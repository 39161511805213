import React, { Component } from 'react';
import Card from '../Geral/CardRelatorio'
import {
    Pane,
    Spinner
} from "evergreen-ui";
import api from '../../../services/api'
import jwtDecode from 'jwt-decode'
import { getToken } from '../../../services/auth'
import { ProgressBar } from 'react-bootstrap';
import { Heading } from 'evergreen-ui/commonjs/typography';

export default class Relatorios extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cardPeriodo: 'MÊS',
            isLoading: false,
            totalAnalise: '',
            totalAprovado: '',
            totalPendente: '',
            totalCancelado: '',
            totalMeta: ''
        }
    }

    async componentDidMount() {
        this.setState({ isLoading: true })
        const decodedUser = jwtDecode(getToken()).data.user[0]
        await api.get(`relatorioFuncionarioIndividual/${decodedUser.id}`)
            .then(response => {
                this.setState({
                    totalAnalise: response.data[0][0].analise !== null ? response.data[0][0].analise : 0,
                    totalAprovado: response.data[0][0].aprovado !== null ? response.data[0][0].aprovado : 0,
                    totalPendente: response.data[0][0].pendente !== null ? response.data[0][0].pendente : 0,
                    totalCancelado: response.data[0][0].cancelado !== null ? response.data[0][0].cancelado : 0,

                })
            })
            .catch(error => {
                console.log(error.response)
            })

        //COMISSAO EM %
        const response = await api.get(`relatorioComissao/${decodedUser.id}/0/0`)
        if (response.data.length > 0) {
            let lucro, total = 0, meta = 12000
            if (decodedUser.username === "Jean Ramos")
                meta = 20000

            response.data.forEach((item) => {
                lucro = (parseFloat(item.valorcontrato) * parseFloat(item.taxa / 100)).toFixed(2)
                total = parseFloat(total) + parseFloat(lucro)
            })
            this.setState({
                isLoading: false,
                totalMeta: Math.round(((parseFloat(total) / parseFloat(meta)) * 100).toPrecision(2))
            })
        }
        else {
            this.setState({
                isLoading: false,
                totalMeta: 0
            })
        }
    }

    render() {
        return (
            <Pane
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                justifyContent="center"
            >
                {this.state.isLoading ?
                    <Pane display="flex" alignItems="center" justifyContent="center" height={500}>
                        <Spinner />
                    </Pane> :
                    <Pane
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        flexWrap="wrap">
                        <Pane width="80%" flexDirection="column" marginTop={10} marginBottom={20} >
                            <Pane display="flex" alignItems="center" justifyContent="center" marginBottom={5}>
                                <Heading size={900}>Meta Mensal</Heading>
                            </Pane>
                            <ProgressBar animated variant="info" now={this.state.totalMeta} label={`${this.state.totalMeta}%`} />
                        </Pane>
                        <Card periodo={this.state.cardPeriodo} valor={this.state.totalAnalise} situacao={'Análise'} />
                        <Card periodo={this.state.cardPeriodo} valor={this.state.totalAprovado} situacao={'Aprovado'} />
                        <Card periodo={this.state.cardPeriodo} valor={this.state.totalPendente} situacao={'Pendente'} />
                        <Card periodo={this.state.cardPeriodo} valor={this.state.totalCancelado} situacao={'Cancelado'} />

                    </Pane>}
            </Pane>
        )

    }
}
