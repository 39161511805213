import React, { Component } from 'react';
import ReactTable from 'react-table'
import "react-table/react-table.css";
import { IconButton, Pane, Badge } from 'evergreen-ui'

export default class ListarUsuarios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [{
        width: 150,
        Header: () => (
          <span>
              <strong>Usuário</strong>
          </span>),
        id: 'username',
        accessor: 'username'
      }, {
        width: 350,
        Header: () => (
          <span>
              <strong>Email</strong>
          </span>),
        accessor: 'email',
      },
      {
        width: 150,
        Header: () => (
          <span>
              <strong>Função</strong>
          </span>),
        accessor: 'roles.description'
      },
      {
        width: 100,
        Header: () => (
          <span>
              <strong>Status</strong>
          </span>),
        acessor: 'status',
        Cell: row => (
          <Pane display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Badge
              color={row.original.status === 1 ? "green"
                : row.original.status === 0 ? "red"
                  : ''}
            >{row.original.status === 1 ? "Ativo" : "Desativado"}</Badge>
          </Pane>
        )
      },
      {
        width: 125,
        Header: () => (
          <span>
              <strong>Ações</strong>
          </span>),
        Cell: row => (
          <Pane
            display="flex"
            flexDirection="row" >
            <IconButton onClick={(e) => this.props.handleEdit(e, row.original.id)} marginRight={5} height={32} icon="edit" intent="none" />
            {row.original.status === 1 ?
              <IconButton id="btnDelete" onClick={(e) => this.props.handleStatus(e, row.original.id)} marginRight={5} height={32} icon="thumbs-down" intent="danger" /> :
              <IconButton id="btnDelete" onClick={(e) => this.props.handleStatus(e, row.original.id)} marginRight={5} height={32} icon="thumbs-up" intent="success" />}
          </Pane>)

      }]
    };
  }



  render() {
    return (
        <ReactTable
          columns={this.state.columns}
          defaultPageSize={10}
          className="-striped -highlight"
          loading={this.props.loading}
          data={this.props.data}
          noDataText="Não há Usuários cadastrados!"
        />
    )
  }
}
