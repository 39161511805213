import React, { Component } from 'react';
import ReactTable from 'react-table'
import "react-table/react-table.css";
import { IconButton, Pane } from 'evergreen-ui'

export default class ListaProdutos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [{
                width: 300,
                Header: () => (
                    <span>
                        <strong>Produto</strong>
                    </span>),
                id: 'descricao',
                accessor: 'descricao'
            },
            {
                width: 125,
                Header: () => (
                    <span>
                        <strong>Ações</strong>
                    </span>),
                Cell: row => (
                    <Pane
                        display="flex"
                        flexDirection="row" >
                        <IconButton onClick={(e) => this.props.handleEdit(e, row.original.id)} marginRight={5} height={32} icon="edit" intent="none" />
                        <IconButton id="btnDelete" onClick={(e) => this.props.handleDelete(e, row.original.id)} marginRight={5} height={32} icon="trash" intent="danger" />
                    </Pane>)
            }]
        };
    }

    render() {
        return (
            <ReactTable
                columns={this.state.columns}
                defaultPageSize={20}
                className="-striped -highlight"
                loading={this.props.loading}
                data={this.props.data}
                noDataText="Não há Produtos cadastrados!"
            />
        )
    }
}
