import React, { Component } from 'react';
import Card from './CardRelatorio'
import {
    Pane,
    Button,
    SelectField,
    Heading,
    Spinner
} from "evergreen-ui";
import DatePicker from 'react-date-picker'
import api from '../../../services/api'

export default class Relatorios extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dtinicial: null,
            dtfinal: null,
            periodo: 'TODOS',
            cardPeriodo: '',
            isBusca: false,
            isLoading: false,
            totalAnalise: '',
            totalAprovado: '',
            totalPendente: '',
            totalCancelado: ''
        }
    }

    onChangeDTini = date => this.setState({ dtinicial: date })
    onChangeDTfinal = date => this.setState({ dtfinal: date })

    handlePesquisa = async (e) => {
        e.preventDefault()
        const { periodo, dtinicial, dtfinal } = this.state

        if (dtinicial === null && dtfinal === null) {
            this.setState({ isLoading: true, isBusca: false })
            await api.get(`relatorio/${periodo}`)
                .then(response => {
                    this.setState({
                        totalAnalise: response.data[0][0].analise !== null ? response.data[0][0].analise : 0,
                        totalAprovado: response.data[0][0].aprovado !== null ? response.data[0][0].aprovado : 0,
                        totalPendente: response.data[0][0].pendente !== null ? response.data[0][0].pendente : 0,
                        totalCancelado: response.data[0][0].cancelado !== null ? response.data[0][0].cancelado : 0,
                        isLoading: false, isBusca: true, cardPeriodo: periodo
                    })
                })
                .catch(error => {
                    console.log(error.response)
                })
        } else {
            if (dtinicial !== null && dtfinal !== null) {
                let dtini = dtinicial.getTime()
                let dtfim = dtfinal.getTime()
                if (parseInt(dtfim, 10) >= parseInt(dtini, 10)) {
                    this.setState({ isLoading: true, isBusca: false })
                    await api.get(`relatorio-dt/${dtini}/${dtfim}`)
                        .then(response => {
                            this.setState({
                                totalAnalise: response.data[0][0].analise !== null ? response.data[0][0].analise : 0,
                                totalAprovado: response.data[0][0].aprovado !== null ? response.data[0][0].aprovado : 0,
                                totalPendente: response.data[0][0].pendente !== null ? response.data[0][0].pendente : 0,
                                totalCancelado: response.data[0][0].cancelado !== null ? response.data[0][0].cancelado : 0,
                                isLoading: false, isBusca: true, cardPeriodo: ` ${dtinicial.toLocaleDateString()} Até: ${dtfinal.toLocaleDateString()}`
                            })
                        })
                        .catch(error => {
                            console.log(error.response)
                        })
                }
                else {
                    window.alert("Data inicial precisa ser menor que data final!")
                }
            }
            else {
                window.alert("É preciso selecionar data inicial e data final!")
            }

        }
    }

    render() {
        return (
            <Pane border="default" display="flex" flexDirection="column" padding={10}>

                <Pane
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"
                    border="default"
                    padding={10}
                    justifyContent="center"
                >
                    <SelectField
                        value={this.state.periodo}
                        onChange={e => this.setState({ periodo: e.target.value })}
                        label="Periodo"
                        width="30%">
                        <option value="TODOS" checked>Todos</option>
                        <option value="HOJE" >Hoje</option>
                        <option value="SEMANA">Semana</option>
                        <option value="MES" >Mês</option>
                    </SelectField>

                    <Pane
                        display="flex"
                        flexDirection="column"
                        marginTop={5}
                        marginLeft={20}
                    >
                        <Heading size={400}>Data Inicial</Heading>
                        <DatePicker
                            calendarIcon={null}
                            onChange={this.onChangeDTini}
                            value={this.state.dtinicial}
                        />
                    </Pane>
                    <Pane
                        display="flex"
                        flexDirection="column"
                        marginLeft={10}
                        marginTop={5}
                    >
                        <Heading size={400}>Data Final</Heading>
                        <DatePicker
                            calendarIcon={null}
                            onChange={this.onChangeDTfinal}
                            value={this.state.dtfinal}
                        />
                    </Pane>


                    <Button onClick={this.handlePesquisa} iconBefore="search" marginTop={23} marginLeft={10} height={32}>Filtrar</Button>
                </Pane>

                <Pane
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"
                    justifyContent="center"
                    marginTop={20}
                    height={500}
                >
                    {this.state.isLoading ?
                        <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
                            <Spinner />
                        </Pane> : ''}

                    {this.state.isBusca ?
                        <Pane
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                            flexWrap="wrap">
                            <Card periodo={this.state.cardPeriodo} valor={this.state.totalAnalise} situacao={'Análise'} />
                            <Card periodo={this.state.cardPeriodo} valor={this.state.totalAprovado} situacao={'Aprovado'} />
                            <Card periodo={this.state.cardPeriodo} valor={this.state.totalPendente} situacao={'Pendente'} />
                            <Card periodo={this.state.cardPeriodo} valor={this.state.totalCancelado} situacao={'Cancelado'} />
                        </Pane>
                        : ''}

                </Pane>
            </Pane>
        )

    }
}
