import React, { useState } from 'react';
import {
    Pane,
    Textarea,
    Heading,
    IconButton,
    Button,
    TextInput,
    Tooltip
} from 'evergreen-ui'

const AddPosts = props => {
    const initialFormState = { titulo: '', texto: '', favorito: false }
    const [post, setPost] = useState(initialFormState)

    const initialErrorState = { errAreaInfo: false, errTitle: false, errMessage: '' }
    const [error, setError] = useState(initialErrorState)

    const handleInputChange = e => {
        const { name, value } = e.target
        setPost({ ...post, [name]: value })
    }

    const handleFavorite = e => {
        e.preventDefault()
        setPost({ ...post, favorito: !post.favorito })
    }

    return (
        <form onSubmit={e => {
            e.preventDefault()
            if (!post.titulo || !post.texto) {
                setError({ errTitle: !post.titulo, errAreaInfo: !post.texto, errMessage: "Título e texto não podem ser vazio." })
                return
            }
            props.addPost(post)
            setPost(initialFormState)
            setError(initialErrorState)
        }}>
            <Pane display="flex" flexDirection="column" alignItems="center" justifyContent="center" >
                <Pane display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Heading size={600}>Cadastro Posts</Heading>
                </Pane>

                <Pane display="flex" flexDirection="column" marginTop={5} padding={5}>
                    <Pane
                        display="flex"
                        flexDirection="row"
                        flexWrap="wrap"
                    >
                        <Pane
                            width="85%"
                            display="flex"
                        >
                            <TextInput
                                marginBottom={10}
                                name="titulo"
                                placeholder="Título Post"
                                value={post.titulo}
                                onChange={handleInputChange}
                                isInvalid={error.errTitle}
                            />
                        </Pane>
                        <Pane display="flex" height={32} marginLeft={10} background="#ffc700">
                            <Tooltip content="Favorito">
                                <IconButton name="favorito" value={post.favorito} onClick={handleFavorite} appearance="minimal"
                                    icon={post.favorito ? "star" : "star-empty"} />
                            </Tooltip>
                        </Pane>
                    </Pane>
                    <Pane
                        display="flex"
                        width="100%"
                    >
                        <Textarea
                            id="textarea-2"
                            placeholder="Digite o texto..."
                            name="texto"
                            onChange={handleInputChange}
                            value={post.texto}
                            isInvalid={error.errAreaInfo}
                        />
                    </Pane>
                </Pane>

                <Pane marginTop={5}>
                    <Heading color="red" size={300}>{error.errMessage}</Heading>
                </Pane>
                <Button
                    iconBefore="add"
                    marginTop={10}
                    height={38}
                    appearance="primary" intent="success">
                    Adicionar
                </Button>
            </Pane>
        </form>
    )
}
export default AddPosts;
