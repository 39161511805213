import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import PrivateRoute from '../src/components/Privado/PrivateRoute'
import Login from './components/Login/Login'
import Menu from './components/Menu/Menu'
import PageNotFound from './components/PageNotFound/index'
import 'bootstrap-css-only/css/bootstrap.min.css';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Login} />
          <PrivateRoute path="/Menu" component={Menu} />
          <Route component={PageNotFound} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
