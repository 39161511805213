import React, { useState } from 'react';
import DatePicker from 'react-date-picker'
import {
    Pane,
    Textarea,
    Heading,
    Icon,
    Button
} from 'evergreen-ui'
import { CardContainer, Header } from './styles';

const AddAgendamentoContrato = props => {
    const initialFormState = { dtagendamento: '', info: '', situacao: false }
    const [agendamento, setAgendamento] = useState(initialFormState)
    const [dtAgendamento, setDtAgendamento] = useState(null)

    const handleInputChange = e => {
        const { name, value } = e.target
        name === 'nome' ?
            setAgendamento({ ...agendamento, [name]: value.toUpperCase() })
            :
            setAgendamento({ ...agendamento, [name]: value })
    }

    const onChangeDT = date => {
        setDtAgendamento(date)
        setAgendamento({ ...agendamento, dtagendamento: date })
    }

    return (

        <form onSubmit={e => {
            e.preventDefault()
            props.addAgendamento(agendamento)
            props.toggle()
            setAgendamento(initialFormState)
        }}>
            <CardContainer pri>
                <Header pri> <Icon icon="calendar" marginRight={5} />Agendamento</Header>
                <Pane display="flex" flexDirection="column" alignItems="center" background="yellowTint" padding={5} >

                    <Pane display="flex" alignItems="center" flexDirection="column">
                        <Heading size={400}>Data Agendamento</Heading>
                        <DatePicker
                            showLeadingZeros
                            required
                            onChange={onChangeDT}
                            value={dtAgendamento}
                        />
                    </Pane>

                    <Pane
                        display="flex"
                        width="100%"
                        marginTop={20}
                    >
                        <Textarea
                            id="textarea-2"
                            placeholder="Digite Informações Adicionais..."
                            name="info"
                            onChange={handleInputChange}
                            value={agendamento.info}
                        />
                    </Pane>
                </Pane>
            </CardContainer>
            <Pane display="flex" justifyContent="flex-end" alignItems="flex-end">
                <Button
                    height={32}
                    appearance="primary"
                    intent="success">
                    Salvar
        </Button>

                <Button appearance="primary"
                    onClick={props.toggle}
                    intent="danger"
                    height={32}
                    marginLeft={10}
                >
                    Cancelar
        </Button>
            </Pane>
        </form>
    )
}
export default AddAgendamentoContrato;
