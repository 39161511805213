import React, { Component } from 'react';
import {
    Pane,
    TextInputField,
    Button,
    toaster,
    Spinner,
} from "evergreen-ui";
import validates from '../../helpers/validates'
import api from '../../services/api'
import ListaCliente from './ListaCliente/ListaCliente'
import ButtonControl from './ButtonControl'
import EditCliente from './EditCliente'
import ListaContratos from './ListaContratoCliente'
import Modal from '../Contrato/Modal'
import ModalDocument from '../Contrato/ModalDocument'

export default class Cliente extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cliente: [],
            data: [],
            pesquisa: '',
            errPesquisab: false,
            errPesquisa: null,
            isEdit: false,
            isSearching: false,

            modal: false,
            contrato: [],
            tel: [],
            modalDoc: false,
            contratoID: null,
        }
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.openModalDocument = this.openModalDocument.bind(this);
        this.closeModalDocument = this.closeModalDocument.bind(this);
    }

    handleViewContrato = async (e, id) => {
        e.preventDefault()
        this.setState({ contrato: [] })
        const { data } = this.state
        const newData = data.filter(item => item.id === id)
        let { cpf_id } = newData[0]
        let response = await api.get(`telefone/${cpf_id}`)
        this.setState({ contrato: { ...newData, view: true }, tel: response.data }, this.openModal)
    }

    openModal() {
        this.setState({ modal: true })
    }

    closeModal() {
        this.setState({ modal: false, contrato: [] })
    }

    handleSaveEdit = async (vcpf) => {
        var config = { headers: { 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' } };
        await api.get(`cliente/${vcpf}`, config)
            .then(response => {
                this.setState({ cliente: response.data, isEdit: !this.state.isEdit })
            }).catch(error => console.log(error.response))
    }

    handleEdit = () => {
        this.setState({ isEdit: !this.state.isEdit })
    }

    handleCancelar = () => {
        this.setState({ cliente: [], pesquisa: '' })
    }

    handleDelete = async () => {
        if (window.confirm("Você realmente quer deletar?")) {
            await api.delete(`cliente/${this.state.cliente[0].id}`)
                .then(response => {
                    this.setState({ cliente: [], pesquisa: '' })
                    toaster.success(
                        'Cliente deletado com sucesso',
                        {
                            duration: 3
                        }
                    )
                })
                .catch(error => console.log(error.response))
        }
    }

    handlePesquisa = async () => {
        const { pesquisa } = this.state

        this.setState({
            errPesquisab: false,
            errPesquisa: null,
            cliente: [],
            isEdit: false,
            isSearching: true
        })
        if (pesquisa !== '') {
            let vcpf = pesquisa.replace(/[^\d]+/g, '')
            if (vcpf.match(/^[0-9]+$/) && validates.ValidateCPF(vcpf)) {
                await api.get(`cliente/${vcpf}`)
                    .then(response => {
                        this.setState({
                            cliente: response.data,

                        });

                    })
                    .catch(error => console.log(error.response));

                if (this.state.cliente.length > 0) {
                    await api.get(`/contrato-cliente/${this.state.cliente[0].id}`)
                        .then(response => {
                            this.setState({
                                data: response.data,
                                isSearching: false
                            });
                        })
                        .catch(error => console.log(error.response))
                }

                if (this.state.cliente.length === 0) {
                    toaster.danger(
                        'Cliente não encontrado!',
                        {
                            duration: 3
                        }
                    )
                    this.setState({ isSearching: false })
                }

            }
            else {
                this.setState({
                    errPesquisab: true,
                    errPesquisa: 'CPF inválido',
                    isSearching: false
                })
            }
        }
        else {
            this.setState({
                errPesquisab: true,
                errPesquisa: 'Campo inválido',
                isSearching: false
            })
        }
    }

    handleDocument = (e, id) => {
        this.openModalDocument(id)
    }

    handleEditContrato = (e, id) => {
        e.preventDefault()
        this.setState({ contrato: [] })
        const { data } = this.state
        const newData = data.filter(item => item.id === id)
        this.setState({ contrato: newData }, this.openModal)
    }

    openModalDocument(id) {
        this.setState({ modalDoc: true, contratoID: id })
    }

    closeModalDocument() {
        this.setState({ modalDoc: false, contratoID: null })
    }

    handleReceivedDataEdit = (contrato) => {
        const { data } = this.state
        toaster.success(
            'Contrato atualizado com sucesso',
            {
                duration: 3
            })
        const newData = data.filter(item => item.id !== contrato.id)
        this.setState({ data: [contrato, ...newData] })
    }


    render() {
        return (
            <Pane
                border="default">
                <Pane
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    marginBottom={8}
                >

                    <TextInputField
                        isInvalid={this.state.errPesquisab}
                        validationMessage={this.state.errPesquisa}
                        onChange={e => this.setState({ pesquisa: e.target.value })}
                        value={this.state.pesquisa}
                        width="50%" height={36} marginTop={20} placeholder="Digite o CPF" label="" />

                    <Button onClick={this.handlePesquisa}
                        iconBefore="search" height={32} marginLeft={10}>Pesquisar</Button>
                </Pane>
                {this.state.isSearching ? <Spinner marginX="auto" marginY={120} /> : ''}
                {this.state.cliente.length !== 0 && !this.state.isEdit ? <ButtonControl editar={this.handleEdit} delete={this.handleDelete} cancelar={this.handleCancelar} /> : ''}
                {this.state.cliente.length !== 0 && !this.state.isEdit ? <ListaCliente loadCliente={this.state.cliente} /> : ''}
                <Pane padding={10}>
                    {this.state.cliente.length !== 0 && !this.state.isEdit ?
                        <ListaContratos
                            isLoading={this.state.isSearching}
                            loadingData={this.state.data}
                            handleEditContrato={this.handleEditContrato}
                            handleViewContrato={this.handleViewContrato}
                            handleDocument={this.handleDocument} /> : ''}
                </Pane>
                {this.state.isEdit ? <EditCliente isDisable={true} loadCliente={this.state.cliente} cancelar={this.handleEdit} salvar={this.handleSaveEdit} /> : ''}
                {this.state.modal ?
                    <Modal isOpen={this.state.modal} onClose={this.closeModal} retrivedataEdit={this.handleReceivedDataEdit} sendData={this.state.contrato} sendTel={this.state.tel} /> : ''
                }

                {this.state.modalDoc ?
                    <ModalDocument isOpen={this.state.modalDoc} onClose={this.closeModalDocument} sendID={this.state.contratoID} /> : ''
                }
            </Pane>
        )
    }
}
