import styled from 'styled-components'

export const CardContainer = styled.div`
  border-radius: 4px;           /* Slightly Curve edges */
  overflow: hidden;             /* Fixes the corners */
  display: flex;                /* Children use Flexbox */
  flex-direction: column;       /* Rotate Axis */
  margin-Bottom: 10px;
  border: ${props => props.pri ? '1px solid #FFC75F' : ''};
  border: ${props => props.ter ? '1px solid #a8e6cf' : ''};
  border: ${props => props.sec ? '1px solid #FFC75F' : ''};
  border: ${props => props.quar ? '1px solid #FFC75F' : ''};
  margin-left: ${props => props.quar ? '5px': ''};
`


export const Header = styled.div`
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  padding: 5px 10px;

  background-color: ${props => props.pri ? '#FFC75F' : ''};
  background-color: ${props => props.sec ? '#FFC75F' : ''};
  background-color: ${props => props.ter ? '#a8e6cf' : ''};
  background-color: ${props => props.quar ? '#FFC75F' : ''};

  border-bottom: ${props => props.pri ? '1px solid #FFC75F' : ''};
  border-bottom: ${props => props.sec ? '1px solid #FFC75F' : ''};
  border-bottom: ${props => props.ter ? '1px solid #a8e6cf' : ''};

  color: ${props => props.pri ? '1px solid #FFC75F' : ''};
  color: ${props => props.sec ? '1px solid #FF6F91' : ''};
  color: ${props => props.ter ? '1px solid #a8e6cf' : ''};
  color: ${props => props.quar ? '1px solid #a8e6cf' : ''};
`


export const Texto = styled.div`
  color: ${props => props.pri ? '1px solid #FFC75F' : ''};
  color: ${props => props.ter ? '1px solid #a8e6cf' : ''};
  color: ${props => props.quar ? '1px solid #a8e6cf' : ''};
  font-size: 12px;
  text-align: left;
  padding-Left: 10px;
  `