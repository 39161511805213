import React, { Component } from 'react';
import {
    Pane,
    TextInputField,
    Button,
    toaster,
    IconButton,
    FilePicker,
    Label
} from "evergreen-ui";
import NumberFormat from 'react-number-format';
import api from '../../services/api';
import ReactTable from 'react-table'
import readXlsxFile from 'read-excel-file'


/*const schema = {
    'CONVÊNIO': {
        prop: 'tabela',
        type: String
    },
    'FLAT + DIFERIDO ANTECIPADO': {
        prop: 'taxa',
        type: String,
    }
}*/

export default class Tabelas extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataExcel: [],
            tabelas: [],
            tabela: '',
            errTabela: null,
            errTabelab: false,
            taxa: '',
            errTaxa: null,
            errTaxab: false,
            isSaving: false,
            isSavingTabela: true,
            isLoading: false,
            columns: [
                {
                    width: 50,
                    Header: () => (
                        <span>
                            <strong>ID</strong>
                        </span>),
                    accessor: 'id',
                    show: false
                },
                {
                    width: 900,
                    Header: () => (
                        <span>
                            <strong>Tabela</strong>
                        </span>),
                    filterMethod: (filter, row) =>
                        row[filter.id].includes(filter.value.toUpperCase()),
                    accessor: 'descricao',
                },
                {
                    width: 100,
                    filterable: false,
                    Header: () => (
                        <span>
                            <strong>Taxa</strong>
                        </span>),
                    accessor: 'taxa',
                    Cell: row => (
                        <strong>{`%${row.original.taxa}`}</strong>
                    )
                },
                {
                    width: 100,
                    filterable: false,
                    Header: () => (
                        <span>
                            <strong>Ações</strong>
                        </span>),
                    id: 'edit/delete',
                    Cell: row => (
                        <Pane display="flex" justify-content="flex-start" >
                            <IconButton id="btnDelete" onClick={(e) => this.handleDelete(e, row.original.id)} marginRight={5} height={32} icon="trash" intent="danger" />
                        </Pane>)
                }
            ]
        }
    }

    // componentWillUnmount() {
    //     this.state.uploadedFiles.forEach(file => URL.revokeObjectURL(file.preview))
    // }

    limparCampos() {
        this.setState({ tabela: '', taxa: '' })
    }

    validarTaxa() {
        const { tabela, taxa } = this.state
        this.setState({
            errTabela: null,
            errTabelab: false,
            errTaxa: null,
            errTaxab: false,
        })
        const vtaxa = taxa.replace(/[%]/g, '')
        if (tabela === '') {
            this.setState({
                errTabela: 'Tabela inválida',
                errTabelab: true
            })
            return false
        }
        if (vtaxa === '' || parseFloat(vtaxa) <= 0) {
            this.setState({
                errTaxa: 'Taxa precisa ser positiva',
                errTaxab: true
            })
            return false
        }
        if (parseFloat(vtaxa) > 100) {
            this.setState({
                errTaxa: 'Taxa não pode ser maior que 100%',
                errTaxab: true
            })
            return false
        }
        return true
    }

    handleDelete = async (e, id) => {
        e.preventDefault()
        const { tabelas } = this.state
        if (window.confirm("Você realmente deseja deletar a tabela?")) {
            this.setState({ isLoading: true })
            await api.delete(`tabela/${id}`)
                .then(response => {
                    toaster.success(
                        'Tabela deletada com sucesso',
                        {
                            duration: 3
                        }
                    )
                    const newTabela = tabelas.filter(item => item.id !== id)
                    this.setState({ tabelas: newTabela, isLoading: false })
                })
                .catch(error => {
                    console.log(error.response)
                })
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        const { tabela, taxa, tabelas } = this.state
        if (this.validarTaxa()) {
            this.setState({ isSaving: true })
            const vtaxa = taxa.replace(/[%]/g, '')
            const vtabela = {
                descricao: tabela.toUpperCase(),
                taxa: vtaxa,
                favorito: true
            }

            await api.post('tabela', vtabela)
                .then(response => {
                    const vtab = response.data
                    toaster.success(
                        'Tabela cadastrada com sucesso',
                        {
                            duration: 3
                        }
                    )
                    const newTabela = [vtab, ...tabelas]
                    this.setState({ isSaving: false, tabelas: newTabela })
                    this.limparCampos()
                })
                .catch(error => {
                    console.log(error.response)
                })
        }
    }

    handleSubmitExcel = async (e) => {
        e.preventDefault()
        const { dataExcel } = this.state
        if (window.confirm("Você realmente quer enviar as novas tabelas ? OBS: as tabelas antigas serão perdidas!")) {
            if (dataExcel.length > 0) {
                this.setState({ isSaving: true, isLoading: true })

                await api.delete('/tabela-delete-all')

                dataExcel.map(async item => {
                    const vtabela = { descricao: item[0], taxa: item[1] !== null ? item[1] : 0 }
                    await api.post('tabela', vtabela)
                })

                var config = { headers: { 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' } };
                await api.get('tabela', config)
                    .then(response => {
                        this.setState({ tabelas: response.data, isLoading: false, isSaving: false, dataExcel: [] })
                    })
                    .catch(error => {
                        console.log(error.response)
                    })


                toaster.success(
                    'Tabelas cadastradas com sucesso',
                    {
                        duration: 3
                    }
                )
            }
            else {
                alert("Selecione um arquivo para enviar")
            }
        }
    }

    async componentWillMount() {
        //CARREGAR TABELA
        this.setState({ isLoading: true })
        await api.get('tabela')
            .then(response => {
                this.setState({ tabelas: response.data, isLoading: false })
            })
            .catch(error => {
                console.log(error.response)
            })
    }

    readExelFile = (dataex) => {
        if (dataex[0].name.includes('.xls') || dataex[0].name.includes('.xlsx')) {
            this.setState({ isSavingTabela: false })
            readXlsxFile(dataex[0]).then((rows) => {
                this.setState({ dataExcel: rows })
            })
        }
        else {
            this.setState({ isSavingTabela: true })
            window.alert('Arquivo inválido!')
        }
    }


    render() {
        return (
            <Pane
                background="tint1"
                border="default"
            >
                <Pane padding={10}   >
                    <form onSubmit={this.handleSubmit} id='my-form'>
                        <Pane
                            border="default"
                            padding={10}
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="center"
                            height={110}
                        >
                            <TextInputField
                                isInvalid={this.state.errTabelab}
                                validationMessage={this.state.errTabela}
                                value={this.state.tabela}
                                onChange={(e) => { this.setState({ tabela: e.target.value }) }}
                                width="60%" height={36} placeholder="Digite a Tabela" label="Tabela" />

                            <NumberFormat
                                prefix={'%'}
                                isInvalid={this.state.errTaxab}
                                validationMessage={this.state.errTaxa}
                                value={this.state.taxa}
                                onChange={(e) => { this.setState({ taxa: e.target.value }) }}
                                label="Taxa %"
                                customInput={TextInputField}
                                width="30%" height={36} marginLeft={10} placeholder="Digite a Taxa" />
                            <Button disabled={this.state.isSaving} onClick={this.handleSubmit} form='my-form' content='Submit' value='Submit' iconBefore="add" height={32} marginLeft={10} marginTop={20} appearance="primary" intent="success">Adicionar</Button>
                        </Pane>
                    </form>
                </Pane>
                <Pane
                    background="tint2"
                    padding={10}
                    display="flex"
                >
                    <Pane
                        border="default"
                        display="flex"
                        flexDirection="row"
                        flexWrap="wrap"
                        padding={10}
                    >
                        <Pane display="flex" flexDirection="column">
                            <Label> Enviar Arquivo Excel </Label>
                            <FilePicker
                                marginRight={20}
                                width={250}
                                multiple={false}
                                onChange={(files) => this.readExelFile(files)}
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            />
                        </Pane>
                        <Button marginTop={25} disabled={this.state.isSavingTabela} onClick={this.handleSubmitExcel} iconBefore="import" height={32} marginLeft={10} appearance="primary" intent="success">Enviar Tabelas</Button>
                    </Pane>
                </Pane>
                <Pane
                    padding={10}
                >
                    <ReactTable
                        filterable
                        defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]) === filter.value}
                        loading={this.state.isLoading}
                        data={this.state.tabelas}
                        pageSize={25}
                        columns={this.state.columns}
                        className="-striped -highlight"
                        noDataText="Não há tabelas!"
                        sortable={true}
                        resizable={false}
                    />
                </Pane>
            </Pane >
        )
    }
}
