import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
    width: 100%;
    margin: 10px;
    background: white;
    border-radius: 4px;
    padding: 5px;
`;