import styled from 'styled-components';

export const Container = styled.ul`
    margin: 0; 
    padding: 0;
    background-color: #FFF3B9;
    
  li{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: #444;
      border: 1px solid gray;  
  }

`;
