import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import Contrato from './Create/Contrato'
import CardContrato from './CardContrato/index'

export default class Cliente extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      contrato: [],
      tel: [],
    };

    this.onClose = this.onClose.bind(this);
    this.openModal = this.openModal.bind(this)
  }

  componentDidMount() {
    this.setState({ contrato: [], tel:[] })
    if (this.props.isOpen)
      this.openModal()
    else
      this.onClose()

    if (this.props.sendData) {
      this.setState({ contrato: this.props.sendData, tel: this.props.sendTel })
    }
  }



  openModal() {
    this.setState({ modal: true })
  }

  onClose() {
    this.props.onClose();
  }

  handleReceivedData = (contrato) => {
    this.props.retrivedata(contrato)
  }

  handleReceivedDataEdit = (contrato) => {
    this.props.retrivedataEdit(contrato)
  }

  render() {
    return (
      <Modal size="lg" isOpen={this.state.modal} toggle={this.onClose} className={this.props.className}>
        <ModalHeader >Contrato</ModalHeader>
        <ModalBody>
          {!this.state.contrato.view ?
            <Contrato toggle={this.onClose} retrivedata={this.handleReceivedData} retrivedataEdit={this.handleReceivedDataEdit} sendData={this.state.contrato} />
            : 
            <CardContrato  sendData={this.state.contrato} sendTel={this.state.tel} />
          }
        </ModalBody>
      </Modal>
    )
  }
}
