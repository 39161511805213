import React, { useState } from 'react';
import {
    Pane,
    Button,
    TextInputField,
    Heading
} from 'evergreen-ui'

const AddBanco = props => {
    const initialFormState = { descricao: '' }
    const [banco, setBanco] = useState(initialFormState)

    const initialErrorState = { errDescricaob: false, errDescricao: null }
    const [error, setError] = useState(initialErrorState)

    const handleInputChange = e => {
        const { name, value } = e.target
        setBanco({ ...banco, [name]: value.toUpperCase() })
    }

    return (
        <Pane border="default"  >
            <Pane display="flex"
                justifyContent="center"
                alignItems="center"
                marginTop={20}>
                <Heading size={600}>Cadastro / Editar Bancos</Heading>
            </Pane>
            <form onSubmit={e => {
                e.preventDefault()
                if (!banco.descricao) {
                    setError({ errDescricaob: !banco.descricao, errDescricao: "Digite o nome do banco." })
                    return
                }
                props.addBanco(banco)
                setBanco(initialFormState)
                setError(initialErrorState)
            }}>
                <Pane

                    padding={10}
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"
                >

                    <Pane flex={1} height="120px">
                        <TextInputField
                            width="100%"
                            name="descricao"
                            placeholder="Nome do banco"
                            label="Banco"
                            value={banco.descricao}
                            onChange={handleInputChange}
                            isInvalid={error.errDescricaob}
                            validationMessage={error.errDescricao}
                        />
                    </Pane>

                    <Pane display="flex" marginTop={25} >
                        <Button
                            value='Submit'
                            iconBefore="add"
                            heigth={32}
                            marginLeft={10}
                            appearance="primary"
                            intent="success">
                            Salvar
                        </Button>
                    </Pane>
                </Pane>
            </form>
        </Pane>
    )
}

export default AddBanco;
