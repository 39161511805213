import React from "react";
import {  Route, Redirect } from "react-router-dom";
import { isAuthenticated } from "../../services/auth"
import { isGerente, isAdministrador } from '../../helpers/validateUser'

export const PrivateRouteAdm = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() && isAdministrador() ? (
        <Component {...props} />
      ) : (
        <Redirect to="/Menu"/>
      )
    }
  />
);

export const PrivateRouteGenAdm = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() && (isAdministrador() || isGerente()) ? (
        <Component {...props} />
      ) : (
        <Redirect to="/Menu"/>
      )
    }
  />
);
