import React, { Component } from 'react';
import ListarUsuarios from './ListarUsuarios'
import { Pane, Button, TextInputField, Heading, SelectField } from 'evergreen-ui'
import api from '../../services/api'

export default class Usuarios extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dataUsers: [],
      id: "",
      username: "",
      password: "",
      email: "",
      status: true,
      isSaving: false,
      isLoading: false,
      isEditing: false,

      funcoes: [],
      selectedValue: "",
      funcao_id: "",
      funcao_name: "",

      errUsername: null,
      errUsernameb: false,
      errEmail: null,
      errEmailb: false,
      errPassword: null,
      errPasswordb: false,
    }

    this.handleClear = this.handleClear.bind(this)
  }

  componentDidMount() {
    this.setState({ isLoading: true })

    api.get("roles")
      .then(response => {
        this.setState({
          funcoes: response.data,
          funcao_id: response.data[0].id,
          funcao_name: response.data[0].description,
          selectedValue: response.data[0].description
        });
      })
      .catch(error => console.log(error.response));


    api.get("users")
      .then(response => {
        this.setState({
          dataUsers: response.data,
          isLoading: false
        })
      })
      .catch(error => console.log(error.response));
  }

  handleFuncao(e) {
    this.setState({
      funcao_name: e.target.value,
      funcao_id: e.target.childNodes[e.target.selectedIndex].getAttribute('data-key'),
      selectedValue: e.target.value,
    })
  }

  validateUser() {
    const { username, password, email } = this.state
    this.setState({
      errUsername: null,
      errUsernameb: false,
      errEmail: null,
      errEmailb: false,
      errPassword: null,
      errPasswordb: false,
    })
    if (username === '') {
      this.setState({
        errUsername: 'Digite um username válido',
        errUsernameb: true
      })
      return false
    }
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      this.setState({
        errEmail: 'Digite um email válido',
        errEmailb: true
      })
      return false
    }
    if (password.length <= 3) {
      this.setState({
        errPassword: 'Senha deve possuir > 3 caracteres',
        errPasswordb: true
      })
      return false
    }
    return true
  }

  handleSubmit = async (e) => {
    const { username, password, email, funcao_id, dataUsers, selectedValue, isEditing, id } = this.state
    e.preventDefault()
    //console.log(this.state.funcao_id, this.state.funcao_name)

    if (!isEditing) {
      if (this.validateUser()) {
        this.setState({ isSaving: true })

        const newUser = {
          username,
          password,
          email,
          role_id: funcao_id,
          status: 1,
          roles: {
            id: funcao_id,
            description: selectedValue
          }
        }

        await api.post(`register/`, newUser)
        this.setState({
          isSaving: false,
          dataUsers: [newUser, ...dataUsers]
        })
        this.handleClear()
      }
    }
    else {
      if (this.validateUser()) {
        this.setState({ isSaving: true })

        const newUser = {
          id,
          username,
          password,
          email,
          role_id: funcao_id,
          status: 1,
        }
        const newUsers = dataUsers.filter(item => item.id !== id)
        await api.put(`user/${id}`, newUser)
        this.setState({
          isSaving: false,
          dataUsers: [
            {
              ...newUser, roles: {
                id: funcao_id,
                description: selectedValue
              }
            }, ...newUsers]
        })
        this.handleClear()
      }
    }

  }

  handleClear() {
    const { funcoes } = this.state
    this.setState({
      username: "",
      password: "",
      email: "",
      funcao_id: funcoes[0].id,
      funcao_name: funcoes[0].description,
      selectedValue: funcoes[0].description,
      isEditing: false,
      id: "",
      errUsername: null,
      errUsernameb: false,
      errEmail: null,
      errEmailb: false,
      errPassword: null,
      errPasswordb: false,
    })
  }

  handleStatus = async (e, id) => {
    const { dataUsers } = this.state
    e.preventDefault()
    if (window.confirm("Você realmente deseja alterar o status do funcionário?")) {
      this.setState({ isLoading: true })
      const copyUser = dataUsers.filter(item => item.id === id)
      const newUsers = dataUsers.filter(item => item.id !== id)
      if (copyUser[0].status) {
        copyUser[0].status = 0
        await api.put(`user/${id}`, ...copyUser)
      }
      else {
        copyUser[0].status = 1
        await api.put(`user/${id}`, ...copyUser)
      }
      this.setState({ dataUsers: [...copyUser, ...newUsers], isLoading: false })
    }
  }


  handleEdit = async (e, id) => {
    const { dataUsers } = this.state
    e.preventDefault()
    const copyUser = dataUsers.filter(item => item.id === id)
    this.setState({
      id: copyUser[0].id,
      username: copyUser[0].username,
      email: copyUser[0].email,
      funcao_id: copyUser[0].roles.id,
      funcao_name: copyUser[0].roles.description,
      selectedValue: copyUser[0].roles.description,
      isEditing: true
    })

  }

  render() {
    return (
      <Pane padding={10}  border="default" >
        <Pane display="flex" flexDirection="column"  >
          <Pane
            border="default"
            padding={10}
            flexDirection="column"
            flex={1}
          >
            <form onSubmit={this.handleSubmit} id='my-form'>
              <Pane display="flex" justifyContent="center"
                alignItems="center">
                <Heading size={600}>Cadastro / Editar Funcionários</Heading>
              </Pane>

              <Pane display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column">
                <TextInputField
                  isInvalid={this.state.errUsernameb}
                  validationMessage={this.state.errUsername}
                  value={this.state.username}
                  onChange={(e) => { this.setState({ username: e.target.value }) }}
                  width="50%" height={36} placeholder="Digite o nome" label="Nome Funcionário" />

                <TextInputField
                  isInvalid={this.state.errEmailb}
                  validationMessage={this.state.errEmail}
                  value={this.state.email}
                  onChange={(e) => { this.setState({ email: e.target.value }) }}
                  width="50%" height={36} marginTop={20}
                  placeholder="nome@exemplo.com"
                  type="email" label="Email" />

                <TextInputField
                  isInvalid={this.state.errPasswordb}
                  validationMessage={this.state.errPassword}
                  value={this.state.password}
                  onChange={(e) => { this.setState({ password: e.target.value }) }}
                  width="50%" height={36} marginTop={20} placeholder="Digite a senha"
                  type="password"
                  label="Senha" />

                <SelectField label="Função"
                  value={this.state.selectedValue}
                  onChange={(e) => this.handleFuncao(e)}
                  height={45} width="50%" marginTop={20}>
                  {
                    this.state.funcoes.map((item) => (
                      <option key={item.id} data-key={item.id}>{item.description}</option>
                    ))
                  }
                </SelectField>
              </Pane>
            </form>
            <Pane display="flex" alignItems="center" justifyContent="center" marginBottom={10}>
              <Button disabled={this.state.isSaving} onClick={this.handleSubmit}
                form='my-form' content='Submit' value='Submit'
                iconBefore="add"
                height={38} marginLeft={10}
                appearance="primary" intent="success">Salvar</Button>

              <Button disabled={this.state.isSaving} onClick={this.handleClear}
                iconBefore="eraser"
                height={38} marginLeft={10}
                appearance="primary" intent="none">Limpar</Button>
            </Pane>
          </Pane>

          <Pane
            flexDirection="column"
            marginTop={10}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Heading size={600} marginTop={10} marginBottom={5}>Lista Usuarios Sistema</Heading>
            </Pane>
              <ListarUsuarios loading={this.state.isLoading} data={this.state.dataUsers} handleEdit={this.handleEdit} handleStatus={this.handleStatus} />
        </Pane>
      </Pane>

    )
  }
}
