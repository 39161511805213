import React, { Component } from 'react';
import {
    Pane,
    TextInputField,
    Button,
    toaster,
} from "evergreen-ui";
import ListaClientes from './ListaClientes'
import ListaCliente from './ListaCliente/ListaCliente'
import ButtonControl from './ButtonControl'
import EditCliente from './EditCliente'
import api from '../../services/api'
import ListaContratos from './ListaContratoCliente'
import Modal from '../Contrato/Modal'
import ModalDocument from '../Contrato/ModalDocument'


export default class Cliente extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            cliente: [],
            varCliente: [],
            pesquisa: '',
            errPesquisab: false,
            errPesquisa: null,
            loading: false,
            isEdit: false,

            modal: false,
            contrato: [],
            modalDoc: false,
            contratoID: null,
        }
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.openModalDocument = this.openModalDocument.bind(this);
        this.closeModalDocument = this.closeModalDocument.bind(this);
    }

    handleViewContrato = async (e, id) => {
        e.preventDefault()
        this.setState({ contrato: [] })
        const { data } = this.state
        const newData = data.filter(item => item.id === id)
        let { cpf_id } = newData[0]
        let response = await api.get(`telefone/${cpf_id}`)
        this.setState({ contrato: { ...newData, view: true }, tel: response.data }, this.openModal)
    }

    openModal() {
        this.setState({ modal: true })
    }

    closeModal() {
        this.setState({ modal: false, contrato: [] })
    }


    handleSaveEdit = async (vcpf) => {
        //NO CACHE AXIOS
        var config = { headers: { 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' } };
        await api.get(`cliente/${vcpf}`, config)
            .then(response => {
                this.setState({ varCliente: response.data, isEdit: !this.state.isEdit })
            }).catch(error => console.log(error.response))
    }

    handleEdit = () => {
        this.setState({ isEdit: !this.state.isEdit })
    }

    handleCancelar = () => {
        this.setState({ varCliente: [], pesquisa: '', cliente: [] })
    }

    handleDelete = async (idCliente) => {
        if (this.state.varCliente.length !== 0) {
            idCliente = this.state.varCliente[0].id
        }
        if (window.confirm("Você realmente quer deletar?")) {
            await api.delete(`cliente/${idCliente}`)
                .then(response => {
                    const vcliente = this.state.cliente.filter(item => item.id !== idCliente)
                    this.setState({ cliente: vcliente, varCliente: [] })
                    toaster.success(
                        'Cliente deletado com sucesso',
                        {
                            duration: 3
                        }
                    )
                })
                .catch(error => console.log(error.response))
        }
    }

    handleView = async (cpfCliente, id) => {
        this.setState({
            varCliente: this.state.cliente.filter(item => item.cpf === cpfCliente),
            data: [],
            isSearching: true
        })
        await api.get(`/contrato-cliente/${id}`)
            .then(response => {
                this.setState({
                    data: response.data,
                    isSearching: false
                });
            })
            .catch(error => console.log(error.response))
    }

    handlePesquisa = async () => {
        const { pesquisa } = this.state

        this.setState({
            errPesquisab: false,
            errPesquisa: null,
            cliente: [],
            varCliente: [],
            isEdit: false,
            isSearching: true
        })
        if (pesquisa !== '' && !pesquisa.match(/\d/)) {
            if (pesquisa.length >= 2) {
                this.setState({
                    loading: true
                })
                await api.get(`clientes/${pesquisa.toUpperCase()}`)
                    .then(response => {
                        this.setState({
                            cliente: response.data,
                            loading: false,
                            isSearching: false
                        })
                    })
                    .catch(error => console.log(error.response))



            } else {
                this.setState({
                    errPesquisab: true,
                    errPesquisa: 'Nome deve possuir dois ou mais caracteres.',
                    isSearching: false
                })
            }
        }
        else {
            this.setState({
                errPesquisab: true,
                errPesquisa: 'Nome inválido.',
                isSearching: false
            })
        }
    }

    handleDocument = (e, id) => {
        this.openModalDocument(id)
    }

    openModalDocument(id) {
        this.setState({ modalDoc: true, contratoID: id })
    }

    closeModalDocument() {
        this.setState({ modalDoc: false, contratoID: null })
    }

    handleEditContrato = (e, id) => {
        e.preventDefault()
        this.setState({ contrato: [] })
        const { data } = this.state
        const newData = data.filter(item => item.id === id)
        this.setState({ contrato: newData }, this.openModal)
    }

    handleReceivedDataEdit = (contrato) => {
        const { data } = this.state
        toaster.success(
            'Contrato atualizado com sucesso',
            {
                duration: 3
            })
        const newData = data.filter(item => item.id !== contrato.id)
        this.setState({ data: [contrato, ...newData] })
    }


    render() {
        return (
            <Pane
                border="default">
                <Pane
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    marginBottom={8}
                >
                    <TextInputField
                        isInvalid={this.state.errPesquisab}
                        validationMessage={this.state.errPesquisa}
                        onChange={e => this.setState({ pesquisa: e.target.value })}
                        value={this.state.pesquisa}
                        width="50%" height={36} marginTop={20} placeholder="Digite o Nome" label="" />

                    <Button onClick={this.handlePesquisa}
                        iconBefore="search" height={32} marginLeft={10}>Pesquisar</Button>
                </Pane>
                {this.state.varCliente.length !== 0 && this.state.isEdit ? <EditCliente isDisable={true} loadCliente={this.state.varCliente} cancelar={this.handleEdit} salvar={this.handleSaveEdit} /> :
                    ''}
                {this.state.varCliente.length !== 0 && !this.state.isEdit ? <ButtonControl delete={this.handleDelete} cancelar={this.handleCancelar} editar={this.handleEdit} /> : ''}
                {this.state.varCliente.length !== 0 && !this.state.isEdit ? <ListaCliente loadCliente={this.state.varCliente} /> : ''}
                <Pane padding={10}>
                    {this.state.varCliente.length !== 0 && !this.state.isEdit ?
                        <ListaContratos
                            isLoading={this.state.isSearching}
                            loadingData={this.state.data}
                            handleEditContrato={this.handleEditContrato}
                            handleViewContrato={this.handleViewContrato}
                            handleDocument={this.handleDocument} /> : ''}
                </Pane>
                {this.state.varCliente.length === 0 ?
                    <ListaClientes dataCliente={this.state.cliente} isLoading={this.state.loading} view={this.handleView} /> : ''
                }

                {this.state.modal ?
                    <Modal isOpen={this.state.modal} onClose={this.closeModal} retrivedataEdit={this.handleReceivedDataEdit} sendData={this.state.contrato} sendTel={this.state.tel} /> : ''
                }

                {this.state.modalDoc ?
                    <ModalDocument isOpen={this.state.modalDoc} onClose={this.closeModalDocument} sendID={this.state.contratoID} /> : ''
                }
            </Pane>
        )
    }
}
