import React, { Component } from 'react';
import {
  Pane,
  SelectField,
  TextInputField,
  Table,
  IconButton,
  Label,
  Textarea,
  Heading,
  Button,
  Switch,
  Icon,
  Checkbox
} from "evergreen-ui";

import { CardContainer, Header } from './styles';
import Select from 'react-select';
import validates from '../../../helpers/validates'
import dataEstados from '../../../helpers/estados'
import dataIndicacao from '../../../helpers/indicacoes'
import MaskedInput from 'react-text-mask'
import NumberFormat from 'react-number-format';
import api from '../../../services/api'
import { isFuncionario } from '../../../helpers/validateUser'
import { getToken } from '../../../services/auth'
import jwtDecode from 'jwt-decode'

const initialState = {
  selectedOption: null,
  isSaving: false,
  haveCliente: false,
  isEditing: false,
  bloqueado: false,
  cliente: [],
  telefones: [],
  maskTelefonePlaceholder: "(18)99999-9999",
  maskTelefonePlaceholderx: ["(18)99999-9999", "(18)9999-9999"],
  maskTelefone: ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  maskTelefonex: [['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]],
  selecionado: false,
  valorCurrency: 0,

  telefone: '',
  errTelefone: null,
  errTelefoneb: false,

  responsavel: '',
  responsavel_id: '',
  responsaveis: [],

  indicacao: 'NENHUMA',

  cpf: '',
  errCpf: null,
  errCpfb: false,

  nome: '',
  errNome: null,
  errNomeb: false,

  nascimento: new Date().toLocaleDateString(),
  errNascimento: null,
  errNascimentob: false,

  endereco: '',
  errEndereco: null,
  errEnderecob: false,

  numero: '',
  errNumero: null,
  errNumerob: false,

  bairro: '',
  errBairro: null,
  errBairrob: false,

  cidade: '',
  errCidade: null,
  errCidadeb: false,

  estado: 'SP',
  errEstado: null,
  errEstadob: false,

  cep: '',
  errCep: null,
  errCepb: false,

  ade: '',
  errAde: null,
  errAdeb: false,

  produto: '',
  produto_id: '',
  produtos: [],
  errProduto: false,

  banco: '',
  banco_id: '',
  bancos: [],
  errBanco: false,

  tabela: null,
  tabela_id: '',
  tabelas: [],
  errTabela: null,
  errTabelab: false,

  valor: '',
  errValor: null,
  errValob: false,

  taxa: '',
  errTaxa: null,
  errTaxab: false,

  parcela: '',
  errParcela: null,
  errParcelab: false,

  qtdParcela: '',
  errQtdParcela: null,
  errQtdParcelab: false,

  situacao: 'ANALISE',
  errSituacao: null,
  errSituacaob: false,

  areaInfo: '',

  agDigitacao: false
}


export default class Create extends Component {
  constructor(props) {
    super(props)
    this.state = initialState

    this.handleMaskTelefone = this.handleMaskTelefone.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.addTelefones = this.addTelefones.bind(this)
    this.removeTelefone = this.removeTelefone.bind(this)
  }

  limparErrosCliente = () => {
    this.setState({
      errCpf: null, errNome: null, errEndereco: null,
      errNumero: null, errCidade: null, errCep: null,
      errBairro: null, errTelefone: null
    })
  }

  handleLoadCliente = async () => {
    const { cpf } = this.state
    if (cpf !== '' && validates.ValidateCPF(cpf)) {
      let vcpf = cpf.replace(/[^\d]+/g, '');
      await api.get(`cliente/${vcpf}`)
        .then(response => {
          this.setState({
            cliente: response.data,
          });
        })
        .catch(error => console.log(error.response));

      if (this.state.cliente.length > 0) {
        this.limparErrosCliente()
        const vnascimento = new Date(this.state.cliente[0].nascimento).toLocaleDateString()


        //CARREGAR TELEFONES
        const json = this.state.cliente[0].tel
        for (var i = 0; i < json.length; i++) {
          this.state.telefones.push(json[i].numero)
        }

        this.setState({
          nome: this.state.cliente[0].nome,
          endereco: this.state.cliente[0].endereco ? this.state.cliente[0].endereco : '',
          bairro: this.state.cliente[0].bairro ? this.state.cliente[0].bairro : '',
          cep: this.state.cliente[0].cep ? this.state.cliente[0].cep : '',
          cidade: this.state.cliente[0].cidade ? this.state.cliente[0].cidade : '',
          estado: this.state.cliente[0].estado,
          numero: this.state.cliente[0].numero ? this.state.cliente[0].numero : '',
          nascimento: vnascimento,
          telefones: this.state.telefones,
          bloqueado: true,
          haveCliente: true
        })
      }
    }
  }

  async componentWillMount() {
    this.setState({ telefones: [] })
    let decodedUser = jwtDecode(getToken()).data.user[0]

    await api.get("users")
      .then(response => {
        this.props.sendData.length === 0 ?
          this.setState({
            responsaveis: response.data,
            responsavel: decodedUser.username,
            responsavel_id: decodedUser.id
          }) :
          this.setState({
            responsaveis: response.data,
          })
      })
      .catch(error => console.log(error.response));


    const responsebanco = await api.get("banco")
    this.props.sendData.length === 0 ?
      responsebanco.data.length &&
      this.setState({
        bancos: responsebanco.data,
        banco: responsebanco.data[0].descricao,
        banco_id: responsebanco.data[0].id
      }) :
      this.setState({
        bancos: responsebanco.data,
      })


    const responseprod = await api.get("produto")
    this.props.sendData.length === 0 ?
      responseprod.data.length &&
      this.setState({
        produtos: responseprod.data,
        produto: responseprod.data[0].descricao,
        produto_id: responseprod.data[0].id
      }) :
      this.setState({
        produtos: responseprod.data,
      })



    await api.get("tabela")
      .then(response => {
        this.setState({
          tabelas: response.data,
        });
      })
      .catch(error => console.log(error.response));
  }
  componentDidMount() {
    const { sendData } = this.props

    if (this.props.sendData.length > 0) {
      this.setState({
        isEditing: true, bloqueado: true,
        responsavel: sendData[0].responsavel.username,
        responsavel_id: sendData[0].responsavel.id,
        nome: sendData[0].cli.nome, cpf: sendData[0].cpf_id,
        nascimento: new Date(sendData[0].cli.nascimento).toLocaleDateString(),
        endereco: sendData[0].cli.endereco ? sendData[0].cli.endereco : '',
        numero: sendData[0].cli.numero ? sendData[0].cli.numero : '',
        bairro: sendData[0].cli.bairro ? sendData[0].cli.bairro : '',
        cidade: sendData[0].cli.cidade ? sendData[0].cli.cidade : '',
        estado: sendData[0].cli.estado,
        cep: sendData[0].cli.cep ? sendData[0].cli.cep : '',
        ade: sendData[0].ade,
        produto: sendData[0].pro.descricao,
        produto_id: sendData[0].produto_id,
        banco: sendData[0].ban.descricao,
        banco_id: sendData[0].banco_id,
        selectedOption: { value: sendData[0].tabela, label: sendData[0].tabela },
        tabela: sendData[0].tabela,
        taxa: `${sendData[0].taxa} %`,
        parcela: sendData[0].parcela,
        qtdParcela: sendData[0].qntdparcela,
        valor: sendData[0].valorcontrato.replace('.', ','),
        situacao: sendData[0].situacao === 'DIGITACAO' ? 'ANALISE' : sendData[0].situacao,
        indicacao: sendData[0].indicacao
      })
    }
  }

  validateCli = () => {
    const newState = validates.validaCliente(this.state)

    if (newState.errCpf ||
      newState.errNome ||
      newState.errNascimento ||
      newState.errTelefone)
    //|| newState.errEndereco || newState.errNumero || newState.errBairro || newState.errCidade || newState.errCep 
    {
      this.setState({
        errCpf: newState.errCpf, errCpfb: newState.errCpfb,
        errNome: newState.errNome, errNomeb: newState.errNomeb,
        errNascimento: newState.errNascimento, errNascimentob: newState.errNascimentob,
        errEndereco: newState.errEndereco, errEnderecob: newState.errEnderecob,
        errNumero: newState.errNumero, errNumerob: newState.errNumerob,
        errBairro: newState.errBairro, errBairrob: newState.errBairrob,
        errCidade: newState.errCidade, errCidadeb: newState.errCidadeb,
        errCep: newState.errCep, errCepb: newState.errCepb,
        errTelefone: newState.errTelefone, errTelefoneb: newState.errTelefoneb
      })
      return false
    }
    return true
  }

  validateContrato = () => {
    const newState = validates.validaContrato(this.state)

    if (newState.errAde || newState.errTabela || newState.errTaxa || newState.errValor
      || newState.errParcela || newState.errQtdParcela || newState.errProduto || newState.errBanco) {
      this.setState({
        errAde: newState.errAde, errAdeb: newState.errAdeb,
        errTabela: newState.errTabela, errTabelab: newState.errTabelab,
        errTaxa: newState.errTaxa, errTaxab: newState.errTaxab,
        errValor: newState.errValor, errValob: newState.errValob,
        errParcela: newState.errParcela, errParcelab: newState.errParcelab,
        errQtdParcela: newState.errQtdParcela, errQtdParcelab: newState.errQtdParcelab,
        errProduto: newState.errProduto, errBanco: newState.errBanco
      })
      return false
    }
    return true
  }

  validateCliCon = () => {
    const newState = validates.validaCliente(this.state)
    const newStateCon = validates.validaContrato(this.state)

    if (newState.errCpf ||
      newState.errNome ||
      newState.errNascimento
      //|| newState.errEndereco|| newState.errNumero || newState.errBairro || newState.errCidade || newState.errCep 
      || newState.errTelefone
      || newStateCon.errAde || newStateCon.errValor
      || newStateCon.errTabela || newStateCon.errTaxa
      || newStateCon.errParcela || newStateCon.errQtdParcela
      || newState.errProduto || newState.errBanco) {
      this.setState({
        errCpf: newState.errCpf, errCpfb: newState.errCpfb,
        errNome: newState.errNome, errNomeb: newState.errNomeb,
        errNascimento: newState.errNascimento, errNascimentob: newState.errNascimentob,
        // errEndereco: newState.errEndereco, errEnderecob: newState.errEnderecob,
        // errNumero: newState.errNumero, errNumerob: newState.errNumerob,
        // errBairro: newState.errBairro, errBairrob: newState.errBairrob,
        // errCidade: newState.errCidade, errCidadeb: newState.errCidadeb,
        // errCep: newState.errCep, errCepb: newState.errCepb,
        errTelefone: newState.errTelefone, errTelefoneb: newState.errTelefoneb,
        errAde: newStateCon.errAde, errAdeb: newStateCon.errAdeb,
        errTabela: newStateCon.errTabela, errTabelab: newStateCon.errTabelab,
        errTaxa: newStateCon.errTaxa, errTaxab: newStateCon.errTaxab,
        errValor: newStateCon.errValor, errValob: newStateCon.errValob,
        errParcela: newStateCon.errParcela, errParcelab: newStateCon.errParcelab,
        errQtdParcela: newStateCon.errQtdParcela, errQtdParcelab: newStateCon.errQtdParcelab,
        errProduto: newState.errProduto, errBanco: newState.errBanco
      })
      return false
    }
    return true
  }

  handleADE = async () => {
    const { ade } = this.state
    var adeData = []
    await api.get(`contrato-ade/${ade}`)
      .then(response => {
        adeData = response.data
      })
      .catch(error => {
        console.log(error.response)
      })

    if (adeData.length === 0) {
      return true
    }
    else {
      return false

    }
  }

  handleSubmit = async e => {
    const { cpf, nome, nascimento, endereco,
      numero, bairro, cidade, estado, cep,
      ade, produto_id, produto, banco_id, banco, valor, parcela, qtdParcela, tabela, taxa,
      responsavel_id, responsavel, indicacao } = this.state
    var vhistcon
    var contrato

    e.preventDefault()
    if (!this.state.isEditing) {
      if (!this.state.haveCliente) {
        if (this.validateCliCon() && this.state.ade !== '') {
          if (await this.handleADE()) {
            this.setState({ isSaving: true })

            let partsNascimento = nascimento.replace(/\D/g, '-').split('-')
            let vnascimento = `${partsNascimento[2]}-${partsNascimento[1]}-${partsNascimento[0]}`
            const cliente = {
              cpf: cpf.replace(/[^\d]+/g, ''), nome: nome.toUpperCase(),
              nascimento: vnascimento, endereco, numero, bairro, cidade, estado, cep
            }
            const vcli = await api.post('cliente', cliente)
            const tel = {
              cliente_id: vcli.data.id,
              cpf_id: cpf.replace(/[^\d]+/g, ''),
            }
            this.state.telefones.map(async (item) => {
              await api.post('telefone', { numero: item, ...tel })
            })
            ////////////////////////// INSERIR CONTRATO ////////////////////////

            const contrato = {
              user_id: responsavel_id, cliente_id: vcli.data.id,
              cpf_id: cpf.replace(/[^\d]+/g, ''),
              ade, produto_id, banco_id,
              valorcontrato: valor.replace(/[R$]/g, '').replace('.', '').replace(',', '.'),
              parcela: parcela.replace(/[R$]/g, ''),
              qntdparcela: parseInt(qtdParcela),
              tabela, taxa: taxa.replace(/[%]/g, ''),
              indicacao,
              situacao: this.state.agDigitacao ? 'DIGITACAO' : 'ANALISE',
            }

            const vcontrato = await api.post('contrato', contrato)

            const histcon = {
              contract_id: vcontrato.data.id,
              situacao: this.state.agDigitacao ? 'DIGITACAO' : 'ANALISE',
              descricao: '',
              historico: '',
              username: jwtDecode(getToken()).data.user[0].username
            }

            const vhistcon = await api.post('historico-contrato', histcon)
            const newcontrato = {
              ...contrato, id: vcontrato.data.id, responsavel: { username: responsavel },
              cli: { nome: nome, nascimento: '1993-07-10', endereco, numero, bairro, cidade, estado, cep }, created_at: new Date(), histcont: [vhistcon.data],
              pro: { descricao: produto },
              ban: { descricao: banco }
            }
            this.props.retrivedata(newcontrato)
            this.props.toggle()
          }
          else {
            window.alert("ADE já cadastrada!")
            this.setState({ ade: '' })
          }
        }
      }
      else {
        if (this.validateCliCon() && this.state.ade !== '') {
          if (await this.handleADE()) {
            const contrato = {
              user_id: responsavel_id, cliente_id: this.state.cliente[0].id,
              cpf_id: cpf.replace(/[^\d]+/g, ''),
              ade, produto_id, banco_id,
              valorcontrato: valor.replace(/[R$]/g, '').replace('.', '').replace(',', '.'),
              parcela: parcela.replace(/[R$]/g, ''),
              qntdparcela: parseInt(qtdParcela),
              tabela, taxa: parseFloat(taxa.replace(/[%]/g, '')),
              indicacao,
              situacao: this.state.agDigitacao ? 'DIGITACAO' : 'ANALISE'
            }

            const vcontrato = await api.post('contrato', contrato)
            const histcon = {
              contract_id: vcontrato.data.id,
              situacao: this.state.agDigitacao ? 'DIGITACAO' : 'ANALISE',
              descricao: '',
              historico: '',
              username: jwtDecode(getToken()).data.user[0].username
            }
            const vhistcon = await api.post('historico-contrato', histcon)

            const newcontrato = {
              ...contrato, id: vcontrato.data.id, responsavel: { username: responsavel },
              cli: { nome: nome, nascimento, endereco, numero, bairro, cidade, estado, cep },
              created_at: new Date(), histcont: [vhistcon.data],
              pro: { descricao: produto },
              ban: { descricao: banco }
            }
            this.props.retrivedata(newcontrato)
            this.props.toggle()
          }
          else {
            window.alert("ADE já cadastrada!")
            this.setState({ ade: '' })
          }
        }
      }
    }
    else {
      if (ade === this.props.sendData[0].ade) {
        if (this.validateContrato()) {
          if (this.state.situacao === 'APROVADO') {

            contrato = {
              user_id: responsavel_id,
              cliente_id: this.props.sendData[0].cliente_id,
              cpf_id: this.props.sendData[0].cpf_id,
              ade, produto_id, banco_id,
              valorcontrato: valor.replace(/[R$]/g, '').replace('.', '').replace(',', '.'),
              parcela: parcela.replace(/[R$]/g, ''),
              qntdparcela: parseInt(qtdParcela),
              tabela, taxa: parseFloat(taxa.replace(/[%]/g, '')),
              indicacao,
              situacao: this.state.situacao,
              dtfinalizacao: new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().split("T")[0]
            }
          } else {
            contrato = {
              user_id: responsavel_id,
              cliente_id: this.props.sendData[0].cliente_id,
              cpf_id: this.props.sendData[0].cpf_id,
              ade, produto_id, banco_id,
              valorcontrato: valor.replace(/[R$]/g, '').replace('.', '').replace(',', '.'),
              parcela: parcela.replace(/[R$]/g, ''),
              qntdparcela: parseInt(qtdParcela),
              tabela, taxa: parseFloat(taxa.replace(/[%]/g, '')),
              indicacao,
              situacao: this.state.situacao,
              dtfinalizacao: null
            }
          }
          await api.put(`contrato/${this.props.sendData[0].id}`, contrato)

          if (this.state.situacao === this.props.sendData[0].situacao) {
            const histcon = {
              contract_id: this.props.sendData[0].id,
              situacao: this.state.situacao,
              historico: `${this.state.situacao} - ${this.state.situacao}`,
              descricao: this.state.areaInfo,
              username: jwtDecode(getToken()).data.user[0].username
            }
            vhistcon = await api.post('historico-contrato', histcon)
          }
          else {
            const histcon = {
              contract_id: this.props.sendData[0].id,
              situacao: this.state.situacao,
              historico: `${this.props.sendData[0].situacao} - ${this.state.situacao}`,
              descricao: this.state.areaInfo,
              username: jwtDecode(getToken()).data.user[0].username
            }
            vhistcon = await api.post('historico-contrato', histcon)
          }

          const newcontrato = {
            ...contrato, id: this.props.sendData[0].id, responsavel: { username: responsavel },
            cli: { nome: nome, nascimento, endereco, numero, bairro, cidade, estado, cep },
            created_at: this.props.sendData[0].created_at, histcont: [...this.props.sendData[0].histcont, vhistcon.data],
            pro: { descricao: produto },
            ban: { descricao: banco }
          }
          this.props.retrivedataEdit(newcontrato)
          this.props.toggle()
        }
      }
      else {
        if (await this.handleADE()) {
          if (this.validateContrato()) {
            if (this.state.situacao === 'APROVADO') {
              contrato = {
                user_id: responsavel_id,
                cliente_id: this.props.sendData[0].cliente_id,
                cpf_id: this.props.sendData[0].cpf_id,
                ade, produto_id, banco_id,
                valorcontrato: valor.replace(/[R$]/g, '').replace('.', '').replace(',', '.'),
                parcela: parcela.replace(/[R$]/g, ''),
                qntdparcela: parseInt(qtdParcela),
                tabela, taxa: parseFloat(taxa.replace(/[%]/g, '')),
                indicacao,
                situacao: this.state.situacao,
                dtfinalizacao: new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().split("T")[0]
              }
            } else {
              contrato = {
                user_id: responsavel_id,
                cliente_id: this.props.sendData[0].cliente_id,
                cpf_id: this.props.sendData[0].cpf_id,
                ade, produto_id, banco_id,
                valorcontrato: valor.replace(/[R$]/g, '').replace('.', '').replace(',', '.'),
                parcela: parcela.replace(/[R$]/g, ''),
                qntdparcela: parseInt(qtdParcela),
                tabela, taxa: parseFloat(taxa.replace(/[%]/g, '')),
                indicacao,
                situacao: this.state.situacao,
                dtfinalizacao: null
              }
            }
            await api.put(`contrato/${this.props.sendData[0].id}`, contrato)

            if (this.state.situacao === this.props.sendData[0].situacao) {
              const histcon = {
                contract_id: this.props.sendData[0].id,
                situacao: this.state.situacao,
                historico: `${this.state.situacao} - ${this.state.situacao}`,
                descricao: this.state.areaInfo,
                username: jwtDecode(getToken()).data.user[0].username
              }
              vhistcon = await api.post('historico-contrato', histcon)
            }
            else {
              const histcon = {
                contract_id: this.props.sendData[0].id,
                situacao: this.state.situacao,
                historico: `${this.props.sendData[0].situacao} - ${this.state.situacao}`,
                descricao: this.state.areaInfo,
                username: jwtDecode(getToken()).data.user[0].username
              }
              vhistcon = await api.post('historico-contrato', histcon)
            }

            const newcontrato = {
              ...contrato, id: this.props.sendData[0].id, responsavel: { username: responsavel },
              cli: { nome: nome, nascimento, endereco, numero, bairro, cidade, estado, cep }, created_at: this.props.sendData[0].created_at,
              histcont: [...this.props.sendData[0].histcont, vhistcon.data],
              pro: { descricao: produto },
              ban: { descricao: banco }
            }
            this.props.retrivedataEdit(newcontrato)
            this.props.toggle()
          }
        }
        else {
          window.alert("ADE já cadastrada!")
          this.setState({ ade: '' })
        }
      }
    }
  }

  addTelefones(e) {
    e.preventDefault()
    const { telefone, telefones } = this.state


    if (telefone !== ''
      && !telefone.includes("_")
      && !telefones.includes(telefone)) {
      telefones.push(telefone)
      this.setState({
        telefones: telefones, telefone: '',
        errTelefone: null, errTelefoneb: false
      })
    }
    else {
      this.setState({ errTelefone: 'Telefone inválido', errTelefoneb: true })
      // alert('Telefone em formato errado ou já existe telefone igual!')
    }
  }

  removeTelefone(e, tel) {
    e.preventDefault()
    let newTelefones = this.state.telefones.filter(item => item !== tel)
    this.setState({ telefones: newTelefones })
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    })
  }

  handleMaskTelefone = () => {
    const { selecionado, maskTelefonex, maskTelefonePlaceholderx } = this.state

    if (!selecionado) {
      this.setState({
        selecionado: true,
        telefone: '',
        maskTelefonePlaceholder: maskTelefonePlaceholderx[1],
        maskTelefone: maskTelefonex[1]
      })
    }
    else {
      this.setState({
        selecionado: false,
        telefone: '',
        maskTelefonePlaceholder: maskTelefonePlaceholderx[0],
        maskTelefone: maskTelefonex[0]
      })
    }
  }

  handleResponsavel(e) {
    this.setState({
      responsavel: e.target.value,
      responsavel_id: e.target.childNodes[e.target.selectedIndex].getAttribute('data-key')
    })
  }

  handleBanco(e) {
    this.setState({
      banco: e.target.value,
      banco_id: e.target.childNodes[e.target.selectedIndex].getAttribute('data-key')
    })
  }

  handleProduto(e) {
    this.setState({
      produto: e.target.value,
      produto_id: e.target.childNodes[e.target.selectedIndex].getAttribute('data-key')
    })
  }

  handleChangeTabela = (selectedOption) => {
    if (selectedOption !== null) {
      this.setState({
        tabela: selectedOption.label,
        tabela_id: selectedOption.id,
        selectedOption: selectedOption,
        taxa: `${selectedOption.taxa} %`
      })
    }
    else {
      this.setState({
        tabela: '',
        tabela_id: '',
        selectedOption: null,
        taxa: ''
      })
    }
  }

  render() {

    const styles = {
      menuList: base => ({
        ...base,
        fontSize: '11px',
        fontWeight: 'bold'
      })
    };

    return (
      <Pane >
        <form onSubmit={this.handleSubmit} id='my-form'>
          <Pane
            display="flex"
            justifyContent="center"
            alignItems="left"
            flexDirection="column">

            <CardContainer sec>
              <Header sec> <Icon icon="info-sign" marginRight={5} />Informação Cliente / Contrato</Header>
              <Pane
                display="flex"
                flexDirection="row"
                background="redTint"
                padding={5}
              >
                {!isFuncionario() ? <SelectField label="Responsável"
                  value={this.state.responsavel}
                  onChange={(e) => this.handleResponsavel(e)}
                  height={45} width="100%" padding={5}>
                  {
                    this.state.responsaveis.map((item) => (
                      <option key={item.id} data-key={item.id}>{item.username}</option>
                    ))
                  }
                </SelectField> : ''}

                <SelectField label="Origem de Cliente?"
                  value={this.state.indicacao}
                  onChange={e => this.setState({ indicacao: e.target.value })}
                  height={45} width="100%" padding={5}>
                  {
                    dataIndicacao.map((item, index) => {
                      return (<option key={index}>{item.ID}</option>)
                    })
                  }
                </SelectField>
              </Pane>
              {!this.state.isEditing ?
                <Pane background="redTint">
                  <Checkbox
                    marginLeft={10}
                    label="Aguardando Digitação?"
                    checked={this.state.agDigitacao}
                    onChange={e => this.setState({ agDigitacao: e.target.checked })}
                  />
                </Pane>
                : ''}
            </CardContainer>

            <CardContainer pri>
              <Header pri> <Icon icon="person" marginRight={5} />Cliente</Header>
              <Pane
                display="flex"
                flexDirection="row"
                background="yellowTint"
                padding={5}
                height={90}
              >
                <TextInputField label="CPF"
                  disabled={this.state.bloqueado}
                  isInvalid={this.state.errCpfb}
                  validationMessage={this.state.errCpf}
                  onChange={e => this.setState({ cpf: e.target.value })}
                  onBlur={() => this.handleLoadCliente()}
                  value={this.state.cpf}
                  width="43%" height={45} placeholder="Digite o cpf do cliente" />

                <TextInputField label="Nome Completo"
                  disabled={this.state.bloqueado}
                  isInvalid={this.state.errNomeb}
                  validationMessage={this.state.errNome}
                  onChange={e => this.setState({ nome: e.target.value })}
                  value={this.state.nome}
                  width="50%" height={45} marginLeft={10} placeholder="Nome do cliente" />

                {!this.state.isEditing ?
                  <NumberFormat
                    disabled={this.state.bloqueado}
                    customInput={TextInputField}
                    format="##/##/####"
                    placeholder="DD/MM/YYYY"
                    label="Nascimento"
                    isInvalid={this.state.errNascimentob}
                    validationMessage={this.state.errNascimento}
                    value={this.state.nascimento}
                    onChange={(e) => { this.setState({ nascimento: e.target.value }) }}
                    width="35%" height={45} marginLeft={10} />
                  : ''}
              </Pane>

              {!this.state.isEditing ?
                <Pane
                  display="flex"
                  flexDirection="row"
                  background="yellowTint"
                  padding={5}
                  height={90}
                >
                  <TextInputField label="Endereço"
                    disabled={this.state.bloqueado}
                    isInvalid={this.state.errEnderecob}
                    validationMessage={this.state.errEndereco}
                    onChange={e => this.setState({ endereco: e.target.value })}
                    value={this.state.endereco}
                    width="50%" height={45} placeholder="Digite o endereço" />

                  <NumberFormat
                    customInput={TextInputField}
                    label="Numero"
                    disabled={this.state.bloqueado}
                    isInvalid={this.state.errNumerob}
                    validationMessage={this.state.errNumero}
                    onChange={e => this.setState({ numero: e.target.value })}
                    value={this.state.numero}
                    width="15%" height={45} marginLeft={10} placeholder="Numero" />

                  <TextInputField label="Bairro"
                    disabled={this.state.bloqueado}
                    isInvalid={this.state.errBairrob}
                    validationMessage={this.state.errBairro}
                    onChange={e => this.setState({ bairro: e.target.value })}
                    value={this.state.bairro}
                    width="32%" height={45} marginLeft={10} placeholder="Digite o bairro" />
                </Pane>
                : ''}

              {!this.state.isEditing ?
                <Pane
                  display="flex"
                  flexDirection="row"
                  background="yellowTint"
                  padding={5}
                  height={90}
                >

                  <TextInputField label="Cidade"
                    disabled={this.state.bloqueado}
                    isInvalid={this.state.errCidadeb}
                    validationMessage={this.state.errCidade}
                    onChange={e => this.setState({ cidade: e.target.value })}
                    value={this.state.cidade}
                    width="50%" height={45} placeholder="Digite a cidade"
                  />

                  <SelectField
                    disabled={this.state.bloqueado}
                    value={this.state.estado}
                    onChange={e => this.setState({ estado: e.target.value })}
                    label="Estado" marginLeft={10} height={45} width="15%">
                    {
                      dataEstados.map((uf, index) => {
                        return (<option key={index}>{uf.UF}</option>)
                      })
                    }
                  </SelectField>

                  <NumberFormat
                    customInput={TextInputField}
                    label="CEP"
                    disabled={this.state.bloqueado}
                    isInvalid={this.state.errCepb}
                    validationMessage={this.state.errCep}
                    onChange={e => this.setState({ cep: e.target.value })}
                    value={this.state.cep}
                    width="32%" height={45} marginLeft={10} placeholder="Digite o CEP" />
                </Pane>
                : ''}

              {!this.state.isEditing ?
                <Pane
                  display="flex"
                  flexDirection="row"
                  background="yellowTint"
                  padding={5}
                  height={90}
                >

                  <Pane
                    display="flex"
                    flexDirection="column"
                    background="yellowTint"
                    padding={2}
                    marginBottom={3}
                  >
                    <MaskedInput
                      disabled={this.state.bloqueado}
                      isInvalid={this.state.errTelefoneb}
                      validationMessage={this.state.errTelefone}
                      value={this.state.telefone}
                      onChange={e => this.setState({ telefone: e.target.value })}
                      mask={this.state.maskTelefone}
                      render={(ref, props) => (
                        <TextInputField innerRef={ref}
                          {...props} label="Celular / Fixo"
                          height={45} width={130}
                          placeholder={this.state.maskTelefonePlaceholder}
                        />
                      )}
                    />
                  </Pane>

                  <Pane >
                    <Switch
                      disabled={this.state.bloqueado} marginLeft={5}
                      checked={this.state.selecionado} onChange={this.handleMaskTelefone}
                    />
                    <IconButton disabled={this.state.bloqueado}
                      onClick={this.addTelefones} marginLeft={5}
                      icon="plus" intent="success" />
                  </Pane>

                  <Pane
                    marginLeft="18px"
                    display="flex"
                    flexDirection="column"
                    align-items="center"
                    background="white"
                    padding={5}
                    marginBottom={5}
                    width="100%"
                  >
                    <Heading is="h6" >Lista Telefones</Heading>
                    {this.state.telefones.map(tel => {
                      return (
                        <Table.Row
                          height={20}
                          key={tel}
                          isSelectable

                        >
                          <Table.TextCell flexBasis={115} flexShrink={0} flexGrow={0}>{tel}</Table.TextCell>
                          <Table.TextCell >
                            <IconButton id="btnDelete" disabled={this.state.bloqueado} onClick={(e) => this.removeTelefone(e, tel)} height={18} icon="trash" intent="danger" />
                          </Table.TextCell>
                        </Table.Row>
                      )
                    })
                    }
                  </Pane>
                </Pane >
                : ''}
            </CardContainer>

            <CardContainer ter>
              <Header ter> <Icon icon="folder-close" marginRight={5} />Contrato</Header>
              <Pane
                background="greenTint"
                display="flex"
                flexDirection="row"
                padding={5}
                height={90}
                justifycontent="space-between"
              >
                <TextInputField
                  label="ADE"
                  isInvalid={this.state.errAdeb}
                  validationMessage={this.state.errAde}
                  onChange={e => this.setState({ ade: e.target.value })}
                  value={this.state.ade}
                  width="50%" height={45} placeholder="ADE contrato" />

                <SelectField
                  isInvalid={this.state.errProduto}
                  value={this.state.produto}
                  onChange={(e) => this.handleProduto(e)}
                  label="Produto" marginLeft={10} height={45} width="50%">
                  {
                    this.state.produtos.map((item) => (
                      <option key={item.id} data-key={item.id}>{item.descricao}</option>
                    ))
                  }
                </SelectField>

                <SelectField
                  isInvalid={this.state.errBanco}
                  value={this.state.banco}
                  onChange={(e) => this.handleBanco(e)}
                  label="Banco" marginLeft={10} height={45} width="50%">
                  {
                    this.state.bancos.map((item) => (
                      <option key={item.id} data-key={item.id}>{item.descricao}</option>
                    ))
                  }
                </SelectField>


              </Pane>

              <Pane
                background="greenTint"
                display="flex"
                flexDirection="row"
                padding={5}
                height={100}
              >
                <Pane
                  flex={2}
                  display="flex"
                  flexDirection="column"
                >
                  <Heading size={400}>Tabela</Heading>
                  <Pane
                    background="tint1"
                    marginTop={5}
                  >
                    <Select
                      styles={styles}
                      maxMenuHeight="150"
                      menuPlacement="top"
                      placeholder="Selecione a Tabela"
                      isClearable
                      isSearchable
                      value={this.state.selectedOption}
                      onChange={this.handleChangeTabela}
                      options={
                        this.state.tabelas.map((item) => (
                          { value: item.id, label: item.descricao, taxa: item.taxa }
                        ))
                      }
                    />
                  </Pane>
                </Pane>

                <TextInputField label="Taxa" inputHeight={40}
                  width="25%" disabled marginLeft={10}
                  placeholder="Taxa" value={this.state.taxa}
                  isInvalid={this.state.errTaxab}
                  validationMessage={this.state.errTaxa} />
              </Pane>

              <Pane
                background="greenTint"
                display="flex"
                flexDirection="row"
                padding={5}
                height={90}
              >
                <NumberFormat
                  customInput={TextInputField}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  prefix={'R$'}
                  label="Parcela"
                  isInvalid={this.state.errParcelab}
                  validationMessage={this.state.errParcela}
                  value={this.state.parcela}
                  onChange={(e) => { this.setState({ parcela: e.target.value }) }}
                  width="33%" height={45} placeholder="Valor parcela" />


                <NumberFormat
                  label="Qntd Parcelas"
                  customInput={TextInputField}
                  isInvalid={this.state.errQtdParcelab}
                  validationMessage={this.state.errQtdParcela}
                  value={this.state.qtdParcela}
                  onChange={(e) => { this.setState({ qtdParcela: e.target.value }) }}
                  width="33%" height={45} marginLeft={10} placeholder="Qntd parcelas" />

                <NumberFormat
                  customInput={TextInputField}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  prefix={'R$'}
                  label="Valor Contrato"
                  isInvalid={this.state.errValob}
                  validationMessage={this.state.errValor}
                  value={this.state.valor}
                  onChange={(e) => { this.setState({ valor: e.target.value }) }}
                  width="33%" height={45} marginLeft={10} placeholder="Valor contrato" />
              </Pane>
            </CardContainer>
            {this.state.isEditing ?
              (
                <CardContainer quar>
                  <Header quar> <Icon icon="timeline-events" marginRight={5} />Situação Contrato / Informações</Header>
                  <Pane
                    background="tealTint"
                    display="flex"
                    flexDirection="column"
                    padding={10}
                  >
                    <SelectField
                      width='100%'
                      value={this.state.situacao}
                      onChange={e => this.setState({ situacao: e.target.value })}
                      label="Situação Contrato"
                      height={40}>
                      <option value="ANALISE">ANÁLISE</option>
                      <option value="CANCELADO">CANCELADO</option>
                      <option value="APROVADO">APROVADO</option>
                      <option value="PENDENTE">PENDENTE</option>
                      <option value="PENDENTE_RE">PENDENTE/RE</option>
                    </SelectField>

                    <Label
                      htmlFor="textarea-2"
                      marginBottom={4}
                      display="block"
                    >
                      Informação Adicional
                        </Label>
                    <Textarea
                      id="textarea-2"
                      placeholder="Digite o texto..."
                      onChange={e => this.setState({ areaInfo: e.target.value })}
                      value={this.state.areaInfo}
                    />
                  </Pane>
                </CardContainer>
              ) : ''
            }
          </Pane>
        </form>
        <Pane
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          marginTop={15}
        >

          <Button
            disabled={this.state.isSaving}
            height={40}
            onClick={this.handleSubmit}
            value='Submit'
            appearance="primary"
            intent="success"
            iconBefore="add">
            Salvar
                  </Button>
          <Button disabled={this.state.isSaving} onClick={this.props.toggle} marginLeft={10} height={40} appearance="primary" intent="danger" iconBefore="close">Cancelar</Button>
        </Pane>
      </Pane>
    )
  }
}
