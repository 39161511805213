import React from 'react';
import { Container, Content, ButtonPill, Texto, SubTexto } from './styles'

const PageNotFound = (props) => (
    <Container>
        <Content>
            <Texto>404</Texto> 
            <SubTexto>Pagina não encontrada</SubTexto> 
            <ButtonPill onClick={() =>  props.history.push("/")}>Voltar a tela inicial</ButtonPill>
        </Content>
    </Container>
)

export default PageNotFound;
