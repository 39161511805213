import React, { useState, useEffect } from 'react';
import { Pane, Button, SegmentedControl, toaster } from 'evergreen-ui'
import ListaAgendamentos from './ListaAgendamento/index'
import Modal from './ModalAgendamento/index'
import api from '../../services/api'
import jwtDecode from 'jwt-decode'
import { getToken } from '../../services/auth'
import { isAdministrador } from '../../helpers/validateUser'

const Agendamento = () => {

    const options = [
        { label: 'Agendado', value: 'AGENDADO' },
        { label: 'Histórico', value: 'HISTORICO' }
    ]

    const initialState = []
    const [dataAgendado, setDataAgendado] = useState(initialState)
    const [isLoading, setLoading] = useState(false)
    const [isModal, setIsModal] = useState(false)
    const [optionsValue, setOptionsValue] = useState('AGENDADO')

    const openModal = () => {
        setIsModal(true)
    }

    const closeModal = () => {
        setIsModal(false)
    }

    async function getAgendamentos() {
        const decodedUser = jwtDecode(getToken()).data.user[0]
        const page = 1
        if (isAdministrador()) {
            const response = await api.get(`agendamentos/${page}`)
            setDataAgendado(response.data)
        } else {
            const response = await api.get(`agendamentos-funcionario/${decodedUser.id}/${page}`)
            setDataAgendado(response.data)
        }
    }

    useEffect(() => {
        setLoading(true)
        getAgendamentos()
        setLoading(false)
    }, []);


    const addAgendamento = async (agendamento) => {
        let decodedUser = jwtDecode(getToken()).data.user[0]
        const response = await api.post('/agendamento', { ...agendamento, user_id: decodedUser.id })

        if (optionsValue === 'AGENDADO') {
            let dataMenor, dataMaior
            dataMenor = dataAgendado.data.filter(item => new Date(item.dtagendamento).getTime() <= parseInt(agendamento.dtagendamento.getTime(), 10))
            dataMaior = dataAgendado.data.filter(item => new Date(item.dtagendamento).getTime() >= parseInt(agendamento.dtagendamento.getTime(), 10))
            setDataAgendado({ data: [...dataMenor, { ...response.data, responsavel: { username: decodedUser.username } }, ...dataMaior] })
        }
        toaster.success(
            'Agendamento Adicionado',
            {
                duration: 3
            }
        )
    }

    const handleConfirm = async (id) => {
        await api.put(`/agendamento/${id}`, { 'situacao': 1 })
        setDataAgendado({ ...dataAgendado, data: dataAgendado.data.filter(item => item.id !== id) })
        toaster.success(
            'Agendamento Confirmado',
            {
                duration: 3
            }
        )
    }

    const handlePagination = async (page) => {
        const decodedUser = jwtDecode(getToken()).data.user[0]
        setLoading(true)
        if (optionsValue === 'HISTORICO') {
            if (isAdministrador()) {
                const response = await api.get(`agendamentos-historico/${page}`)
                setDataAgendado(response.data)
            } else {
                const response = await api.get(`agendamentos-historico-funcionario/${decodedUser.id}/${page}`)
                setDataAgendado(response.data)
            }

        } else {
            if (isAdministrador()) {
                const response = await api.get(`agendamentos/${page}`)
                setDataAgendado(response.data)
            } else {
                const response = await api.get(`agendamentos-funcionario/${decodedUser.id}/${page}`)
                setDataAgendado(response.data)
            }
        }
        setLoading(false)
    }

    const handleFiltroChange = async (value) => {
        const decodedUser = jwtDecode(getToken()).data.user[0]
        setOptionsValue(value)
        setLoading(true)
        if (value === 'HISTORICO') {
            if (isAdministrador()) {
                let page = 1
                const response = await api.get(`agendamentos-historico/${page}`)
                setDataAgendado(response.data)
            } else {
                let page = 1
                const response = await api.get(`agendamentos-historico-funcionario/${decodedUser.id}/${page}`)
                setDataAgendado(response.data)
            }

        } else {
            if (isAdministrador()) {
                let page = 1
                const response = await api.get(`agendamentos/${page}`)
                setDataAgendado(response.data)
            } else {
                let page = 1
                const response = await api.get(`agendamentos-funcionario/${decodedUser.id}/${page}`)
                setDataAgendado(response.data)
            }
        }
        setLoading(false)
    }

    return (
        <Pane
            display="flex"
            width="100%"
            flexDirection="column">

            <Pane marginLeft={5} marginTop={5} display="flex" flexDirection="row" justifyContent="space-between">
                <Button
                    onClick={openModal}
                    iconBefore="calendar"
                    height={32}
                    appearance="primary" intent="success">
                    Agendar
             </Button>

                <Pane width="50%" marginLeft={10} marginRight={5}>
                    <SegmentedControl
                        height={32}
                        options={options}
                        value={optionsValue}
                        onChange={value => handleFiltroChange(value)}
                    />
                </Pane>
            </Pane>

            <Pane marginTop={10} >
                <ListaAgendamentos data={dataAgendado} isLoading={isLoading} handleConfirm={handleConfirm} handlePagination={handlePagination} />
            </Pane>

            {isModal ?
                <Modal isOpen={isModal} onClose={closeModal} addAgendamento={addAgendamento} /> : ''
            }
        </Pane>
    )
}

export default Agendamento;
