import styled from 'styled-components';

export const Container = styled.div`
   height:100vh;
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
    width: 100%;
    margin: 10px;
    border-radius: 4px;
    padding: 5px;
    background-color: #cccc;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
`;

export const ButtonPill = styled.button`
 background: orange;
  color: white;
  font-size: 15px;
  padding: 10px 30px;
  border: none;
  border-radius: 3px;
  font-weight: 500;
  margin: 20px;
  cursor:pointer;

  &:hover {
    opacity: 0.7;
  }
`;

export const Texto = styled.h1`
text-decoration: underline overline;
font-size: 200px;
font-weight: bold;
`
export const SubTexto = styled.h2`
font-size: 50px;
`
