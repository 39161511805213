import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import UploadDocument from './UploadDocument/index'
import {
    Button,
  } from 'evergreen-ui'

export default class Cliente extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
        };

        this.onClose = this.onClose.bind(this);
        this.openModal = this.openModal.bind(this)
    }

    componentDidMount() {
        if (this.props.isOpen)
            this.openModal()
        else
            this.onClose()
    }

    openModal() {
        this.setState({ modal: true })
    }

    onClose() {
        this.props.onClose();
    }


    render() {
        let { sendID } = this.props
        return (
            <Modal size="lg" isOpen={this.state.modal}  className={this.props.className}>
                <ModalHeader >Upload Documentos</ModalHeader>
                <ModalBody>
                    <UploadDocument id={sendID} />
                </ModalBody>
                <ModalFooter>
                    <Button appearance="primary" intent="danger" height={32} onClick={this.onClose}>Fechar</Button>
                </ModalFooter>
            </Modal>
        )
    }
}
