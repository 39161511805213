import React, { Component } from 'react';
import api from '../../../services/api'
import {
    Pane,
    Button,
    Heading,
    Spinner
} from "evergreen-ui";
import DatePicker from 'react-date-picker'
import { Doughnut } from 'react-chartjs-2';

const options = {
    "display": true,
    "position": "top",
    "fullWidth": true,
    "reverse": false,
    maintainAspectRatio: false,
    tooltips: {
        callbacks: {
            label: function (tooltipItem, data) {
                var dataset = data.datasets[tooltipItem.datasetIndex];
                var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                var total = meta.total;
                var currentValue = dataset.data[tooltipItem.index];
                var percentage = parseFloat((currentValue / total * 100).toFixed(1));
                return currentValue + ' (' + percentage + '%)';
            },
            title: function (tooltipItem, data) {
                return data.labels[tooltipItem[0].index];
            }
        }
    }
}

var dynamicColors = function () {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    return "rgb(" + r + "," + g + "," + b + ")";
}

export default class Marketing extends Component {
    constructor(props) {
        super(props)
        this.state = {
            teste: [],
            data: { datasets: [], labels: [] },
            dtinicial: null,
            dtfinal: null,
            isBusca: false,
            isLoading: false,
            cardPeriodo: '',
            total: 0,
        }
    }

    onChangeDTini = date => this.setState({ dtinicial: date })
    onChangeDTfinal = date => this.setState({ dtfinal: date })

    async componentWillMount() {
        this.setState({ isLoading: true })
        await api.get(`relatorioMarketing/`)
            .then(response => {
                this.setState({
                    teste: response.data[0],
                    isLoading: false, isBusca: true
                })
            })
            .catch(error => {
                console.log(error.response)
            })

        this.setState({
            data: {
                datasets: [
                    {
                        backgroundColor: this.state.teste.map(item => dynamicColors()),
                        data: this.state.teste.map(item => item.total),
                    }
                ],
                labels: this.state.teste.map(item => item.indicacao)
            },
            total: this.state.teste.reduce((acumulador, item) => acumulador + item.total, 0)
        })
    }


    handlePesquisa = async (e) => {
        e.preventDefault()
        const { dtinicial, dtfinal } = this.state

        if (dtinicial !== null && dtfinal !== null) {
            let dtini = dtinicial.getTime()
            let dtfim = dtfinal.getTime()
            if (parseInt(dtfim, 10) >= parseInt(dtini, 10)) {
                this.setState({ isLoading: true, isBusca: false })
                await api.get(`relatorioMarketingDT/${dtini}/${dtfim}`)
                    .then(response => {
                        this.setState({
                            teste: response.data[0],
                            isLoading: false,
                            isBusca: true,
                            cardPeriodo: ` ${dtinicial.toLocaleDateString()} Até: ${dtfinal.toLocaleDateString()}`
                        })
                    })
                    .catch(error => {
                        console.log(error.response)
                    })

                this.setState({
                    data: {
                        datasets: [
                            {
                                backgroundColor: this.state.teste.map(item => dynamicColors()),
                                data: this.state.teste.map(item => item.total),
                            }
                        ],
                        labels: this.state.teste.map(item => item.indicacao)
                    },
                    total: this.state.teste.reduce((acumulador, item) => acumulador + item.total, 0)
                })
            }
            else {
                window.alert("Data inicial precisa ser menor que data final!")
            }
        }
        else {
            window.alert("É preciso selecionar data inicial e data final!")
        }
    }

    render() {
        return (
            <Pane border="default" display="flex" flexDirection="column" padding={10}>
                <Pane
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"
                    border="default"
                    justifyContent="center"
                    padding={10}
                >
                    <Pane
                        display="flex"
                        flexDirection="column"
                        marginTop={5}
                    >
                        <Heading size={400}>Data Inicial</Heading>
                        <DatePicker
                            calendarIcon={null}
                            onChange={this.onChangeDTini}
                            value={this.state.dtinicial}
                        />
                    </Pane>
                    <Pane
                        display="flex"
                        flexDirection="column"
                        marginLeft={10}
                        marginTop={5}
                    >
                        <Heading size={400}>Data Final</Heading>
                        <DatePicker
                            calendarIcon={null}
                            onChange={this.onChangeDTfinal}
                            value={this.state.dtfinal}
                        />
                    </Pane>
                    <Button onClick={this.handlePesquisa} disabled={this.state.isLoading} iconBefore="search" marginTop={23} marginLeft={10} height={32}>Filtrar</Button>
                </Pane>
                <Pane
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"
                    justifyContent="center"
                    padding={10}
                    height={490}
                >
                    {this.state.isLoading ?
                        <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
                            <Spinner />
                        </Pane> : ''}
                    {this.state.isBusca ?
                        <Pane display="flex" flexDirection="column" alignItems="center" justifyContent="center" flexWrap="wrap" >
                            <strong>Relatório de Marketing</strong>
                            <div>
                                <Doughnut data={this.state.data} options={options} />
                            </div>
                            <strong>Total: {this.state.total}</strong>
                        </Pane> : ''}
                </Pane>
            </Pane>
        )
    }
}
