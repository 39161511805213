import React, { Component } from 'react';
import {
    Pane,
    SelectField,
    TextInputField,
    Table,
    IconButton,
    Heading,
    Switch,
    Button,
    Icon,
    toaster,
    Label,
    Textarea
} from "evergreen-ui";
import MaskedInput from 'react-text-mask'
import NumberFormat from 'react-number-format';
import api from '../../services/api'
import dataEstados from '../../helpers/estados'
import validates from '../../helpers/validates'
import { CardContainer, Header } from './ListaCliente/styles'

const initialState = {
    bloqueado: false,
    isSaving: false,
    cliente: [],
    telefones: [],
    maskTelefonePlaceholder: "(18)99999-9999",
    maskTelefonePlaceholderx: ["(18)99999-9999", "(18)9999-9999"],
    maskTelefone: ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    maskTelefonex: [['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]],
    selecionado: false,

    telefone: '',
    errTelefone: null,
    errTelefoneb: false,

    cpf: '',
    errCpf: null,
    errCpfb: false,

    nome: '',
    errNome: null,
    errNomeb: false,

    nascimento: new Date().toLocaleDateString(),
    errNascimento: null,
    errNascimentob: false,

    endereco: '',
    errEndereco: null,
    errEnderecob: false,

    numero: '',
    errNumero: null,
    errNumerob: false,

    bairro: '',
    errBairro: null,
    errBairrob: false,

    cidade: '',
    errCidade: null,
    errCidadeb: false,

    estado: 'SP',
    errEstado: null,
    errEstadob: false,

    cep: '',
    errCep: null,
    errCepb: false,

    observacao: '',
}

export default class Cliente extends Component {
    constructor(props) {
        super(props)
        this.state = initialState

        this.handleMaskTelefone = this.handleMaskTelefone.bind(this)
        this.addTelefones = this.addTelefones.bind(this)
        this.removeTelefone = this.removeTelefone.bind(this)
    }

    limparForm = () => {
        this.setState({
            telefones: [], telefone: '', cpf: '',
            nome: '', nascimento: new Date().toLocaleDateString(), endereco: '',
            numero: '', bairro: '', cidade: '', estado: 'SP',
            cep: '', observacao: '', isSaving: false
        })

    }

    validateCli = () => {
        const newState = validates.validaCliente(this.state)

        if (newState.errCpf || newState.errNome || newState.errEndereco
            || newState.errNumero || newState.errBairro || newState.errCidade
            || newState.errCep || newState.errTelefone) {
            this.setState({
                errCpf: newState.errCpf, errCpfb: newState.errCpfb,
                errNome: newState.errNome, errNomeb: newState.errNomeb,
                errEndereco: newState.errEndereco, errEnderecob: newState.errEnderecob,
                errNumero: newState.errNumero, errNumerob: newState.errNumerob,
                errBairro: newState.errBairro, errBairrob: newState.errBairrob,
                errCidade: newState.errCidade, errCidadeb: newState.errCidadeb,
                errCep: newState.errCep, errCepb: newState.errCepb,
                errTelefone: newState.errTelefone, errTelefoneb: newState.errTelefoneb
            })
            return false
        }
        return true
    }

    componentDidMount() {
        this.setState({ telefones: [], bloqueado: this.props.isDisable, })

    }

    handleSubmit = async e => {
        const { cpf, nome, nascimento, endereco,
            numero, bairro, cidade, estado, cep, observacao } = this.state
        e.preventDefault()
        if (this.validateCli()) {
            if (await this.handleCPF()) {
                this.setState({ isSaving: true })

                let partsNascimento = nascimento.replace(/\D/g, '-').split('-')
                let vnascimento = `${partsNascimento[2]}-${partsNascimento[1]}-${partsNascimento[0]}`
                const cliente = {
                    cpf: cpf.replace(/[^\d]+/g, ''), nome: nome.toUpperCase(), nascimento: vnascimento, endereco,
                    numero, bairro, cidade, estado, cep, observacao
                }

                const vcli = await api.post('cliente', cliente)

                //PEGAR ID DO CLIENTE
                const tel = {
                    cliente_id: vcli.data.id,
                    cpf_id: cpf.replace(/[^\d]+/g, ''),
                }

                this.state.telefones.map(async (item) => {
                    // var numero = item.replace(/[^\d]+/g, '')
                    await api.post('telefone', { numero: item, ...tel })
                })

                toaster.success(
                    'Cliente cadastrado com sucesso',
                    {
                        duration: 3
                    }
                )
                this.limparForm()
            }
            else {
                window.alert("CPF já cadastrado!");
                this.limparForm()
            }
        }
    }

    handleCPF = async () => {
        const { cpf } = this.state
        var data = []
        let vcpf = cpf.replace(/[^\d]+/g, '');
        await api.get(`cliente/${vcpf}`)
            .then(response => {
                data = response.data
            })
            .catch(error => {
                console.log(error.response)
            });

        if (data.length === 0) {
            return true
        }
        else {
            return false
        }
    }

    handleMaskTelefone = () => {
        const { selecionado, maskTelefonex, maskTelefonePlaceholderx } = this.state

        if (!selecionado) {
            this.setState({
                selecionado: true,
                telefone: '',
                maskTelefonePlaceholder: maskTelefonePlaceholderx[1],
                maskTelefone: maskTelefonex[1]
            })
        }
        else {
            this.setState({
                selecionado: false,
                telefone: '',
                maskTelefonePlaceholder: maskTelefonePlaceholderx[0],
                maskTelefone: maskTelefonex[0]
            })
        }
    }

    addTelefones(e) {
        e.preventDefault()
        const { telefone, telefones } = this.state


        if (telefone !== ''
            && !telefone.includes("_")
            && !telefones.includes(telefone)) {
            telefones.push(telefone)
            this.setState({
                telefones: telefones, telefone: '',
                errTelefone: null, errTelefoneb: false
            })
        }
        else {
            this.setState({ errTelefone: 'Telefone inválido', errTelefoneb: true })
        }
    }

    removeTelefone(e, tel) {
        e.preventDefault()
        let newTelefones = this.state.telefones.filter(item => item !== tel)
        this.setState({ telefones: newTelefones })
    }


    render() {
        return (
            <Pane >
                <CardContainer pri>
                    <Header pri> <Icon icon="person" marginRight={5} />Cliente</Header>
                    <form onSubmit={this.handleSubmit} id='my-form'>
                        <Pane
                            display="flex"
                            background="tint1"
                            justifyContent="center"
                            alignItems="left"
                            flexDirection="column">

                            <Pane
                                display="flex"
                                flexDirection="row"
                                background="yellowTint"
                                padding={10}
                                flexWrap="wrap"
                            >
                                <TextInputField label="CPF"
                                    marginTop={15}
                                    disabled={this.state.bloqueado}
                                    isInvalid={this.state.errCpfb}
                                    validationMessage={this.state.errCpf}
                                    onChange={e => this.setState({ cpf: e.target.value })}
                                    value={this.state.cpf}
                                    width="100%" height={45} placeholder="Digite o cpf do cliente" />

                                <TextInputField label="Nome Completo"
                                    marginTop={15}
                                    disabled={this.state.bloqueado}
                                    isInvalid={this.state.errNomeb}
                                    validationMessage={this.state.errNome}
                                    onChange={e => this.setState({ nome: e.target.value })}
                                    value={this.state.nome}
                                    width="100%" height={45} placeholder="Nome do cliente" />

                                <NumberFormat
                                    marginTop={15}
                                    disabled={this.state.bloqueado}
                                    customInput={TextInputField}
                                    format="##/##/####"
                                    placeholder="DD/MM/YYYY"
                                    label="Nascimento"
                                    isInvalid={this.state.errNascimentob}
                                    validationMessage={this.state.errNascimento}
                                    value={this.state.nascimento}
                                    onChange={(e) => { this.setState({ nascimento: e.target.value }) }}
                                    width="100%" height={45} />
                            </Pane>

                            <Pane
                                display="flex"
                                flexDirection="row"
                                background="yellowTint"
                                padding={10}
                            >
                                <TextInputField label="Endereço"
                                    disabled={this.state.bloqueado}
                                    isInvalid={this.state.errEnderecob}
                                    validationMessage={this.state.errEndereco}
                                    onChange={e => this.setState({ endereco: e.target.value })}
                                    value={this.state.endereco}
                                    width="50%" height={45} placeholder="Digite o endereço" />

                                <NumberFormat
                                    customInput={TextInputField}
                                    label="Numero"
                                    disabled={this.state.bloqueado}
                                    isInvalid={this.state.errNumerob}
                                    validationMessage={this.state.errNumero}
                                    onChange={e => this.setState({ numero: e.target.value })}
                                    value={this.state.numero}
                                    width="15%" height={45} marginLeft={10} placeholder="Numero" />

                                <TextInputField label="Bairro"
                                    disabled={this.state.bloqueado}
                                    isInvalid={this.state.errBairrob}
                                    validationMessage={this.state.errBairro}
                                    onChange={e => this.setState({ bairro: e.target.value })}
                                    value={this.state.bairro}
                                    width="38%" height={45} marginLeft={10} placeholder="Digite o bairro" />
                            </Pane>

                            <Pane
                                display="flex"
                                flexDirection="row"
                                background="yellowTint"
                                padding={10}
                            >
                                <TextInputField label="Cidade"
                                    disabled={this.state.bloqueado}
                                    isInvalid={this.state.errCidadeb}
                                    validationMessage={this.state.errCidade}
                                    onChange={e => this.setState({ cidade: e.target.value })}
                                    value={this.state.cidade}
                                    width="50%" height={45} placeholder="Digite a cidade" />

                                <SelectField
                                    disabled={this.state.bloqueado}
                                    value={this.state.estado}
                                    onChange={e => this.setState({ estado: e.target.value })}
                                    label="Estado" marginLeft={10} height={45} width="15%">
                                    {
                                        dataEstados.map((uf, index) => {
                                            return (<option key={index}>{uf.UF}</option>)
                                        })
                                    }
                                </SelectField>

                                <NumberFormat
                                    customInput={TextInputField}
                                    label="CEP"
                                    disabled={this.state.bloqueado}
                                    isInvalid={this.state.errCepb}
                                    validationMessage={this.state.errCep}
                                    onChange={e => this.setState({ cep: e.target.value })}
                                    value={this.state.cep}
                                    width="38%" height={45} marginLeft={10} placeholder="Digite o CEP" />
                            </Pane>

                            <Pane
                                display="flex"
                                flexDirection="row"
                                background="yellowTint"
                                padding={10}
                            >


                                <Pane
                                    display="flex"
                                    flexDirection="column"
                                    background="yellowTint"
                                    padding={2}
                                >
                                    <MaskedInput
                                        disabled={this.state.bloqueado}
                                        isInvalid={this.state.errTelefoneb}
                                        validationMessage={this.state.errTelefone}
                                        value={this.state.telefone}
                                        onChange={e => this.setState({ telefone: e.target.value })}
                                        mask={this.state.maskTelefone}
                                        render={(ref, props) => (
                                            <TextInputField innerRef={ref}
                                                {...props} label="Celular / Fixo"
                                                height={45} width={130}
                                                placeholder={this.state.maskTelefonePlaceholder}
                                            />
                                        )}
                                    />
                                </Pane>

                                <Pane >
                                    <Switch
                                        disabled={this.state.bloqueado} marginLeft={5}
                                        checked={this.state.selecionado} onChange={this.handleMaskTelefone}
                                    />
                                    <IconButton disabled={this.state.bloqueado}
                                        onClick={this.addTelefones} marginLeft={5}
                                        icon="plus" intent="success" />
                                </Pane>
                                <Pane
                                    marginLeft="18px"
                                    display="flex"
                                    flexWrap="wrap"
                                    flexDirection="column"
                                    background="white"
                                    padding={5}
                                    width="100%"
                                >
                                    <Pane display="flex" alignItems="center" justifyContent="center">
                                        <Heading is="h6" >Lista Telefones</Heading>
                                    </Pane>
                                    {this.state.telefones.map(tel => {
                                        return (
                                            <Table.Row
                                                height={20}
                                                key={tel}
                                                isSelectable

                                            >
                                                <Table.TextCell flexBasis={115} flexShrink={0} flexGrow={0}>{tel}</Table.TextCell>
                                                <Table.TextCell >
                                                    <IconButton id="btnDelete" disabled={this.state.bloqueado} onClick={(e) => this.removeTelefone(e, tel)} height={18} icon="trash" intent="danger" />
                                                </Table.TextCell>
                                            </Table.Row>
                                        )
                                    })}
                                </Pane>

                            </Pane >
                            <Pane
                                display="flex"
                                flexDirection="column"
                                background="yellowTint"
                                padding={10} >
                                <Label
                                    htmlFor="textarea-2"
                                    marginBottom={4}
                                    display="block"
                                >
                                    Observação:
                               </Label>
                                <Textarea
                                    id="textarea-2"
                                    placeholder="Digite o texto..."
                                    onChange={e => this.setState({ observacao: e.target.value })}
                                    value={this.state.observacao}
                                />
                            </Pane>
                        </Pane>
                    </form>
                </CardContainer>
                <Pane
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    marginTop={15}
                >
                    <Button disabled={this.state.isSaving} height={40} form='my-form' content='Submit' value='Submit' appearance="primary" intent="success" iconBefore="add">
                        Adicionar
                    </Button>

                    <Button disabled={this.state.isSaving} height={40} onClick={this.limparForm} marginLeft={10} appearance="primary" iconBefore="remove">
                        Limpar
                    </Button>
                </Pane>
            </Pane>
        )
    }
}
