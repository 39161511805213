import React, { useState, useEffect } from 'react';
import AddProduto from './forms/AddProduto'
import EditProduto from './forms/EditProduto'
import ListaProdutos from './ListaProdutos/index'
import {
    Pane,
    Heading,
    Spinner,
    toaster
} from "evergreen-ui";
import api from '../../services/api'

const Produto = () => {
    const produtosData = []
    const [produtos, setProdutos] = useState(produtosData)

    const initialFormState = { id: null, descricao: '' }
    const [currentProduto, setCurrentProduto] = useState(initialFormState)

    const [isLoading, setLoading] = useState(false)
    const [editing, setEditing] = useState(false)

    async function getProdutos() {
        const response = await api.get(`produto/`)
        setProdutos([...response.data])
    }

    useEffect(() => {
        setLoading(true)
        getProdutos()
        setLoading(false)
    }, []);

    const addProduto = async produto => {
        setLoading(true)
        const vpost = await api.post(`produto/`, { ...produto })
        setProdutos([...produtos, { ...vpost.data }])
        toaster.success(
            'Produto adicionado com sucesso',
            {
                duration: 3
            }
        )
        setLoading(false)
    }


    const handleDelete = async (e, id) => {
        e.preventDefault()
        if (window.confirm("Você realmente deseja deletar o produto?")) {
            setLoading(true)
            await api.delete(`produto/${id}`)
            toaster.success(
                'Produto deletado com sucesso',
                {
                    duration: 3
                }
            )
            setProdutos(produtos.filter(item => item.id !== id))
            setLoading(false)
        }
    }

    const handleEdit = (e, id) => {
        e.preventDefault()
        setEditing(true)
        setCurrentProduto(produtos.filter(item => item.id === id))
    }

    const handleUpdateProduto = async (id, updateProduto) => {
        setEditing(false)
        setLoading(true)
        await api.put(`produto/${id}`, updateProduto)
        let oldProdutos = produtos.filter(item => item.id !== id)
        setProdutos([...oldProdutos, { ...updateProduto, id }])
        toaster.success(
            'Produto editado com sucesso',
            {
                duration: 3
            }
        )
        setLoading(false)
    }

    const handleCancel = (e) => {
        e.preventDefault()
        setEditing(false)
    }

    return (
        <Pane display="flex" border="default" flexDirection="column">
            <Pane padding={10}>
                {!editing ?
                    (
                        <AddProduto addProduto={addProduto} />
                    )
                    :
                    (<EditProduto handleCancel={handleCancel} currentProduto={currentProduto} handleUpdateProduto={handleUpdateProduto} />)
                }
            </Pane>
            {isLoading ?
                <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
                    <Spinner />
                </Pane>
                :
                <Pane marginTop={20}>
                    <Pane display="flex" alignItems="center" justifyContent="center" >
                        <Heading size={800}>Lista de Produtos</Heading>
                    </Pane>
                    <Pane padding={10} >
                        <ListaProdutos data={produtos} handleDelete={handleDelete} handleEdit={handleEdit} />
                    </Pane>
                </Pane>}
        </Pane>
    )
}

export default Produto;
