import React, { useState, useEffect } from 'react';
import {
    SelectField,
    Pane,
    Heading,
    Button
} from 'evergreen-ui'
import api from '../../../services/api'
import DatePicker from 'react-date-picker'
import ListaComissao from './ListaComissao'

const Comissao = () => {

    const initialState = {
        id: 0,
        username: 'TODOS'
    }
    const initialStatePD = {
        id: 0,
        descricao: 'TODOS'
    }

    const respData = []
    const [responsavelData, setResponsavelData] = useState(respData)
    const [responsavel, setResponsavel] = useState(initialState)

    const bancData = []
    const [bancoData, setBancoData] = useState(bancData)
    const [banco, setBanco] = useState(initialStatePD)

    const prodData = []
    const [produtoData, setProdutoData] = useState(prodData)
    const [produto, setProduto] = useState(initialStatePD)

    const [dtinicial, setDtinicial] = useState(null)
    const [dtfinal, setDtfinal] = useState(null)
    const [dataComissao, setDatacomissao] = useState([])
    const [isLoading, setIsloading] = useState(false)

    const onChangeDTini = date => setDtinicial(date)
    const onChangeDTfinal = date => setDtfinal(date)

    useEffect(() => {
        carregarData()
    }, []);

    async function carregarData() {
        let result = await api.get("listFuncionarios")
        setResponsavelData([{ id: 0, username: 'TODOS' }, ...result.data])

        result = await api.get('banco')
        setBancoData([{ id: 0, descricao: 'TODOS' }, ...result.data])

        result = await api.get('produto')
        setProdutoData([{ id: 0, descricao: 'TODOS' }, ...result.data])
    }

    const handleResponsavel = (e) => {
        setResponsavel({
            id: e.target.childNodes[e.target.selectedIndex].getAttribute('data-key'),
            username: e.target.value,
        })
    }

    const handleBanco = (e) => {
        setBanco({
            id: e.target.childNodes[e.target.selectedIndex].getAttribute('data-key'),
            descricao: e.target.value,
        })
    }

    const handleProduto = (e) => {
        setProduto({
            id: e.target.childNodes[e.target.selectedIndex].getAttribute('data-key'),
            descricao: e.target.value,
        })
    }

    const handlePesquisa = async (e) => {
        e.preventDefault()
        setDatacomissao([])
        if (dtinicial === null && dtfinal === null) {
            setIsloading(true)
            if (responsavel.username === 'TODOS') {
                const response = await api.get(`comissaoFuncionarioGeral/${produto.id}/${banco.id}`)
                if (response.data.length > 0) {
                    let lucro, comissao
                    let auxData = []

                    auxData = response.data.map((item) => {
                        lucro = (parseFloat(item.valorcontrato) * parseFloat(item.taxa / 100)).toFixed(2)
                        comissao = (lucro * (7.5 / 100)).toFixed(2)
                        return { ...item, lucro, comissao }
                    })
                    setDatacomissao(auxData)
                }
            } else {
                const response = await api.get(`relatorioComissao/${responsavel.id}/${produto.id}/${banco.id}`)

                if (response.data.length > 0) {
                    let lucro, comissao
                    let auxData = []

                    auxData = response.data.map((item) => {
                        lucro = (parseFloat(item.valorcontrato) * parseFloat(item.taxa / 100)).toFixed(2)
                        comissao = (lucro * (7.5 / 100)).toFixed(2)
                        return { ...item, lucro, comissao }
                    })
                    setDatacomissao(auxData)
                }
            }
            setIsloading(false)
        }
        else {
            if (dtinicial !== null && dtfinal !== null) {
                let dtini = dtinicial.getTime()
                let dtfim = dtfinal.getTime()
                if (parseInt(dtfim, 10) >= parseInt(dtini, 10)) {
                    setIsloading(true)
                    if (responsavel.username === 'TODOS') {
                        const response = await api.get(`/comissaoFuncionarioDTGeral/${dtini}/${dtfim}/${produto.id}/${banco.id}`)
                        if (response.data.length > 0) {
                            let lucro, comissao
                            let auxData = []

                            auxData = response.data.map((item) => {
                                lucro = (parseFloat(item.valorcontrato) * parseFloat(item.taxa / 100)).toFixed(2)
                                comissao = (lucro * (7.5 / 100)).toFixed(2)
                                return { ...item, lucro, comissao }
                            })
                            setDatacomissao(auxData)
                        }
                    } else {
                        const response = await api.get(`/relatorioComissaoDT/${responsavel.id}/${dtini}/${dtfim}/${produto.id}/${banco.id}`)
                        if (response.data.length > 0) {
                            let lucro, comissao
                            let auxData = []

                            auxData = response.data.map((item) => {
                                lucro = (parseFloat(item.valorcontrato) * parseFloat(item.taxa / 100)).toFixed(2)
                                comissao = (lucro * (7.5 / 100)).toFixed(2)
                                return { ...item, lucro, comissao }
                            })
                            setDatacomissao(auxData)
                        }
                    }

                    setIsloading(false)
                }
                else {
                    window.alert("Data inicial precisa ser menor que data final!")
                }
            }
            else {
                window.alert("É preciso selecionar data inicial e data final!")
            }
        }
    }

    return (
        <Pane border="default"
            display="flex"
            flexDirection="column"
            padding={10}
        >
            <Pane
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                border="default"
                padding={10}
                justifyContent="center"
            >

                <SelectField label="Funcionário"
                    value={responsavel.username}
                    onChange={(e) => handleResponsavel(e)}
                    height={45} width="33%" padding={5}>
                    {
                        responsavelData.map((item) => (
                            <option key={item.id} data-key={item.id}>{item.username}</option>
                        ))
                    }
                </SelectField>

                <SelectField label="Produto"
                    value={produto.descricao}
                    onChange={(e) => handleProduto(e)}
                    height={45} width="33%" padding={5}>
                    {
                        produtoData.map((item) => (
                            <option key={item.id} data-key={item.id}>{item.descricao}</option>
                        ))
                    }
                </SelectField>

                <SelectField label="Banco"
                    value={banco.descricao}
                    onChange={(e) => handleBanco(e)}
                    height={45} width="33%" padding={5}>
                    {
                        bancoData.map((item) => (
                            <option key={item.id} data-key={item.id}>{item.descricao}</option>
                        ))
                    }
                </SelectField>

                <Pane
                    display="flex"
                    flexDirection="column"
                    marginTop={5}
                >
                    <Heading size={400}>Data Inicial</Heading>
                    <DatePicker
                        calendarIcon={null}
                        onChange={onChangeDTini}
                        value={dtinicial}
                    />
                </Pane>
                <Pane
                    display="flex"
                    flexDirection="column"
                    marginLeft={10}
                    marginTop={5}
                >
                    <Heading size={400}>Data Final</Heading>
                    <DatePicker
                        calendarIcon={null}
                        onChange={onChangeDTfinal}
                        value={dtfinal}
                    />
                </Pane>
                <Button onClick={handlePesquisa} iconBefore="search" marginTop={23} marginLeft={10} height={32}>Filtrar</Button>
            </Pane>
            {dataComissao ?
                <Pane marginTop={15}>
                    <ListaComissao isLoading={isLoading} dataComissao={dataComissao} />
                </Pane>
                : ''}
        </Pane>
    )
}

export default Comissao;
