import React, { Component } from 'react';
import { uniqueId } from 'lodash'
import filesize from 'filesize'
import { Container, Content } from './styles'
import Upload from './Upload/index'
import FileList from './FileList/index'
import api from '../../../services/api'
import {
    Pane,
    Spinner
} from "evergreen-ui";


export default class UploadDocument extends Component {
    constructor(props) {
        super(props)
        this.state = {
            uploadedFiles: [],
            isLoading: false
        }
    }

    async componentDidMount() {
        this.setState({ isLoading: true })
        const response = await api.get(`documento-contrato/${this.props.id}`)

        this.setState({
            uploadedFiles: response.data.map(file => ({
                id: file.id,
                name: file.name,
                readableSize: filesize(file.size),
                preview: file.url,
                uploaded: true,
                url: file.url,
            })),
            isLoading: false
        })
    }

    componentWillUnmount() {
        this.state.uploadedFiles.forEach(file => URL.revokeObjectURL(file.preview))
    }

    handleUpload = files => {
        const uploadedFiles = files.map(file => ({
            file,
            id: uniqueId(),
            name: file.name,
            readableSize: filesize(file.size),
            preview: URL.createObjectURL(file),
            progress: 0,
            uploaded: false,
            error: false,
            url: null,
        }))

        this.setState({
            uploadedFiles: this.state.uploadedFiles.concat(uploadedFiles)
        })

        uploadedFiles.forEach(this.processUpload)
    }

    updateFile = (id, data) => {
        this.setState({
            uploadedFiles: this.state.uploadedFiles.map(uploadedFile => {
                return id === uploadedFile.id ?
                    { ...uploadedFile, ...data }
                    : uploadedFile
            })
        })
    }

    processUpload = async (uploadedFile) => {
        const data = new FormData()
        data.append('image', uploadedFile.file, uploadedFile.name, uploadedFile.readableSize)
        await api.post(`documento-contrato/${this.props.id}`, data, {
            onUploadProgress: e => {
                const progress = parseInt(Math.round((e.loaded * 100) / e.total))

                this.updateFile(uploadedFile.id, {
                    progress,
                })
            }
        }).then(response => {
            this.updateFile(uploadedFile.id, {
                uploaded: true,
                id: response.data.id,
                url: response.data.url
            })
        }).catch(() => {
            this.updateFile(uploadedFile.id, {
                error: true
            })
        })
    }

    handleDelete = async id => {
        await api.delete(`documento-contrato/${id}`)

        this.setState({
            uploadedFiles: this.state.uploadedFiles.filter(file => file.id !== id)
        })
    }

    render() {
        const { uploadedFiles } = this.state
        return (
            <Container>
                {
                    this.state.isLoading ?
                        <Pane display="flex" alignItems="center" justifyContent="center">
                            <Spinner />
                        </Pane> :
                        <Content>

                            <Upload onUpload={this.handleUpload} />
                            {!!uploadedFiles.length &&
                                (
                                    <FileList files={uploadedFiles} onDelete={this.handleDelete} />
                                )}
                        </Content>
                }
            </Container >
        )
    }
}
