import React, { Component } from 'react';
import ReactTable from 'react-table'
import { Pane, IconButton, Heading, Paragraph, Tooltip, Icon, Avatar } from 'evergreen-ui'

export default class ListaAgendamento extends Component {
    constructor(props) {
        super(props)
        this.state = {
            columns: [
                {
                    width: 60,
                    filterable: false,
                    Header: () => (
                        <span>
                            <strong>Resp</strong>
                        </span>),
                    accessor: 'responsavel.username',
                    Cell: row => (
                        <Pane display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Avatar name={row.original.responsavel.username} size={30} />
                        </Pane>
                    )
                },
                {
                    width: 150,
                    Header: () => (
                        <span>
                            <strong>DT Agendamento</strong>
                        </span>),
                    accessor: 'dtagendamento',
                    Cell: row => (
                        <Pane display="flex" justifyContent="center">
                            <Heading size={400} color="red">{new Date(row.original.dtagendamento).toLocaleDateString()}</Heading>
                        </Pane>
                    ),
                },
                {
                    width: 130,
                    id: 'id',
                    Header: () => (
                        <span>
                            <strong>CPF</strong>
                        </span>),
                    accessor: 'cpf',
                    Cell: row => (
                        <Pane display="flex" justifyContent="center">
                            <Heading size={400} >{row.original.cpf}</Heading>
                        </Pane>
                    )
                }, {
                    width: 250,
                    Header: () => (
                        <span>
                            <strong>Nome</strong>
                        </span>),
                    accessor: 'nome',
                    Cell: row => (
                        <Pane display="flex" justifyContent="center">
                            <Heading size={400} >{row.original.nome}</Heading>
                        </Pane>
                    )
                },
                {
                    width: 100,
                    Header: () => (
                        <span>
                            <strong>Telefone</strong>
                        </span>),
                    accessor: 'telefone',
                    Cell: row => (
                        <Pane display="flex" justifyContent="center">
                            <Tooltip content={row.original.telefone} >
                                <Heading size={300} >{row.original.telefone}</Heading>
                            </Tooltip>
                        </Pane>
                    )
                },
                {
                    width: 50,
                    Header: () => (
                        <span>
                            <strong>Info</strong>
                        </span>),
                    accessor: 'info',
                    Cell: row => (
                        <Tooltip
                            content={
                                <Paragraph margin={20}>{row.original.info}</Paragraph>
                            }
                            appearance="card"
                        >
                            <Pane display="flex" justifyContent="center">
                                <Icon icon="info-sign" color="info" />
                            </Pane>
                        </Tooltip>
                    )
                },
                {
                    width: 125,
                    Header: () => (
                        <span>
                            <strong>Ações</strong>
                        </span>),
                    id: 'edit/delete',
                    Cell: row => (
                        <Pane display="flex" justify-content="flex-start" >
                            <Tooltip content="Confirmar Agendamento">
                                {row.original.situacao === 1 ?
                                    <IconButton
                                        id="btnConfirm"
                                        onClick={() => this.props.handleConfirm(row.original.id)}
                                        marginRight={5}
                                        height={30}
                                        icon="tick-circle"
                                        intent="success"
                                        disabled />
                                    :
                                    <IconButton
                                        id="btnConfirm"
                                        onClick={() => this.props.handleConfirm(row.original.id)}
                                        marginRight={5}
                                        height={30}
                                        icon="tick-circle"
                                        intent="success"
                                    />
                                }

                            </Tooltip>
                        </Pane>)
                }]
        }
    }
    render() {
        return (
            <Pane padding={5}>
                <ReactTable
                    style={{
                        height: "675px",
                    }}
                    loading={this.props.isLoading}
                    data={this.props.data.data}
                    columns={this.state.columns}
                    className="-striped -highlight"
                    noDataText="Não há agendamentos!"
                    defaultPageSize={20}
                    showPageSizeOptions={false}
                    pages={this.props.data.lastPage || 0}
                    page={this.props.data.page - 1 || 0}
                    pagesize={this.props.data.total || 0}
                    onPageChange={page => this.props.handlePagination(page + 1)}
                    manual
                />
            </Pane>
        )
    }
}