import React, { Component } from "react";
import "./style.css";
import Logo from "../../assets/granacred.png";
import { logout } from "../../services/auth";
import Usuarios from "../Usuarios/index";
import Contrato from "../Contrato/Clientes";
import Clientes from "../Cliente/index";
import Tabelas from "../Tabelas/index";
import Relatorios from "../Relatorios/Geral/index";
import RelatorioFuncionario from "../Relatorios/Funcionario/index";
import RelatorioFaturamento from "../Relatorios/Faturamento/index";
import RelatorioMarketing from "../Relatorios/Marketing/index";
import RelatorioComissao from "../Relatorios/Comissao/index";
import RelatorioFuncionarioIndividual from "../Relatorios/Funcionario-Individual/index";
import Posts from "../Posts/index";
import Produto from "../Produto/index";
import Banco from "../Banco/index";
import Agendamento from "../Agendamento/index";
import { Link, Switch } from "react-router-dom";
import { Container, Content } from "./styles";
import PrivateRoute from "../Privado/PrivateRoute";
import {
  PrivateRouteGenAdm,
  PrivateRouteAdm
} from "../Privado/PrivateRouteMenu";
import EditarUsuario from "../EditarUsuario/index";
import PesquisaContrato from "../PesquisaContrato/index";
import { getToken } from "../../services/auth";
import jwtDecode from "jwt-decode";
import api from "../../services/api";

import {
  Pane,
  IconButton,
  Icon,
  Popover,
  Menu,
  Position,
  Button,
  Heading,
  Avatar,
  Badge
} from "evergreen-ui";

export default class Teste extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comando: "Tela Inicial",
      isToggle: false,
      username: "",
      funcao: "",
      numAlertas: 0
    };
  }

  handlerToggle = e => {
    e.preventDefault();
    this.setState({ isToggle: !this.state.isToggle });
  };

  async componentDidMount() {
    const decodedUser = jwtDecode(getToken()).data.user[0];
    await this.setState({
      username: decodedUser.username,
      funcao: decodedUser.roles.description
    });

    if (
      this.state.funcao === "Funcionario" ||
      this.state.funcao === "Gerente"
    ) {
      let alertas = await api.get(`alerta-agendamento/${decodedUser.id}`);
      if (alertas.data > 0) this.setState({ numAlertas: alertas.data });
    }
  }

  render() {
    let menuClassName = this.state.isToggle ? "toggled" : "";
    const { path } = this.props.match;

    return (
      <div id="wrapper" className={menuClassName}>
        <div id="sidebar-wrapper">
          <ul className="sidebar-nav ">
            <li className="sidebar-brand">
              <Link
                to={`${path}`}
                acao="Tela Inicial"
                onClick={this.handlerClick}
              >
                <Pane display="flex" alignItems="center">
                  <Heading size={600} color="white" marginBottom={10}>
                    Grana <strong className="strong">Cred</strong>
                  </Heading>
                  <Pane marginTop={10} marginLeft={20}>
                    <Avatar src={Logo} size={45} />
                  </Pane>
                </Pane>
              </Link>
            </li>

            {this.state.funcao === "Administrador" && (
              <ul className="ul1">
                <li>
                  <input
                    className="cd-accordion__input"
                    type="checkbox"
                    name="group-1"
                    id="group-1"
                  />
                  <label
                    className="lblcolor cd-accordion__label"
                    htmlFor="group-1"
                  >
                    <Icon icon="label" color="muted" marginLeft={10} />
                    <span>Cadastros</span>
                  </label>

                  <ul className="ul2 cd-accordion__sub cd-accordion__sub--l1 ">
                    <li>
                      <Link to={`${path}/Usuarios`}>
                        <div className="div-nav">
                          <Icon
                            icon="user"
                            color="muted"
                            marginLeft={10}
                            marginRight={10}
                          />
                          Usuarios Sistema
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to={`${path}/Produtos`}>
                        <div className="div-nav">
                          <Icon
                            icon="box"
                            color="muted"
                            marginLeft={10}
                            marginRight={10}
                          />
                          Produtos
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to={`${path}/Bancos`}>
                        <div className="div-nav">
                          <Icon
                            icon="office"
                            color="muted"
                            marginLeft={10}
                            marginRight={10}
                          />
                          Bancos
                        </div>
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            )}

            <li>
              <Link to={`${path}/Clientes`}>
                <div className="div-nav">
                  <Icon
                    icon="people"
                    color="muted"
                    marginLeft={10}
                    marginRight={10}
                  />
                  Clientes
                </div>
              </Link>
            </li>

            <li>
              <Link to={`${path}/Contratos`}>
                <div className="div-nav">
                  <Icon
                    icon="projects"
                    color="muted"
                    marginLeft={10}
                    marginRight={10}
                  />
                  Contratos
                </div>
              </Link>
            </li>
            <li>
              <Link to={`${path}/Pesquisa-Contrato`}>
                <div className="div-nav">
                  <Icon
                    icon="search"
                    color="muted"
                    marginLeft={10}
                    marginRight={10}
                  />
                  Pesquisar Contrato
                </div>
              </Link>
            </li>
            <li>
              <Link to={`${path}/Agendamento`}>
                <div className="div-nav">
                  <Icon
                    icon="calendar"
                    color="muted"
                    marginLeft={10}
                    marginRight={10}
                  />
                  Agendamento
                  {this.state.funcao === "Funcionario" &&
                  this.state.numAlertas > 0 ? (
                    <Badge color="red" isSolid>
                      {this.state.numAlertas}
                    </Badge>
                  ) : this.state.funcao === "Gerente" &&
                    this.state.numAlertas > 0 ? (
                    <Badge color="red" isSolid>
                      {this.state.numAlertas}
                    </Badge>
                  ) : (
                    ""
                  )}
                </div>
              </Link>
            </li>
            {this.state.funcao === "Administrador" ||
            this.state.funcao === "Gerente" ? (
              <li>
                <Link to={`${path}/Tabelas`}>
                  <div className="div-nav">
                    <Icon
                      icon="th"
                      color="muted"
                      marginLeft={10}
                      marginRight={10}
                    />
                    Tabelas / Taxas
                  </div>
                </Link>
              </li>
            ) : (
              ""
            )}
            <li>
              <Link to={`${path}/Posts`}>
                <div className="div-nav">
                  <Icon
                    icon="comment"
                    color="muted"
                    marginLeft={10}
                    marginRight={10}
                  />
                  Posts
                </div>
              </Link>
            </li>

            {this.state.funcao === "Administrador" ? (
              <ul className="ul1">
                <li>
                  <input
                    className="cd-accordion__input"
                    type="checkbox"
                    name="group-2"
                    id="group-2"
                  />
                  <label
                    className="lblcolor cd-accordion__label"
                    htmlFor="group-2"
                  >
                    <Icon icon="chart" color="muted" marginLeft={10} />
                    <span>Relatórios</span>
                  </label>

                  <ul className="ul2 cd-accordion__sub cd-accordion__sub--l1 ">
                    {this.state.funcao === "Administrador" && (
                      <li>
                        <Link to={`${path}/Relatorios-Geral`}>
                          <div className="div-nav">
                            <Icon
                              icon="bank-account"
                              color="muted"
                              marginLeft={10}
                              marginRight={10}
                            />
                            Relatório Geral
                          </div>
                        </Link>
                      </li>
                    )}
                    {this.state.funcao === "Administrador" && (
                      <li>
                        <Link to={`${path}/Relatorios-Funcionario`}>
                          <div className="div-nav">
                            <Icon
                              icon="dollar"
                              color="muted"
                              marginLeft={10}
                              marginRight={10}
                            />
                            Relatório Funcionários
                          </div>
                        </Link>
                      </li>
                    )}

                    {this.state.funcao === "Administrador" && (
                      <li>
                        <Link to={`${path}/Relatorios-Faturamento`}>
                          <div className="div-nav">
                            <Icon
                              icon="timeline-bar-chart"
                              color="muted"
                              marginLeft={10}
                              marginRight={10}
                            />
                            Relatório Faturamento
                          </div>
                        </Link>
                      </li>
                    )}
                    {this.state.funcao === "Administrador" && (
                      <li>
                        <Link to={`${path}/Relatorios-Marketing`}>
                          <div className="div-nav">
                            <Icon
                              icon="pie-chart"
                              color="muted"
                              marginLeft={10}
                              marginRight={10}
                            />
                            Relatório Marketing
                          </div>
                        </Link>
                      </li>
                    )}
                    {this.state.funcao === "Administrador" && (
                      <li>
                        <Link to={`${path}/Relatorios-Comissao`}>
                          <div className="div-nav">
                            <Icon
                              icon="percentage"
                              color="muted"
                              marginLeft={10}
                              marginRight={10}
                            />
                            Relatório Comissão
                          </div>
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              </ul>
            ) : (
              ""
            )}

            <li className="sidebar-logout">
              <Link
                to="/"
                onClick={() => {
                  logout();
                  this.props.history.push("/");
                }}
              >
                <div className="div-nav">
                  <Icon
                    icon="log-out"
                    color="muted"
                    marginLeft={10}
                    marginRight={10}
                  />
                  Logout
                </div>
              </Link>
            </li>
          </ul>
        </div>

        <div id="page-content-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <header className="header">
                  <Pane flex={1} padding={3}>
                    <IconButton
                      icon="menu"
                      intent="none"
                      appearance="minimal"
                      height={60}
                      onClick={this.handlerToggle}
                    />
                  </Pane>
                  <Pane padding={3}>
                    <Popover
                      position={Position.BOTTOM_LEFT}
                      content={
                        <Menu>
                          <Menu.Group>
                            <Link to={`${path}/Editar-Usuario`}>
                              <Menu.Item icon="key">Alterar Senha</Menu.Item>
                            </Link>
                          </Menu.Group>
                          <Menu.Divider />
                          <Menu.Group>
                            <Menu.Item
                              icon="log-out"
                              intent="danger"
                              onSelect={() => {
                                logout();
                                this.props.history.push("/");
                              }}
                            >
                              Logout
                            </Menu.Item>
                          </Menu.Group>
                        </Menu>
                      }
                    >
                      <Button intent="none" appearance="minimal" height={60}>
                        <Heading>
                          Olá <strong>{this.state.username}</strong>
                        </Heading>
                        <Icon icon="arrow-down" color="muted" marginLeft={16} />
                      </Button>
                    </Popover>
                  </Pane>
                </header>
                <Container>
                  <Content>
                    <Switch>
                      {this.state.funcao === "Funcionario" && (
                        <PrivateRoute
                          exact
                          path={`${path}/`}
                          component={RelatorioFuncionarioIndividual}
                        />
                      )}
                      <PrivateRoute
                        path={`${path}/Editar-Usuario`}
                        component={EditarUsuario}
                      />
                      <PrivateRoute
                        path={`${path}/Clientes`}
                        component={Clientes}
                      />
                      <PrivateRoute path={`${path}/Posts`} component={Posts} />
                      <PrivateRoute
                        path={`${path}/Contratos`}
                        component={Contrato}
                      />
                      <PrivateRoute
                        path={`${path}/Pesquisa-Contrato`}
                        component={PesquisaContrato}
                      />
                      <PrivateRoute
                        path={`${path}/Usuarios`}
                        component={Usuarios}
                      />
                      <PrivateRoute
                        path={`${path}/Agendamento`}
                        component={Agendamento}
                      />
                      <PrivateRouteAdm
                        path={`${path}/Relatorios-Geral`}
                        component={Relatorios}
                      />
                      <PrivateRouteAdm
                        path={`${path}/Relatorios-Funcionario`}
                        component={RelatorioFuncionario}
                      />
                      <PrivateRouteAdm
                        path={`${path}/Relatorios-Faturamento`}
                        component={RelatorioFaturamento}
                      />
                      <PrivateRouteAdm
                        path={`${path}/Relatorios-Marketing`}
                        component={RelatorioMarketing}
                      />
                      <PrivateRouteAdm
                        path={`${path}/Relatorios-Comissao`}
                        component={RelatorioComissao}
                      />
                      <PrivateRouteAdm
                        path={`${path}/Produtos`}
                        component={Produto}
                      />
                      <PrivateRouteAdm
                        path={`${path}/Bancos`}
                        component={Banco}
                      />
                      <PrivateRouteGenAdm
                        path={`${path}/Tabelas`}
                        component={Tabelas}
                      />
                    </Switch>
                  </Content>
                </Container>
                <footer className="footer ">
                  <p>© 2019 DevMFZ, All rights reserved.</p>
                </footer>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
