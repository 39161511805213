import React, { useState, useEffect } from 'react';
import AddPosts from './addPosts/index'
import PostList from './PostsList/index'
import {
    Pane,
    Heading,
    toaster,
    Spinner
} from "evergreen-ui";
import api from '../../services/api'
import { getToken } from '../../services/auth'
import jwtDecode from 'jwt-decode'


const Posts = () => {

    const postsData = []
    const [posts, setPosts] = useState(postsData)
    const [isLoading, setLoading] = useState(false)

    const getUserID = () => {
        return jwtDecode(getToken()).data.user[0].id
    }
    const getUsername = () => {
        return jwtDecode(getToken()).data.user[0].username
    }

    async function getPosts() {
        const resultado = await api.get(`posts/`)
        setPosts([...resultado.data.rows, ...resultado.data.posts])
    }

    useEffect(() => {
        setLoading(true)
        getPosts()
        setLoading(false)
    }, []);

    const addPost = async post => {
        setLoading(true)
        const vpost = await api.post(`posts/`, { ...post, user_id: getUserID() })
        if (post.favorito) {
            setPosts([{ ...vpost.data, resp: { username: getUsername() } }, ...posts])
            setLoading(false)
        }
        else {
            setPosts([...posts, { ...vpost.data, resp: { username: getUsername() } }])
            setLoading(false)
        }
    }

    const handleDelete = async (e, id) => {
        e.preventDefault()
        if (window.confirm("Você realmente deseja deletar o post?")) {
            setLoading(true)
            await api.delete(`posts/${id}`)
                .then(response => {
                    toaster.success(
                        'Post deletado com sucesso',
                        {
                            duration: 3
                        }
                    )
                    setPosts(posts.filter(item => item.id !== id))
                    setLoading(false)
                })
                .catch(error => {
                    console.log(error.response)
                })
        }
    }

    return (
        <Pane display="flex" border="default" width="100%" flexDirection="column">
            <Pane display="flex" justifyContent="center" flexDirection="row" marginTop={10} >
                <AddPosts addPost={addPost} />
            </Pane>
            {isLoading ?
                <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
                    <Spinner />
                </Pane>
                :
                <Pane marginTop={20}>
                    <Pane display="flex" alignItems="center" justifyContent="center" >
                        <Heading size={800}>Lista de Posts</Heading>
                    </Pane>
                    <Pane padding={10}>
                        <PostList posts={posts} handleDelete={handleDelete} />
                    </Pane>
                </Pane>}
        </Pane>
    )
}

export default Posts;
