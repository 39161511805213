import axios from 'axios'
import { getToken,logout } from "./auth";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

api.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});


api.interceptors.response.use(
  function(response) {
    // If the request succeeds, we don't have to do anything and just return the response
    return response
  },
  function(error) {
    if (401 === error.response.status) {
      logout()
      this.props.history.push("/")
    }
  }
)


export default api