import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
    width: 100%;
    margin-top: 10px;
`;