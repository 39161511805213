import React, { Component } from 'react';
import { Form, Container } from "./styles";
import Logo from '../../assets/granacred.png'
import { login, isAuthenticated } from "../../services/auth";
import api from '../../services/api'

export default class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      password: "",
      error: ""
    }
  }

  componentDidMount() {
    isAuthenticated() && this.props.history.push("/Menu")
  }

  handleSignIn = async e => {
    e.preventDefault();
    const { email, password } = this.state;
    if (!email || !password) {
      this.setState({ error: "Preencha e-mail e senha para continuar!" });
    } else {
      try {
        const response = await api.post("/authenticate", { email, password });
        login(response.data.token);
        this.props.history.push("/Menu");
      } catch (err) {
        this.setState({
          error:
            ` Houve um problema com o login, verifique suas credenciais.`
        })
      }
    }
  };

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    })
  }

  render() {
    return (
      <Container>
        <Form onSubmit={this.handleSignIn}>
          <img src={Logo} alt="GranaCred" />
          {this.state.error && <p>{this.state.error}</p>}
          <input
            type="email"
            placeholder="Endereço de e-mail"
            onChange={e => this.setState({ email: e.target.value })}
          />
          <input
            type="password"
            placeholder="Senha"
            onChange={e => this.setState({ password: e.target.value })}
          />
          <button type="submit">Entrar</button>
          <hr />
        </Form>
      </Container>
    )
  }
}
