var indicacao = [
    { 'ID': 'NENHUMA' },
    { 'ID': 'CLIENTE LOJA' },
    { 'ID': 'BANNER LOJA' },
    { 'ID': 'TELEMARKETING' },
    { 'ID': 'PANFLETO' },
    { 'ID': 'RÁDIO' },
    { 'ID': 'BLOG' },
    { 'ID': 'SOM MOTO' },
]
export default indicacao 
