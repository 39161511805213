import React from 'react';
import {
    Pane,
    Icon,
    Heading
} from "evergreen-ui";

const Card = ({ periodo, valor, situacao }) => (
    <Pane
        background="#ff8000"
        height={125}
        width={250}
        border="default"
        marginLeft={5}
    >
        {situacao === 'Aprovado' ?
            <Pane>
                <Pane display="flex"
                    flexDirection="column"
                    alignItems="center"
                    marginTop={5}>
                    <Heading size={300} color="white" >Periodo: {periodo}</Heading>
                    <Heading size={500} color="white" >{situacao}</Heading>
                </Pane>
                <Pane
                    display="flex"
                    justifyContent="space-around"
                    alignItems="center">

                    <Heading size={700} color="green" >{'R$ ' + valor.toLocaleString('pt-BR')}</Heading>
                    <Icon color="green" icon="dollar" size={60} />
                </Pane>
            </Pane>
            : situacao === 'Análise' ?
                <Pane>
                    <Pane display="flex"
                        flexDirection="column"
                        alignItems="center"
                        marginTop={5}>
                        <Heading size={300} color="white" >Periodo: {periodo}</Heading>
                        <Heading size={500} color="white" >{situacao}</Heading>
                    </Pane>
                    <Pane
                        display="flex"
                        justifyContent="space-around"
                        alignItems="center">

                        <Heading size={700} color="white" >{'R$ ' + valor.toLocaleString('pt-BR')}</Heading>
                        <Icon color="white" icon="dollar" size={60} />
                    </Pane>
                </Pane>
                : situacao === 'Pendente' ?
                    <Pane>
                        <Pane display="flex"
                            flexDirection="column"
                            alignItems="center"
                            marginTop={5}>
                            <Heading size={300} color="white" >Periodo: {periodo}</Heading>
                            <Heading size={500} color="white" >{situacao}</Heading>
                        </Pane>
                        <Pane
                            display="flex"
                            justifyContent="space-around"
                            alignItems="center">

                            <Heading size={700} color="yellow" >{'R$ ' + valor.toLocaleString('pt-BR')}</Heading>
                            <Icon color="yellow" icon="dollar" size={60} />
                        </Pane>
                    </Pane>
                    :
                    <Pane>
                        <Pane display="flex"
                            flexDirection="column"
                            alignItems="center"
                            marginTop={5}>
                            <Heading size={300} color="white" >Periodo: {periodo}</Heading>
                            <Heading size={500} color="white" >{situacao}</Heading>
                        </Pane>
                        <Pane
                            display="flex"
                            justifyContent="space-around"
                            alignItems="center">

                            <Heading size={700} color="red" >{'R$ ' + valor.toLocaleString('pt-BR')}</Heading>
                            <Icon color="red" icon="dollar" size={60} />
                        </Pane>
                    </Pane>
        }
    </Pane>

)
export default Card;
