import React from 'react';
import { CardContainer, Header } from './styles';
import { Icon, Pane, Heading } from 'evergreen-ui';

const CardContrato = (contrato) => (
    <Pane display="flex" flexDirection="column">
        <CardContainer pri>
            <Header pri> <Icon icon="person" marginRight={5} />Cliente</Header>
            <Pane
                display="flex"
                flexDirection="row"
            >
                <Pane
                    elevation={1}
                    flex={2}
                    background="yellowTint"
                    padding={10}
                >
                    <Pane
                        display="flex"
                        alignItems="center"
                        flexDirection="row">
                        <Heading size={400}>CPF:</Heading>
                        <Heading size={200} marginLeft={10}> {contrato.sendData[0].cpf_id} </Heading>
                    </Pane>
                    <Pane
                        display="flex"
                        alignItems="center"
                        flexDirection="row">
                        <Heading size={400}>Nome:</Heading>
                        <Heading size={200} marginLeft={10}> {contrato.sendData[0].cli.nome} </Heading>
                    </Pane>
                    <Pane
                        display="flex"
                        alignItems="center"
                        flexDirection="row">
                        <Heading size={400}>Nascimento:</Heading>
                        <Heading size={200} marginLeft={10}> {new Date(contrato.sendData[0].cli.nascimento).toLocaleDateString()} </Heading>
                    </Pane>
                </Pane>
                <Pane
                    elevation={1}
                    flex={1}
                    background="yellowTint"
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                >
                    <Heading size={500} > <Icon marginRight={5} icon="phone" /> Telefones</Heading>
                    {contrato.sendTel.map(item => (
                        <Heading size={300} key={item.id}>{item.numero}</Heading>
                    ))
                    }
                </Pane>
            </Pane>
        </CardContainer>

        <CardContainer sec>
            <Header sec> <Icon icon="home" marginRight={5} />Endereço</Header>
            <Pane
                elevation={1}
                flex={2}
                background="yellowTint"
                padding={10}
            >
                <Pane
                    display="flex"
                    alignItems="center"
                    flexDirection="row">
                    <Heading size={400}>Endereço:</Heading>
                    <Heading size={200} marginLeft={10}> {contrato.sendData[0].cli.endereco} - Nº{contrato.sendData[0].cli.numero} </Heading>
                </Pane>
                <Pane
                    display="flex"
                    alignItems="center"
                    flexDirection="row">
                    <Heading size={400}>Cidade:</Heading>
                    <Heading size={200} marginLeft={10}>{contrato.sendData[0].cli.cidade} - {contrato.sendData[0].cli.estado} </Heading>
                </Pane>
                <Pane
                    display="flex"
                    alignItems="center"
                    flexDirection="row">
                    <Heading size={400}>Bairro:</Heading>
                    <Heading size={200} marginLeft={10}>{contrato.sendData[0].cli.bairro}</Heading>
                </Pane>
                <Pane
                    display="flex"
                    alignItems="center"
                    flexDirection="row">
                    <Heading size={400}>CEP:</Heading>
                    <Heading size={200} marginLeft={10}>{contrato.sendData[0].cli.cep}</Heading>
                </Pane>
            </Pane>
        </CardContainer>

        <CardContainer ter>
            <Header ter> <Icon icon="folder-close" marginRight={5} />Contrato</Header>
            <Pane
                elevation={1}
                flex={2}
                background="greenTint"
                padding={10}
            >
                <Pane
                    display="flex"
                    alignItems="center"
                    flexDirection="row">
                    <Heading size={400}>ADE:</Heading>
                    <Heading size={200} marginLeft={10}> {contrato.sendData[0].ade} </Heading>
                </Pane>
                <Pane
                    display="flex"
                    alignItems="center"
                    flexDirection="row">
                    <Heading size={400}>Produto:</Heading>
                    <Heading size={200} marginLeft={10}>{contrato.sendData[0].pro.descricao} </Heading>
                </Pane>
                <Pane
                    display="flex"
                    alignItems="center"
                    flexDirection="row">
                    <Heading size={400}>Banco:</Heading>
                    <Heading size={200} marginLeft={10}>{contrato.sendData[0].ban.descricao}</Heading>
                </Pane>
                <Pane
                    display="flex"
                    alignItems="center"
                    flexDirection="row">
                    <Heading size={400}>Tabela:</Heading>
                    <Heading size={200} marginLeft={10}>{contrato.sendData[0].tabela}</Heading>
                </Pane>
                <Pane
                    display="flex"
                    alignItems="center"
                    flexDirection="row">
                    <Heading size={400}>Taxa:</Heading>
                    <Heading size={200} marginLeft={10}>{contrato.sendData[0].taxa} %</Heading>
                </Pane>
                <Pane
                    display="flex"
                    alignItems="center"
                    flexDirection="row">
                    <Heading size={400}>Parcela:</Heading>
                    <Heading size={200} marginLeft={10}>R$ {contrato.sendData[0].parcela.toLocaleString('pt-BR')}</Heading>
                </Pane>
                <Pane
                    display="flex"
                    alignItems="center"
                    flexDirection="row">
                    <Heading size={400}>Qntd Parcelas:</Heading>
                    <Heading size={200} marginLeft={10}>{contrato.sendData[0].qntdparcela}</Heading>
                </Pane>
                <Pane
                    display="flex"
                    alignItems="center"
                    flexDirection="row">
                    <Heading size={400}>Valor:</Heading>
                    <Heading size={200} marginLeft={10}> R$ {contrato.sendData[0].valorcontrato.toLocaleString('pt-BR')}</Heading>
                </Pane>
                <Pane
                    display="flex"
                    alignItems="center"
                    flexDirection="row">
                    <Heading size={400}>Data Digitação:</Heading>
                    <Heading size={200} marginLeft={10}>{new Date(contrato.sendData[0].created_at).toLocaleDateString()}</Heading>
                </Pane>
                <Pane
                    display="flex"
                    alignItems="center"
                    flexDirection="row">
                    <Heading size={400}>Indicação:</Heading>
                    <Heading size={200} marginLeft={10}>{contrato.sendData[0].indicacao}</Heading>
                </Pane>
            </Pane>
        </CardContainer>
    </Pane >
)
export default CardContrato
