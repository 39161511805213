import React, { useState } from 'react';
import {
    Pane,
    toaster,
} from "evergreen-ui"
import api from '../../services/api'
import EditUser from './editUser/index'
import { getToken } from '../../services/auth'
import jwtDecode from 'jwt-decode'


const EditUsuario = () => {
    const [isLoading, setLoading] = useState(false)

    const getUserID = () => {
        return jwtDecode(getToken()).data.user[0].id
    }



    const editUser = async (user) => {
        setLoading(true)
        await api.put(`user/${getUserID()}`, user)
        setLoading(false)
        toaster.success(
            'Senha alterada com sucesso',
            {
                duration: 3
            }
        )
    }

    return (
        <Pane display="flex" alignItems="center" justifyContent="center" flexDirection="row" marginTop={10} border="default">
            <EditUser editUser={editUser} isLoading={isLoading} />
        </Pane>
    )
}

export default EditUsuario