var exports = module.exports = {}

function validacpf(cpf) {
	var i, add, rev
	cpf = cpf.replace(/[^\d]+/g, '');
	if (cpf === '') return false;
	// Elimina CPFs invalidos conhecidos	
	if (cpf.length !== 11 ||
		cpf === "00000000000" ||
		cpf === "11111111111" ||
		cpf === "22222222222" ||
		cpf === "33333333333" ||
		cpf === "44444444444" ||
		cpf === "55555555555" ||
		cpf === "66666666666" ||
		cpf === "77777777777" ||
		cpf === "88888888888" ||
		cpf === "99999999999")
		return false;
	// Valida 1o digito	
	add = 0;
	for (i = 0; i < 9; i++)
		add += parseInt(cpf.charAt(i)) * (10 - i);
	rev = 11 - (add % 11);
	if (rev === 10 || rev === 11)
		rev = 0;
	if (rev !== parseInt(cpf.charAt(9)))
		return false;
	// Valida 2o digito	
	add = 0;
	for (i = 0; i < 10; i++)
		add += parseInt(cpf.charAt(i)) * (11 - i);
	rev = 11 - (add % 11);
	if (rev === 10 || rev === 11)
		rev = 0;
	if (rev !== parseInt(cpf.charAt(10)))
		return false;
	return true;
}

exports.ValidateCPF = (cpf) => {
	var i, add, rev
	cpf = cpf.replace(/[^\d]+/g, '');
	if (cpf === '') return false;
	// Elimina CPFs invalidos conhecidos	
	if (cpf.length !== 11 ||
		cpf === "00000000000" ||
		cpf === "11111111111" ||
		cpf === "22222222222" ||
		cpf === "33333333333" ||
		cpf === "44444444444" ||
		cpf === "55555555555" ||
		cpf === "66666666666" ||
		cpf === "77777777777" ||
		cpf === "88888888888" ||
		cpf === "99999999999")
		return false;
	// Valida 1o digito	
	add = 0;
	for (i = 0; i < 9; i++)
		add += parseInt(cpf.charAt(i)) * (10 - i);
	rev = 11 - (add % 11);
	if (rev === 10 || rev === 11)
		rev = 0;
	if (rev !== parseInt(cpf.charAt(9)))
		return false;
	// Valida 2o digito	
	add = 0;
	for (i = 0; i < 10; i++)
		add += parseInt(cpf.charAt(i)) * (11 - i);
	rev = 11 - (add % 11);
	if (rev === 10 || rev === 11)
		rev = 0;
	if (rev !== parseInt(cpf.charAt(10)))
		return false;
	return true;
}

exports.validaCliente = (props) => {

	// Nascimento não precisa validar
	//Validar ESTADO depois

	props.errNome = null
	props.errNomeb = false

	props.errCpf = null
	props.errCpfb = false

	props.errNascimento = null
	props.errNascimentob = false

	// props.errEndereco = null
	// props.errEnderecob = false

	// props.errNumero = null
	// props.errNumerob = false

	// props.errBairro = null
	// props.errBairrob = false

	// props.errCidade = null
	// props.errCidadeb = false

	// props.errCep = null
	// props.errCepb = false

	props.errTelefone = null
	props.errTelefoneb = false


	if (props.cpf === '') {
		props.errCpf = 'Digite Cpf válido'
		props.errCpfb = true
	} else if (!validacpf(props.cpf)) {
		props.errCpf = 'Cpf inválido'
		props.errCpfb = true
	}

	if (props.nome === '') {
		props.errNome = 'Nome não pode em branco'
		props.errNomeb = true
	} else if (props.nome.length <= 3) {
		props.errNome = 'Nome muito curto'
		props.errNomeb = true
	}

	if (props.nascimento === '') {
		props.errNascimento = 'Digite o nascimento'
		props.errNascimentob = true
	} else {
		let dateString = props.nascimento.replace(/\D/g, '-')
		var parts = dateString.split('-');
		if (parts[0] <= 0 || parts[0] > 31) {
			props.errNascimento = 'Dia inválido'
			props.errNascimentob = true
		}
		else {
			if (parts[1] <= 0 || parts[1] > 12) {
				props.errNascimento = 'Mês inválido'
				props.errNascimentob = true
			}
			else {
				let ano = new Date().getFullYear()
				if (parts[2] <= 1900 || parts[2] > ano) {
					props.errNascimento = 'Ano inválido'
					props.errNascimentob = true
				}
			}
		}
	}

	// if (props.endereco === '') {
	// 	props.errEndereco = 'Endereço inválido'
	// 	props.errEnderecob = true
	// } else if (props.endereco.length <= 3) {
	// 	props.errEndereco = 'Endereço muito curto'
	// 	props.errEnderecob = true
	// }

	// if (props.numero === '') {
	// 	props.errNumero = 'Numero inválido'
	// 	props.errNumerob = true
	// } else if (!/^[0-9]+$/.test(props.numero)) {
	// 	props.errNumero = 'Apenas numeros'
	// 	props.errNumerob = true
	// }

	// if (props.bairro === '') {
	// 	props.errBairro = 'Bairro inválido'
	// 	props.errBairrob = true
	// }

	// if (props.cidade === '') {
	// 	props.errCidade = 'Cidade inválida'
	// 	props.errCidadeb = true
	// }

	// let vcep = props.cep.replace(/\D/g, '')
	// if (vcep === '') {
	// 	props.errCep = 'Cep inválido'
	// 	props.errCepb = true
	// } else if (!/^[0-9]{8}$/.test(vcep)) {
	// 	props.errCep = 'Cep inválido'
	// 	props.errCepb = true
	// }

	if (props.telefones.length === 0) {
		props.errTelefone = 'Insira um telefone.'
		props.errTelefoneb = true
	}

	return props
}


exports.validaContrato = (props) => {

	//Validar TABELA, TAXA E SITUAÇÃO
	props.errAde = null
	props.errAdeb = false

	props.errTaxa = null
	props.errTaxab = false

	props.errTabela = null
	props.errTabelab = false

	props.errValor = null
	props.errValob = false

	props.errParcela = null
	props.errParcelab = false

	props.errQtdParcela = null
	props.errQtdParcelab = false

	props.errProduto = false
	props.errBanco = false

	if (props.ade === '') {
		props.errAde = 'Ade inválida.'
		props.errAdeb = true
	}

	if (props.banco === '')
		props.errBanco = true

	if (props.produto === '')
		props.errProduto = true

	if (props.taxa === '') {
		props.errTaxa = 'Selecione uma tabela.'
		props.errTaxab = true
	}

	if (props.tabela === '') {
		props.errTabela = 'Selecione uma tabela.'
		props.errTabelab = true
	}

	let vvalor = props.valor.replace(/[R$]/g, '')
	if (props.valor === '') {
		props.errValor = 'Valor inválido'
		props.errValob = true
	} else if (parseFloat(vvalor) <= 0) {
		props.errValor = 'Valor precisa ser positivo.'
		props.errValob = true
	}
	let vparcela = props.parcela.replace(/[R$]/g, '')
	if (props.parcela === '') {
		props.errParcela = 'Parcela inválida.'
		props.errParcelab = true
	} else if (parseFloat(vparcela) <= 0) {
		props.errParcela = 'Parcela precisa ser positiva.'
		props.errParcelab = true
	}

	if (props.qtdParcela === '') {
		props.errQtdParcela = 'Quantidade parcelas inválida.'
		props.errQtdParcelab = true
	} else if (parseInt(props.qtdParcela) < 1 || parseInt(props.qtdParcela) > 150) {
		props.errQtdParcela = 'Quantidade de parcelas precisa ser maior que 0 e menor que 150'
		props.errQtdParcelab = true
	}

	return props
}