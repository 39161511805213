import React, { Component } from 'react';
import ReactTable from 'react-table'
import { Avatar, Badge, Pane, Heading, Tooltip } from 'evergreen-ui'

export default class ListaContratos extends Component {

  render() {
    return (
      <ReactTable
        loading={this.props.isLoading}
        data={this.props.dataComissao}
        className="-striped -highlight"
        defaultPageSize={10}
        style={{
          height: "500px" // This will force the table body to overflow and scroll, since there is not enough room
        }}
        noDataText="Não há Contratos!"
        columns={
          [
            {
              width: 50,
              Header: () => (
                <span>
                  <strong>ID</strong>
                </span>),
              accessor: 'id',
              show: false
            }, {
              width: 60,
              Header: () => (
                <span>
                  <strong>Resp</strong>
                </span>),
              accessor: 'responsavel.username',
              Cell: row => (
                <Pane display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Avatar name={row.original.responsavel.username} size={30} />
                </Pane>
              )
            },
            {
              Header: "Cliente",
              columns: [
                {
                  width: 120,
                  id: 'cpf',
                  Header: () => (
                    <span>
                      <strong>CPF</strong>
                    </span>),
                  accessor: 'cpf_id'
                }, {
                  width: 225,
                  Header: () => (
                    <span>
                      <strong>Nome</strong>
                    </span>),
                  accessor: 'cli.nome',
                }]
            },

            {
              Header: "Contrato",
              columns: [
                {
                  width: 100,
                  Header: () => (
                    <span>
                      <strong>ADE</strong>
                    </span>),
                  accessor: 'ade'
                }, {
                  width: 120,
                  Header: () => (
                    <span>
                      <strong>Produto</strong>
                    </span>),
                  accessor: 'pro.descricao',
                  Cell: row => (
                    <Pane display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Tooltip content={row.original.pro.descricao}>
                        <Badge color={"blue"}>{row.original.pro.descricao}</Badge>
                      </Tooltip>
                    </Pane>
                  )
                },
                {
                  width: 120,
                  Header: () => (
                    <span>
                      <strong>Banco</strong>
                    </span>),
                  accessor: 'ban.descricao',
                  Cell: row => (
                    <Pane display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Badge color={"orange"}>{row.original.ban.descricao}</Badge>
                    </Pane>
                  )
                },
                {
                  width: 100,
                  show: true,
                  Header: () => (
                    <span>
                      <strong>Tabela</strong>
                    </span>),
                  accessor: 'tabela',
                  Cell: row => (
                    <Tooltip content={row.original.tabela}>
                      <Heading size={200} >{row.original.tabela}</Heading>
                    </Tooltip>
                  )
                },
                {
                  width: 50,
                  show: true,
                  Header: () => (
                    <span>
                      <strong>Taxa</strong>
                    </span>),
                  accessor: 'taxa',
                  Cell: row => (
                    <Heading size={500} >{row.original.taxa + "%"}</Heading>
                  )
                },
                {
                  width: 100,
                  Header: () => (
                    <span>
                      <strong>Parcela</strong>
                    </span>),
                  accessor: 'parcela',
                  Cell: row => (
                    <h6>{'R$ ' + row.original.parcela.toLocaleString('pt-BR')}</h6>
                  )
                },
                {
                  width: 50,
                  Header: () => (
                    <span>
                      <strong>X</strong>
                    </span>),
                  accessor: 'qntdparcela'
                }, {
                  width: 120,
                  Header: () => (
                    <span>
                      <strong>Valor</strong>
                    </span>),
                  accessor: 'valorcontrato',
                  Cell: row => (
                    <h6>{'R$ ' + row.original.valorcontrato.toLocaleString('pt-BR')}</h6>
                  ),
                  Footer: (
                    <span>
                      <strong>Total Valor: </strong>
                      {
                        this.props.dataComissao.length > 0 ?
                          <h6>{'R$ ' + this.props.dataComissao.reduce((total, { valorcontrato }) => total += parseFloat(valorcontrato), 0).toFixed(2).toLocaleString('pt-BR')}</h6>
                          : 0
                      }
                    </span>
                  )
                }, {
                  width: 115,
                  show: false,
                  Header: () => (
                    <span>
                      <strong>DT Contrato</strong>
                    </span>),
                  accessor: 'created_at',
                  Cell: row => (
                    <p>{new Date(row.original.created_at).toLocaleDateString()}</p>
                  ),
                }]
            },
            {
              width: 120,
              accessor: 'lucro',
              id: 'lucro',
              Header: () => (
                <span>
                  <strong>Lucro</strong>
                </span>),
              Cell: row => (
                <h6>{'R$ ' + row.original.lucro.toLocaleString('pt-BR')}</h6>
              ),
              Footer: (
                <span>
                  <strong>Total Lucro: </strong>
                  {
                    this.props.dataComissao.length > 0 ?
                      <h6>{'R$ ' + this.props.dataComissao.reduce((total, { lucro }) => total += parseFloat(lucro), 0).toFixed(2).toLocaleString('pt-BR')}</h6>
                      : 0
                  }
                </span>
              )
            },
            {
              width: 130,
              accessor: 'comissao',
              id: 'comissao',
              Header: () => (
                <span>
                  <strong>Comissão</strong>
                </span>),
              Cell: row => (
                <h6>{'R$ ' + row.original.comissao.toLocaleString('pt-BR')}</h6>
              ),
              Footer: (
                <span>
                  <strong>Total Comissão: </strong>
                  {
                    this.props.dataComissao.length > 0 ?
                      <h6>{'R$ ' + this.props.dataComissao.reduce((total, { comissao }) => total += parseFloat(comissao), 0).toFixed(2).toLocaleString('pt-BR')}</h6>
                      : 0
                  }
                </span>
              )
            }]
        }
      />
    )
  }
}
