import React, { useState } from 'react';
import {
    Pane,
    Heading,
    Button,
    TextInputField
} from 'evergreen-ui'

const EditUser = props => {
    const initialFormState = { password: "", copypassword: "" }
    const initialErrorState = { errPasswordb: false, errPassword: null }
    const [user, setUser] = useState(initialFormState)
    const [error, setError] = useState(initialErrorState)

    const handleInputChange = e => {
        const { name, value } = e.target
        setUser({ ...user, [name]: value })
    }

    const handleClearForm = (e) => {
        e.preventDefault()
        setUser(initialFormState)
        setError(initialErrorState)
    }

    return (
        <form onSubmit={e => {
            e.preventDefault()
            if (!user.password || !user.copypassword) {
                setError({ errPasswordb: true, errPassword: "Senhas não podem ser vazio!" })
                return
            }
            else
                if (user.password !== user.copypassword) {
                    setError({ errPasswordb: true, errPassword: "Senhas precisam ser iguais." })
                    return
                }
            props.editUser(user)
            setUser(initialFormState)
            setError(initialErrorState)
        }}>
            <Pane display="flex" flexDirection="column">
                <Pane display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginTop={10}
                >
                    <Heading size={600}>Editar Senha Usuário</Heading>
                </Pane>

                <Pane display="flex" flexDirection="column" marginTop={10}>

                    <TextInputField
                        name="password"
                        isInvalid={error.errPasswordb}
                        validationMessage={error.errPassword}
                        value={user.password}
                        onChange={handleInputChange}
                        placeholder="Digite a senha"
                        type="password"
                        label="Senha" />

                    <TextInputField
                        name="copypassword"
                        isInvalid={error.errPasswordb}
                        validationMessage={error.errPassword}
                        value={user.copypassword}
                        onChange={handleInputChange}
                        placeholder="Repetir senha"
                        type="password"
                        label="Repetir senha" />
                </Pane>

                <Pane marginTop={5}>
                    <Heading color="red" size={300}>{error.errMessage}</Heading>
                </Pane>
                <Pane display="flex" flexDirection="row" marginBottom={20}>
                    <Button
                        disabled={props.isLoading}
                        flex={1}
                        iconBefore="add"
                        height={38}
                        appearance="primary" intent="success">
                        Salvar
                </Button>

                    <Button
                        disabled={props.isLoading}
                        onClick={handleClearForm}
                        flex={1}
                        marginLeft={10}
                        iconBefore="eraser"
                        height={38}
                        appearance="primary" intent="none">
                        Limpar
                </Button>
                </Pane>
            </Pane>
        </form>
    )
}
export default EditUser;