import React, { Component } from 'react';
import ReactTable from 'react-table'
import { Avatar, Badge, Pane, IconButton, Heading, Popover, Table, Tooltip, Small } from 'evergreen-ui'
import { getToken } from '../../services/auth'
import jwtDecode from 'jwt-decode'

export default class ListaContratos extends Component {

  constructor(props) {
    super(props)
    this.state = {
      funcao: '',
      columns: [
        {
          width: 50,
          Header: () => (
            <span>
              <strong>ID</strong>
            </span>),
          accessor: 'id',
          show: false
        }, {
          width: 60,
          Header: () => (
            <span>
              <strong>Resp</strong>
            </span>),
          accessor: 'responsavel.username',
          Cell: row => (
            <Pane display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Avatar name={row.original.responsavel.username} size={30} />
            </Pane>
          )
        },
        {
          Header: "Cliente",
          columns: [
            {
              width: 90,
              id: 'cpf',
              Header: () => (
                <span>
                  <strong>CPF</strong>
                </span>),
              accessor: 'cpf_id',
              Cell: row => (
                <Heading size={200} >{row.original.cpf_id}</Heading>
              )
            }, {
              width: 150,
              Header: () => (
                <strong>Nome</strong>
              ),
              accessor: 'cli.nome',
              Cell: row => (
                <Tooltip content={row.original.cli.nome}>
                  <Heading size={200} >{row.original.cli.nome}</Heading>
                </Tooltip>
              )
            }]
        },

        {
          Header: "Contrato",
          columns: [
            {
              width: 100,
              Header: () => (
                <span>
                  <strong>ADE</strong>
                </span>),
              accessor: 'ade',
              Cell: row => (
                <Tooltip content={row.original.ade}>
                  <Heading size={200} >{row.original.ade}</Heading>
                </Tooltip>
              )
            }, {
              width: 120,
              Header: () => (
                <span>
                  <strong>Produto</strong>
                </span>),
              accessor: 'pro.descricao',
              Cell: row => (
                <Pane display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Tooltip content={row.original.pro.descricao}>
                    <Badge color={"blue"}>{row.original.pro.descricao}</Badge>
                  </Tooltip>
                </Pane>
              )
            },
            {
              width: 100,
              Header: () => (
                <span>
                  <strong>Banco</strong>
                </span>),
              accessor: 'ban.descricao',
              Cell: row => (
                <Pane display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Badge color={"orange"}>{row.original.ban.descricao}</Badge>
                </Pane>
              )
            },
            {
              width: 100,
              show: false,
              Header: () => (
                <span>
                  <strong>Tabela</strong>
                </span>),
              accessor: 'tabela',
              Cell: row => (
                <Tooltip content={row.original.tabela}>
                  <Heading size={200} >{row.original.tabela}</Heading>
                </Tooltip>
              )
            }
            ,
            {
              width: 50,
              show: false,
              Header: () => (
                <span>
                  <strong>Taxa</strong>
                </span>),
              accessor: 'taxa',
              Cell: row => (
                <Heading size={500} >{row.original.taxa + "%"}</Heading>
              )
            }
            ,
            {
              width: 90,
              Header: () => (
                <span>
                  <strong>Parcela</strong>
                </span>),
              accessor: 'parcela',
              Cell: row => (
                <Heading size={400} >{'R$ ' + row.original.parcela.toLocaleString('pt-BR')}</Heading>
              )
            },
            {
              width: 40,
              Header: () => (
                <span>
                  <strong>X</strong>
                </span>),
              accessor: 'qntdparcela',
              Cell: row => (
                <Heading size={200} >{row.original.qntdparcela}</Heading>
              )
            }, {
              width: 90,
              Header: () => (
                <span>
                  <strong>Valor</strong>
                </span>),
              accessor: 'valorcontrato',
              Cell: row => (
                <Heading size={400} >{'R$ ' + row.original.valorcontrato.toLocaleString('pt-BR')}</Heading>
              )
            }, {
              width: 80,
              Header: () => (
                <span>
                  <strong>Digitado</strong>
                </span>),
              accessor: 'created_at',
              Cell: row => (
                <Heading size={200} >{new Date(row.original.created_at).toLocaleDateString()}</Heading>
              ),
            }, {
              width: 120,
              Header: () => (
                <span>
                  <strong>Situação</strong>
                </span>),
              accessor: 'histcont',
              Cell: row => (
                <Pane display="flex"
                  alignItems="center"
                >
                  <Badge
                    color={row.original.situacao === 'APROVADO' ? "green"
                      : row.original.situacao === 'ANALISE' ? "orange"
                        : row.original.situacao === 'PENDENTE' ? "yellow"
                          : row.original.situacao === 'DIGITACAO' ? "teal"
                            : row.original.situacao === 'PENDENTE_RE' ? "purple"
                              : "red"}
                    isSolid marginRight={8}>{row.original.situacao}</Badge>
                  {row.original.histcont.length > 1 ?
                    <Popover
                      content={
                        <Pane
                          width={270}
                          height={200}
                          display="flex"
                          flexDirection="column"
                          background="yellowTint"
                        >
                          <Pane
                            padding={5}
                            flexWrap="wrap"
                          >
                            <Table>
                              <Table.Body height={180}>
                                {
                                  row.original.histcont.map((item) => (
                                    item.historico !== null ?
                                      <Table.Row key={item.id} height="auto" >
                                        <Pane display="flex" flexDirection="column">
                                          <Heading size={300} >
                                            <Small>{new Date(item.created_at).toLocaleString()} </Small> | {item.historico}
                                          </Heading>
                                          {item.username ?
                                            <Heading size={200} >
                                              <Small>Modificado por : </Small> {item.username}
                                            </Heading> : ''}
                                          <Heading color="red" size={200}>
                                            {item.descricao}
                                          </Heading>
                                        </Pane>
                                      </Table.Row>
                                      : ''
                                  )).reverse()
                                }
                              </Table.Body>
                            </Table>
                          </Pane>
                        </Pane>
                      }
                    >
                      <IconButton intent={row.original.histcont[row.original.histcont.length - 1].descricao !== null ? "danger" : "none"} icon="info-sign" appearance="minimal" />
                    </Popover>
                    : ''}
                </Pane>
              )
            }]
        },
        {
          width: 200,
          Header: () => (
            <span>
              <strong>Ações</strong>
            </span>),
          id: 'edit/delete',
          Cell: row => (
            <Pane display="flex" justify-content="flex-start" >
              <Tooltip content="Enviar Documento">
                <IconButton onClick={(e) => this.props.handleDocument(e, row.original.id)} id="btnView" marginRight={5} height={26} icon="document" intent="none" />
              </Tooltip>
              <Tooltip content="Visualizar">
                <IconButton onClick={(e) => this.props.handleViewContrato(e, row.original.id)} id="btnView" marginRight={5} height={26} icon="eye-open" intent="none" />
              </Tooltip>
              {this.state.funcao === 'Administrador' || this.state.funcao === 'Gerente' ?
                <Tooltip content="Editar">
                  <IconButton onClick={(e) => this.props.handleEditContrato(e, row.original.id)} id="btnEdit" marginRight={5} height={26} icon="edit" intent="warning" />
                </Tooltip>
                : ''}
            </Pane>)
        }]

    }
  }

  componentDidMount() {
    let decodedUser = jwtDecode(getToken()).data.user[0]
    this.setState({
      funcao: decodedUser.roles.description
    })
  }

  render() {
    return (
      <div>
        <ReactTable
          style={{
            height: "675px",
          }}
          loading={this.props.isLoading}
          data={this.props.loadingData}
          columns={this.state.columns}
          className="-striped -highlight"
          defaultPageSize={20}
          noDataText="Não há Contratos!"
        />
      </div>
    )
  }
}
