import React, { useState, useEffect } from 'react';
import AddBanco from './forms/AddBanco'
import EditBanco from './forms/EditBanco'
import ListaBancos from './ListaBancos/index'
import {
    Pane,
    Heading,
    Spinner,
    toaster
} from "evergreen-ui";
import api from '../../services/api'

const Banco = () => {
    const bancosData = []
    const [bancos, setBancos] = useState(bancosData)

    const initialFormState = { id: null, descricao: '' }
    const [currentBanco, setCurrentBanco] = useState(initialFormState)

    const [isLoading, setLoading] = useState(false)
    const [editing, setEditing] = useState(false)

    async function getBancos() {
        const response = await api.get(`banco/`)
        setBancos([...response.data])
    }

    useEffect(() => {
        setLoading(true)
        getBancos()
        setLoading(false)
    }, []);

    const addBanco = async banco => {
        setLoading(true)
        const vpost = await api.post(`banco/`, { ...banco })
        setBancos([...bancos, { ...vpost.data }])
        toaster.success(
            'Banco adicionado com sucesso',
            {
                duration: 3
            }
        )
        setLoading(false)
    }


    const handleDelete = async (e, id) => {
        e.preventDefault()
        if (window.confirm("Você realmente deseja deletar o banco?")) {
            setLoading(true)
            await api.delete(`banco/${id}`)
            toaster.success(
                'Banco deletado com sucesso',
                {
                    duration: 3
                }
            )
            setBancos(bancos.filter(item => item.id !== id))
            setLoading(false)
        }
    }

    const handleEdit = (e, id) => {
        e.preventDefault()
        setEditing(true)
        setCurrentBanco(bancos.filter(item => item.id === id))
    }

    const handleUpdateBanco = async (id, updateBanco) => {
        setEditing(false)
        setLoading(true)
        await api.put(`banco/${id}`, updateBanco)
        let oldBancos = bancos.filter(item => item.id !== id)
        setBancos([...oldBancos, { ...updateBanco, id }])
        toaster.success(
            'Banco editado com sucesso',
            {
                duration: 3
            }
        )
        setLoading(false)
    }

    const handleCancel = (e) => {
        e.preventDefault()
        setEditing(false)
    }

    return (
        <Pane display="flex" border="default" flexDirection="column">
            <Pane padding={10}>
                {!editing ?
                    (
                        <AddBanco addBanco={addBanco} />
                    )
                    :
                    (<EditBanco handleCancel={handleCancel} currentBanco={currentBanco} handleUpdateBanco={handleUpdateBanco} />)
                }
            </Pane>
            {isLoading ?
                <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
                    <Spinner />
                </Pane>
                :
                <Pane marginTop={20}>
                    <Pane display="flex" alignItems="center" justifyContent="center" >
                        <Heading size={800}>Lista de Bancos</Heading>
                    </Pane>
                    <Pane padding={10} >
                        <ListaBancos data={bancos} handleDelete={handleDelete} handleEdit={handleEdit} />
                    </Pane>
                </Pane>}
        </Pane>
    )
}

export default Banco;
