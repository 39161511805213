import React, { Component } from 'react';
import api from '../../../services/api'
import {
    Pane,
    Button,
    SelectField,
    Heading,
    Spinner
} from "evergreen-ui";
import DatePicker from 'react-date-picker'
import { Bar } from 'react-chartjs-2';

const options = {
    elements: {
        rectangle: {
            borderWidth: 2,
            borderColor: 'white',
            borderSkipped: 'bottom'
        }
    },
    responsive: true,
    legend: {
        position: 'top'
    },
    maintainAspectRatio: false
}

export default class Chart extends Component {
    constructor(props) {
        super(props)
        this.state = {
            teste: [],
            data: { datasets: [], labels: [] },
            dtinicial: null,
            dtfinal: null,
            periodo: 'TODOS',
            isBusca: false,
            isLoading: false,
            cardPeriodo: ''
        }
    }

    onChangeDTini = date => this.setState({ dtinicial: date })
    onChangeDTfinal = date => this.setState({ dtfinal: date })

    handlePesquisa = async (e) => {
        e.preventDefault()
        const { periodo, dtinicial, dtfinal } = this.state

        if (dtinicial === null && dtfinal === null) {
            this.setState({ isLoading: true, isBusca: false })
            await api.get(`relatorioFuncionario/${periodo}`)
                .then(response => {
                    this.setState({
                        teste: response.data[0],
                        isLoading: false, isBusca: true, cardPeriodo: periodo
                    })
                })
                .catch(error => {
                    console.log(error.response)
                })


            const newData = {
                datasets: [
                    {
                        backgroundColor: 'orange',
                        label: 'Analise R$',
                        data: this.state.teste.map(item => item.analise === 0 ? item.analise : item.analise.toFixed(2)),
                    },
                    {
                        backgroundColor: 'green',
                        label: 'Aprovado R$',
                        data: this.state.teste.map(item => item.aprovado === 0 ? item.aprovado : item.aprovado.toFixed(2)),
                    },
                    {
                        backgroundColor: 'blue',
                        label: 'Total R$',
                        data: this.state.teste.map(item => item.final === 0 ? item.final : item.final.toFixed(2)),
                    }
                ],
                labels: this.state.teste.map(item => item.nome)
            }
            this.setState({ data: newData })
        }
        else {
            if (dtinicial !== null && dtfinal !== null) {
                let dtini = dtinicial.getTime()
                let dtfim = dtfinal.getTime()
                if (parseInt(dtfim, 10) >= parseInt(dtini, 10)) {
                    this.setState({ isLoading: true, isBusca: false })
                    await api.get(`relatorioFuncionarioDT/${dtini}/${dtfim}`)
                        .then(response => {
                            this.setState({
                                teste: response.data[0],
                                isLoading: false, isBusca: true, cardPeriodo: ` ${dtinicial.toLocaleDateString()} Até: ${dtfinal.toLocaleDateString()}`
                            })
                        })
                        .catch(error => {
                            console.log(error.response)
                        })

                    const newData = {
                        datasets: [
                            {
                                backgroundColor: 'orange',
                                label: 'Analise R$',
                                data: this.state.teste.map(item => item.analise === 0 ? item.analise : item.analise.toFixed(2)),
                            },
                            {
                                backgroundColor: 'green',
                                label: 'Aprovado R$',
                                data: this.state.teste.map(item => item.aprovado === 0 ? item.aprovado : item.aprovado.toFixed(2)),
                            },
                            {
                                backgroundColor: 'blue',
                                label: 'Total R$',
                                data: this.state.teste.map(item => item.final === 0 ? item.final : item.final.toFixed(2)),
                            }
                        ],
                        labels: this.state.teste.map(item => item.nome)
                    }
                    this.setState({ data: newData })
                }
                else {
                    window.alert("Data inicial precisa ser menor que data final!")
                }
            }
            else {
                window.alert("É preciso selecionar data inicial e data final!")
            }
        }
    }

    render() {
        return (
            <Pane border="default" display="flex" flexDirection="column" padding={10}>
                <Pane
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"
                    border="default"
                    padding={10}
                    justifyContent="center"
                >

                    <SelectField
                        value={this.state.periodo}
                        onChange={e => this.setState({ periodo: e.target.value })}
                        label="Periodo"
                        width="30%">
                        <option value="TODOS" checked>Todos</option>
                        <option value="HOJE" >Hoje</option>
                        <option value="SEMANA">Semana</option>
                        <option value="MES" >Mês</option>
                    </SelectField>

                    <Pane
                        display="flex"
                        flexDirection="column"
                        marginTop={5}
                        marginLeft={20}
                    >
                        <Heading size={400}>Data Inicial</Heading>
                        <DatePicker
                            calendarIcon={null}
                            onChange={this.onChangeDTini}
                            value={this.state.dtinicial}
                        />
                    </Pane>
                    <Pane
                        display="flex"
                        flexDirection="column"
                        marginLeft={10}
                        marginTop={5}
                    >
                        <Heading size={400}>Data Final</Heading>
                        <DatePicker
                            calendarIcon={null}
                            onChange={this.onChangeDTfinal}
                            value={this.state.dtfinal}
                        />
                    </Pane>
                    <Button onClick={this.handlePesquisa} iconBefore="search" marginTop={23} marginLeft={10} height={32}>Filtrar</Button>
                </Pane>
                <Pane
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"
                    justifyContent="center"
                    padding={10}
                    height={510}
                >
                    {this.state.isLoading ?
                        <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
                            <Spinner />
                        </Pane> : ''}
                    {this.state.isBusca ?
                        <Pane display="flex" flexDirection="column" alignItems="center" justifyContent="center" flexWrap="wrap" >
                            <strong>Relatório de contratos por Funcionário</strong>
                            <Heading size={400}>Período: {this.state.cardPeriodo}</Heading>
                            <div style={{ height: '50vh', width: '35vh'}}>
                                <Bar data={this.state.data} options={options} />
                            </div>
                        </Pane> : ''}
                </Pane>
            </Pane>
        )
    }
}
