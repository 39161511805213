import React, { Component } from 'react';
import ListaContratos from './ListaContratos'
import {
  Pane,
  Button,
  toaster,
  SegmentedControl,
  Select
} from 'evergreen-ui'
import Modal from './Modal'
import ModalDocument from './ModalDocument'
import ModalAgendamento from '../Agendamento/ModalAgendamento/index'
import api from '../../services/api'
import { isAdministrador, isGerente } from '../../helpers/validateUser'
import { getToken } from '../../services/auth'
import jwtDecode from 'jwt-decode'


export default class Cliente extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modalDoc: false,
      modalAg: false,
      rowData: [],
      data: [],
      tel: [],
      contrato: [],
      isLoading: false,
      isEditing: false,
      contratoID: null,
      responsaveis: [{ id: 0, username: 'TODOS' }],
      responsavel: 'TODOS',
      responsavel_id: 0,
      cpf: '',

      options: [
        { label: 'Todos', value: 'TODOS' },
        { label: 'AG/Digitação', value: 'DIGITACAO' },
        { label: 'Análise', value: 'ANALISE' },
        { label: 'Pendente', value: 'PENDENTE' },
        { label: 'Pendente/RE', value: 'PENDENTE_RE' },
        { label: 'Aprovado', value: 'APROVADO' },
        { label: 'Cancelado', value: 'CANCELADO' },
      ],
      value: 'TODOS',
    };
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.openModalDocument = this.openModalDocument.bind(this)
    this.closeModalDocument = this.closeModalDocument.bind(this)
    this.openModalAgendamento = this.openModalAgendamento.bind(this)
    this.closeModalAgendamento = this.closeModalAgendamento.bind(this)
  }

  async componentDidMount() {
    let paginaInicial = 1
    let vazio = "empty"
    this.setState({ isLoading: true })
    if (isAdministrador() || isGerente()) {
      await api.get(`/contratosADM/${this.state.value}/${paginaInicial}/${vazio}`)
        .then(response => {
          this.setState({
            data: response.data, isLoading: false
          })
        })
        .catch(error => console.log(error.response));
    } else {
      let decodedUser = jwtDecode(getToken()).data.user[0]
      await api.get(`contratosFuncionario/${this.state.value}/${decodedUser.id}/${paginaInicial}/${vazio}`)
        .then(response => {
          this.setState({
            data: response.data, isLoading: false
          })
        })
        .catch(error => console.log(error.response));
    }

    await api.get("listFuncionarios")
      .then(response => {
        this.setState({
          responsaveis: [...this.state.responsaveis, ...response.data]
        })
      })
      .catch(error => console.log(error.response));

  }

  handleResponsavel = async (e) => {
    await this.setState({
      responsavel: e.target.value,
      responsavel_id: e.target.childNodes[e.target.selectedIndex].getAttribute('data-key')
    })
    this.handleFiltroChange()
  }

  async handleFiltroChange() {
    const { responsavel, responsavel_id, cpf } = this.state
    let paginaInicial = 1
    let vazio = cpf === "" ? "empty" : cpf
    this.setState({ isLoading: true })
    if (isAdministrador() || isGerente()) {
      if (responsavel === 'TODOS') {
        await api.get(`/contratosADM/${this.state.value}/${paginaInicial}/${vazio}`)
          .then(response => {
            this.setState({
              data: response.data, isLoading: false
            })
          })
          .catch(error => console.log(error.response));
      } else {
        await api.get(`contratosFuncionario/${this.state.value}/${responsavel_id}/${paginaInicial}/${vazio}`)
          .then(response => {
            this.setState({
              data: response.data, isLoading: false
            })
          })
          .catch(error => console.log(error.response));
      }

    }
    else {
      let decodedUser = jwtDecode(getToken()).data.user[0]
      await api.get(`contratosFuncionario/${this.state.value}/${decodedUser.id}/${paginaInicial}/${vazio}`)
        .then(response => {
          this.setState({
            data: response.data, isLoading: false
          })
        })
        .catch(error => console.log(error.response));
    }
  }

  // handlePagination = async (page) => {
  //   const { responsavel, responsavel_id } = this.state
  //   let vazio = "empty"
  //   this.setState({ isLoading: true })
  //   if (isAdministrador() || isGerente()) {
  //     if (responsavel === 'TODOS') {
  //       await api.get(`/contratosADM/${this.state.value}/${page}/${vazio}`)
  //         .then(response => {
  //           this.setState({
  //             data: response.data, isLoading: false
  //           })
  //         })
  //         .catch(error => console.log(error.response));
  //     } else {
  //       await api.get(`contratosFuncionario/${this.state.value}/${responsavel_id}/${page}/${vazio}`)
  //         .then(response => {
  //           this.setState({
  //             data: response.data, isLoading: false
  //           })
  //         })
  //         .catch(error => console.log(error.response));
  //     }
  //   }
  //   else {
  //     let decodedUser = jwtDecode(getToken()).data.user[0]
  //     await api.get(`contratosFuncionario/${this.state.value}/${decodedUser.id}/${page}/${vazio}`)
  //       .then(response => {
  //         this.setState({
  //           data: response.data, isLoading: false
  //         })
  //       })
  //       .catch(error => console.log(error.response));
  //   }

  // }


  openModalDocument(id) {
    this.setState({ modalDoc: true, contratoID: id })
  }

  closeModalDocument() {
    this.setState({ modalDoc: false, contratoID: null })
  }

  openModal() {
    this.setState({ modal: true })
  }

  closeModal() {
    this.setState({ modal: false, contrato: [] })
  }

  handleReceivedData = (contrato) => {
    const { data } = this.state
    toaster.success(
      'Contrato cadastrado com sucesso',
      {
        duration: 3
      })
    const newData = this.state.data.data

    if (this.state.value === 'TODOS' || this.state.value === contrato.situacao) {
      this.setState({ data: { ...data, data: [contrato, ...newData] } })
    }
  }

  handleReceivedDataEdit = (contrato) => {
    const { data } = this.state
    toaster.success(
      'Contrato atualizado com sucesso',
      {
        duration: 3
      })

    if (this.state.value === 'TODOS' || this.state.value === contrato.situacao) {
      const newData = data.data.filter(item => item.id !== contrato.id)
      this.setState({ data: { ...data, data: [contrato, ...newData] } })
    } else {
      const newData = data.data.filter(item => item.id !== contrato.id)
      this.setState({ data: { ...data, data: [...newData] } })
    }
  }

  handleDelete = async (e, id) => {
    e.preventDefault()
    const { data } = this.state
    if (window.confirm("Você realmente deseja deletar o contrato?")) {
      this.setState({ isLoading: true })

      //DELETAR DOCUMENTOS CONTRATO SERVIDOR AMAZON S3
      const response = await api.get(`documento-contrato/${id}`)
      response.data.map(async file =>
        await api.delete(`documento-contrato/${file.id}`)
      )

      await api.delete(`contrato/${id}`)
        .then(response => {
          toaster.success(
            'Contrato deletado com sucesso',
            {
              duration: 3
            }
          )
          const newData = data.data.filter(item => item.id !== id)
          this.setState({ data: { ...data, data: [...newData] }, isLoading: false })
        })
        .catch(error => {
          console.log(error.response)
        })
    }
  }

  handleEdit = (e, id) => {
    e.preventDefault()
    this.setState({ contrato: [] })
    const { data } = this.state
    const newData = data.data.filter(item => item.id === id)
    this.setState({ contrato: newData }, this.openModal)
  }

  handleView = async (e, id) => {
    e.preventDefault()
    this.setState({ contrato: [], tel: [] })
    const { data } = this.state
    const newData = data.data.filter(item => item.id === id)
    const { cpf_id } = newData[0]

    const response = await api.get(`telefone/${cpf_id}`)
    this.setState({ contrato: { ...newData, view: true }, tel: response.data }, this.openModal)
  }

  handleDocument = (e, id) => {
    this.openModalDocument(id)
  }

  fetchDataFilter = async (page, state) => {
    if (page > 1) {
      if (state.length > 0) {
        if (state[0].value.length >= 11) {
          this.setState({ isLoading: true, cpf: state[0].value.toUpperCase() })
          if (isAdministrador() || isGerente()) {
            if (this.state.responsavel === 'TODOS') {
              await api.get(`/contratosADM/${this.state.value}/${page}/${state[0].value.toUpperCase()}`)
                .then(response => {
                  this.setState({
                    data: response.data, isLoading: false
                  })
                })
                .catch(error => console.log(error.response));
            } else {
              await api.get(`contratosFuncionario/${this.state.value}/${this.state.responsavel_id}/${page}/${state[0].value.toUpperCase()}`)
                .then(response => {
                  this.setState({
                    data: response.data, isLoading: false
                  })
                })
                .catch(error => console.log(error.response));
            }
          } else {
            let decodedUser = jwtDecode(getToken()).data.user[0]
            await api.get(`contratosFuncionario/${this.state.value}/${decodedUser.id}/${page}/${state[0].value.toUpperCase()}`)
              .then(response => {
                this.setState({
                  data: response.data, isLoading: false
                })
              })
              .catch(error => console.log(error.response));
          }
        }
      }
      else {
        let vazio = "empty"
        this.setState({ isLoading: true, cpf: '' })
        if (isAdministrador() || isGerente()) {
          if (this.state.responsavel === 'TODOS') {
            await api.get(`/contratosADM/${this.state.value}/${page}/${vazio}`)
              .then(response => {
                this.setState({
                  data: response.data, isLoading: false
                })
              })
              .catch(error => console.log(error.response));
          } else {
            await api.get(`contratosFuncionario/${this.state.value}/${this.state.responsavel_id}/${page}/${vazio}`)
              .then(response => {
                this.setState({
                  data: response.data, isLoading: false
                })
              })
              .catch(error => console.log(error.response));
          }
        } else {
          let decodedUser = jwtDecode(getToken()).data.user[0]
          await api.get(`contratosFuncionario/${this.state.value}/${decodedUser.id}/${page}/${vazio}`)
            .then(response => {
              this.setState({
                data: response.data, isLoading: false
              })
            })
            .catch(error => console.log(error.response));
        }
      }
    }
    ///////// 1 pagina
    else {
      if (state.length > 0) {
        if (state[0].value.length >= 11) {
          let paginaInicial = 1
          this.setState({ isLoading: true, cpf: state[0].value.toUpperCase() })
          if (isAdministrador() || isGerente()) {
            if (this.state.responsavel === 'TODOS') {
              await api.get(`/contratosADM/${this.state.value}/${paginaInicial}/${state[0].value.toUpperCase()}`)
                .then(response => {
                  this.setState({
                    data: response.data, isLoading: false
                  })
                })
                .catch(error => console.log(error.response));
            } else {
              await api.get(`contratosFuncionario/${this.state.value}/${this.state.responsavel_id}/${paginaInicial}/${state[0].value.toUpperCase()}`)
                .then(response => {
                  this.setState({
                    data: response.data, isLoading: false
                  })
                })
                .catch(error => console.log(error.response));
            }
          } else {
            let decodedUser = jwtDecode(getToken()).data.user[0]
            await api.get(`contratosFuncionario/${this.state.value}/${decodedUser.id}/${paginaInicial}/${state[0].value.toUpperCase()}`)
              .then(response => {
                this.setState({
                  data: response.data, isLoading: false
                })
              })
              .catch(error => console.log(error.response));
          }
        }
      }
      else {
        let paginaInicial = 1
        let vazio = "empty"
        this.setState({ isLoading: true, cpf: '' })
        if (isAdministrador() || isGerente()) {
          if (this.state.responsavel === 'TODOS') {
            await api.get(`/contratosADM/${this.state.value}/${paginaInicial}/${vazio}`)
              .then(response => {
                this.setState({
                  data: response.data, isLoading: false
                })
              })
              .catch(error => console.log(error.response));
          } else {
            await api.get(`contratosFuncionario/${this.state.value}/${this.state.responsavel_id}/${paginaInicial}/${vazio}`)
              .then(response => {
                this.setState({
                  data: response.data, isLoading: false
                })
              })
              .catch(error => console.log(error.response));
          }
        } else {
          let decodedUser = jwtDecode(getToken()).data.user[0]
          await api.get(`contratosFuncionario/${this.state.value}/${decodedUser.id}/${paginaInicial}/${vazio}`)
            .then(response => {
              this.setState({
                data: response.data, isLoading: false
              })
            })
            .catch(error => console.log(error.response));
        }
      }
    }
  }

  openModalAgendamento(dataRow) {
    this.setState({ modalAg: true, rowData: { cpf: dataRow.cpf_id, nome: dataRow.cli.nome, user_id: dataRow.user_id } })
  }

  closeModalAgendamento() {
    this.setState({ modalAg: false })
  }

  handleAgendamento = (dataRow) => {
    this.openModalAgendamento(dataRow)
  }

  addAgendamentoContrato = async (agendamento) => {
    const response = await api.get(`telefone/${agendamento.cpf}`)
    let telefone = response.data[0].numero
    await api.post('/agendamento', { ...agendamento, telefone })
    toaster.success(
      'Agendamento Adicionado',
      {
        duration: 3
      }
    )
  }

  render() {
    return (
      <Pane >
        <Pane display="flex" flexWrap="wrap" padding={5} background="tint1" borderRadius={3} marginBottom={5} justifyContent="space-between">
          <Pane flex={1} alignItems="center" display="flex" marginBottom={5}>
            <Button onClick={this.openModal} intent="success" appearance="primary" iconBefore="plus">Cadastrar</Button>
            {isAdministrador() || isGerente() ?
              <Pane flex={1} >
                <Select
                  marginLeft={10}
                  width="50%"
                  value={this.state.responsavel}
                  onChange={(e) => this.handleResponsavel(e)}
                >
                  {
                    this.state.responsaveis.map((item) => (
                      <option key={item.id} data-key={item.id}>{item.username}</option>
                    ))
                  }
                </Select>
              </Pane>
              :
              ''}
          </Pane>

          <Pane flex={1} >
            <SegmentedControl
              height={32}
              options={this.state.options}
              value={this.state.value}
              onChange={value => this.setState({ value: value }, this.handleFiltroChange)} />
          </Pane>
        </Pane>
        <Pane
          display="flex"
          flexDirection="column"
        >
          <ListaContratos fetchDataFilter={this.fetchDataFilter} isLoading={this.state.isLoading} loadingData={this.state.data} handleDelete={this.handleDelete} handleEdit={this.handleEdit} handleView={this.handleView} handleDocument={this.handleDocument} handleAgendamento={this.handleAgendamento} />
        </Pane>
        {this.state.modal ?
          <Modal isOpen={this.state.modal} onClose={this.closeModal} retrivedata={this.handleReceivedData} retrivedataEdit={this.handleReceivedDataEdit} sendData={this.state.contrato} sendTel={this.state.tel} /> : ''
        }

        {this.state.modalDoc ?
          <ModalDocument isOpen={this.state.modalDoc} onClose={this.closeModalDocument} sendID={this.state.contratoID} /> : ''
        }
        {
          this.state.modalAg ?
            <ModalAgendamento isOpen={this.state.modalAg} onClose={this.closeModalAgendamento} sendRow={this.state.rowData} addAgendamentoContrato={this.addAgendamentoContrato} /> : ''
        }
      </Pane>
    )
  }
}
