import styled from 'styled-components'

export const CardContainer = styled.div`
  border-radius: 4px;           /* Slightly Curve edges */
  margin-Bottom: 10px;
  border: ${props => props.pri ? '1px solid #FFC75F' : ''};
`

export const Header = styled.div`
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  padding: 5px 10px;

  background-color: ${props => props.pri ? '#FFC75F' : ''};
 
  border-bottom: ${props => props.pri ? '1px solid #FFC75F' : ''};
 
  color: ${props => props.pri ? '1px solid #FFC75F' : ''};
 
`