import React from 'react';
import {
    Pane,
    Button,
} from "evergreen-ui";

const ButtonControl = (props) => (
    <Pane padding={10} display="flex" flexWrap="wrap">
        <Button height={32} appearance="primary" intent="warning" iconBefore="edit" onClick={() => props.editar()}>Editar</Button>
        <Button height={32} marginLeft={10} appearance="primary" intent="danger" iconBefore="trash" onClick={() => props.delete()}>Deletar</Button>
        <Button height={32} marginLeft={10} appearance="primary"  iconBefore="disable" onClick={() => props.cancelar()}>Cancelar</Button>
    </Pane>
)

export default ButtonControl;
