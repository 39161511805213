import React, { useState } from 'react';
import { Pane, Heading, TextInput, IconButton, Spinner } from 'evergreen-ui';
import api from '../../services/api'
import CardContrato from '../Contrato/CardContrato/index'

const PesquisaContrato = () => {
    const [isLoading, setLoading] = useState(false)

    const initialContratoState = { data: [], tel:[] }
    const [contrato, setContrato] = useState(initialContratoState)

    const initialPesquisaState = { ADE: '' }
    const [pesquisa, setPesquisa] = useState(initialPesquisaState)

    const initialErrorState = { errADE: false, errMensagem: '' }
    const [error, setError] = useState(initialErrorState)

    const handleInputChange = e => {
        const { name, value } = e.target
        setPesquisa({ ...pesquisa, [name]: value })
    }

    const handlePesquisaADE = async () => {
        setError(initialErrorState)
        setContrato(initialContratoState)
        setLoading(true)

        const contratoData = await api.get(`contrato-ade/${pesquisa.ADE}`)
        if (contratoData.data.length > 0) {
            let { cpf_id } = contratoData.data[0]
            const response = await api.get(`telefone/${cpf_id}`)
            setLoading(false)
            setContrato({ data: contratoData.data, tel:response.data })
        } else {
            setLoading(false)
            setError({ errMensagem: 'Não existe contrato informado.' })
        }
    }

    const handleClear = (e) => {
        e.preventDefault()
        setError(initialErrorState)
        setContrato(initialContratoState)
        setPesquisa(initialPesquisaState)
    }

    return (
        <Pane display="flex" border="default" flexDirection="column" height={800}>
            <Pane display="flex" alignItems="center" justifyContent="center" flexDirection="row" marginTop={10}>
                <form onSubmit={e => {
                    e.preventDefault()
                    if (!pesquisa.ADE) {
                        setError({ errADE: !pesquisa.ADE, errMensagem: "ADE não pode ser vazia." })
                        return
                    }
                    handlePesquisaADE()
                }}>
                    <Pane display="flex" flexDirection="column">
                        <Pane display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Heading size={600}>Pesquisar Contrato</Heading>
                        </Pane>
                        <Pane display="flex" flexWrap="wrap" alignItems="center" justifyContent="center" flexDirection="row" marginTop={5}>
                            <TextInput
                                marginTop={10}
                                placeholder="Digite ADE"
                                value={pesquisa.ADE}
                                onChange={handleInputChange}
                                isInvalid={error.errADE}
                                name="ADE"
                            />
                            <Pane display="flex" height={32} marginLeft={5} marginTop={10}>
                                <IconButton icon="search" />
                                <IconButton icon="eraser" marginLeft={5} onClick={handleClear} />
                            </Pane>
                        </Pane>
                        <Pane >
                            <Heading color="red" size={300}>{error.errMensagem}</Heading>
                        </Pane>
                    </Pane>
                </form>
            </Pane>
            {isLoading ?
                <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
                    <Spinner />
                </Pane>
                :
                <Pane marginTop={20}>
                    <Pane padding={10}>
                        {contrato.data.length > 0 ? <CardContrato sendData={contrato.data} sendTel={contrato.tel} /> : ''}
                    </Pane>
                </Pane>}


        </Pane>
    )
}
export default PesquisaContrato;
