import React from 'react';
import { Container } from './styles';
import { Pane, Icon, Heading, Paragraph, IconButton } from 'evergreen-ui'

const PostList = props => (
    <Container>
        {
            props.posts.length > 0 ? (
                props.posts.map(post => (
                    <li key={post.id}>
                        <Pane padding={10} display="flex" flexDirection="column" width="100%" >
                            <Pane display="flex" flexDirection="row" background="#FFC914" alignItems="center" justifyContent="center">
                                <Pane display="flex" flexDirection="row" >
                                    <hr />
                                    <Heading padding={5} size={600} >
                                        {post.titulo}
                                    </Heading>

                                    <Icon marginLeft={10} marginTop={10} name="star" value={post.favorito}
                                        appearance="minimal"
                                        icon={post.favorito ? "star" : "star-empty"} />
                                </Pane>
                            </Pane>
                            <Paragraph>{post.texto}</Paragraph>
                            <Pane display="flex" padding={3} flexDirection="row" background="#FFC914" justifyContent="space-between">
                                    <Heading size={300} >
                                        <strong>Criado por :</strong> {post.resp.username}
                                        <strong> em :</strong> {new Date(post.created_at).toLocaleString()}
                                    </Heading>
                                    <IconButton marginLeft={10} height={20} intent="danger" appearance="primary" icon={"trash"} onClick={(e) => props.handleDelete(e, post.id)} />
                            </Pane>
                        </Pane>
                    </li>
                ))) :
                <Pane display="flex" alignItems="center" justifyContent="center">
                    <Heading size={500}>
                        Não há posts
                    </Heading>
                </Pane>
        }
    </Container>
);

export default PostList;
