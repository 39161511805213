import React, { Component } from 'react';
import ReactTable from 'react-table'
import { Pane, IconButton, Heading } from 'evergreen-ui'

export default class ListaContratos extends Component {
  constructor(props){
    super(props)
    this.state = {
      columns : [
        {
          width: 130,
          id: 'id',
          Header: () => (
            <span>
              <strong>CPF</strong>
            </span>),
          accessor: 'cpf',
          Cell: row => (
            <h6>{row.original.cpf}</h6>
          )
        }, {
          width: 300,
          Header: () => (
            <span>
              <strong>Nome</strong>
            </span>),
          accessor: 'nome',
        },
        {
          width: 200,
          Header: () => (
            <span>
              <strong>Cidade</strong>
            </span>),
          accessor: 'cidade',
        },
        {
          width: 150,
          Header: () => (
            <span>
              <strong>Nascimento</strong>
            </span>),
          accessor: 'nascimento',
          Cell: row => (
            <p>{new Date(row.original.nascimento).toLocaleDateString()}</p>
          ),
        },
        {
          width: 150,
          Header: () => (
            <span>
              <strong>Telefones</strong>
            </span>),
          accessor: 'tel',
          Cell: props => (
            <Pane>
              {props.original.tel.map((item) => (
                <Heading color="green" size={300} key={item.id}>{item.numero}</Heading>
              ))}
            </Pane>
          ),
        },
        {
          width: 125,
          Header: () => (
            <span>
              <strong>Ações</strong>
            </span>),
          id: 'edit/delete',
          Cell: row => (
            <Pane display="flex" justify-content="flex-start" >
              <IconButton id="btnView" onClick={() => this.props.view(row.original.cpf,row.original.id)} marginRight={5} height={32} icon="eye-open" intent="none" />
            </Pane>)
        }]
    }
  }
  render() {
    return (
      <Pane padding={5}>
        <ReactTable
          loading={this.props.isLoading}
          data={this.props.dataCliente}
          pageSize={10}
          columns={this.state.columns}
          className="-striped -highlight"
          noDataText="Não há clientes!"
          sortable={true}
          multiSort={true}
          resizable={false}
        />
      </Pane>
    )
  }
}