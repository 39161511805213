import React from 'react';
import {
    Pane,
    Heading,
    Icon
} from "evergreen-ui";
import { CardContainer, Header } from './styles'

const ListaCliente = (cliente) => (
    <Pane display="flex" flexDirection="column" padding={10}>
        <CardContainer pri>
            <Header pri> <Icon icon="person" marginRight={5} />Cliente</Header>
            <Pane
                display="flex"
                flexDirection="row"
            >
                <Pane
                    elevation={1}
                    flex={2}
                    background="yellowTint"
                    padding={10}
                >
                    <Pane
                        display="flex"
                        alignItems="center"
                        flexDirection="row">
                        <Heading size={300}>CPF:</Heading>
                        <Heading size={600} marginLeft={10}> {cliente.loadCliente[0].cpf} </Heading>
                    </Pane>
                    <Pane
                        display="flex"
                        alignItems="center"
                        flexDirection="row">
                        <Heading size={300}>Nome:</Heading>
                        <Heading size={500} marginLeft={10}> {cliente.loadCliente[0].nome} </Heading>
                    </Pane>
                    <Pane
                        display="flex"
                        alignItems="center"
                        flexDirection="row">
                        <Heading size={300}>Nascimento:</Heading>
                        <Heading size={400} marginLeft={10}> {new Date(cliente.loadCliente[0].nascimento).toLocaleDateString()} </Heading>
                    </Pane>
                </Pane>
                <Pane
                    elevation={1}
                    flex={1}
                    background="yellowTint"
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                >
                    <Heading size={500} > Telefones </Heading>
                    {cliente.loadCliente[0].tel.map(item => (
                        <strong key={item.id}>{item.numero}</strong>
                    ))}
                </Pane>
            </Pane>
        </CardContainer>

        <CardContainer sec>
            <Header sec> <Icon icon="home" marginRight={5} />Endereço</Header>
            <Pane padding={10} display="flex" background="redTint" flexDirection="column">

                <Pane
                    display="flex"
                    alignItems="center"
                    flexDirection="row">
                    <Heading size={300} >Endereço:</Heading>
                    <Heading size={500} marginLeft={10}> {cliente.loadCliente[0].endereco}</Heading>
                    <Heading size={500} marginLeft={5}>  - Nº {cliente.loadCliente[0].numero}</Heading>
                </Pane>
                <Pane
                    display="flex"
                    alignItems="center"
                    flexDirection="row">
                    <Heading size={300} >Bairro:</Heading>
                    <Heading size={400} marginLeft={10}> {cliente.loadCliente[0].bairro} </Heading>
                </Pane>
                <Pane
                    display="flex"
                    alignItems="center"
                    flexDirection="row">
                    <Heading size={300} >Cidade:</Heading>
                    <Heading size={400} marginLeft={10}> {cliente.loadCliente[0].cidade}</Heading>
                </Pane>
                <Pane
                    display="flex"
                    alignItems="center"
                    flexDirection="row">
                    <Heading size={300} >Estado:</Heading>
                    <Heading size={400} marginLeft={10}> {cliente.loadCliente[0].estado} </Heading>
                </Pane>
                <Pane
                    display="flex"
                    alignItems="center"
                    flexDirection="row">
                    <Heading size={300} >Cep:</Heading>
                    <Heading size={400} marginLeft={10}> {cliente.loadCliente[0].cep} </Heading>
                </Pane>
            </Pane>
        </CardContainer >
        <CardContainer ter>
            <Header ter> <Icon icon="info-sign" marginRight={5} />Observação</Header>
            <Heading size={400} marginLeft={10}> {cliente.loadCliente[0].observacao} </Heading>
        </CardContainer>
    </Pane >
)

export default ListaCliente;
