import React, { Component } from 'react';
import {
    Pane,
    Tablist,
    Tab,
} from "evergreen-ui";
import AddCliente from './AddCliente'
import ViewClienteCpf from './ViewClienteCpf'
import ViewClienteNome from './ViewClienteNome'


export default class Cliente extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedIndex: 0,
            tabs: ['Pesquisa CPF', 'Pesquisa Nome', 'Cadastro',],
        }
    }

    render() {
        return (
            <Pane
                background="tint1"
                borderRadius={3}
                border="default"
            >


                <Pane  marginTop={10}>
                    <Tablist marginBottom={16} flexBasis={240} marginRight={24}>
                        {this.state.tabs.map((tab, index) => (
                            <Tab
                                key={tab}
                                id={tab}
                                onSelect={() => this.setState({ selectedIndex: index })}
                                isSelected={index === this.state.selectedIndex}
                                aria-controls={`panel-${tab}`}
                            >
                                {tab}
                            </Tab>
                        ))}
                    </Tablist>
                    <Pane padding={16} background="tint1" >
                        {this.state.tabs.map((tab, index) => (
                            <Pane
                                key={tab}
                                id={`panel-${tab}`}
                                role="tabpanel"
                                aria-labelledby={tab}
                                aria-hidden={index !== this.state.selectedIndex}
                                display={index === this.state.selectedIndex ? 'block' : 'none'}
                            >
                                {tab === 'Cadastro' ? <AddCliente /> : ''}
                                {tab === 'Pesquisa CPF' ? <ViewClienteCpf /> : ''}
                                {tab === 'Pesquisa Nome' ? <ViewClienteNome /> : ''}
                            </Pane>
                        ))}
                    </Pane>
                </Pane>
            </Pane >
        )
    }
}
