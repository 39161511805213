var estados = [
    { 'UF' : 'SP' },
    { 'UF' : 'AC' },
    { 'UF' : 'AL' },
    { 'UF' : 'AP' },
    { 'UF' : 'AM' },
    { 'UF' : 'BA' },
    { 'UF' : 'CE' },
    { 'UF' : 'DF' },
    { 'UF' : 'ES' },
    { 'UF' : 'GO' },
    { 'UF' : 'MA' },
    { 'UF' : 'MT' },
    { 'UF' : 'MS' },
    { 'UF' : 'MG' },
    { 'UF' : 'PA' },
    { 'UF' : 'PB' },
    { 'UF' : 'PR' },
    { 'UF' : 'PE' },
    { 'UF' : 'PI' },
    { 'UF' : 'RJ' },
    { 'UF' : 'RN' },
    { 'UF' : 'RS' },
    { 'UF' : 'RO' },
    { 'UF' : 'RR' },
    { 'UF' : 'SC' },
    { 'UF' : 'SE' },
    { 'UF' : 'TO' },
  ]
export default estados 
